import React, { FC, ReactNode, useMemo, useState } from 'react';
import { CButton, CText, WageView } from '../../components';
import { useStyle } from '../../utilities/styles';
import { View } from 'react-native';
import { wageMessages } from './wage.messages';
import { MRates } from '../../models';
import { Message } from '../../utilities/types';

enum ERates {
    regular = 'regular',
    night = 'night',
    holiday = 'holiday',
    sunday = 'sunday',
}

export interface IRatesProps {
    curData: MRates;
    original?: MRates;
    prev?: MRates;
    horizontal?: boolean;
    injectedFields?: ReactNode[];
    excludeRegular?: boolean;
    messageIfEmpty?: Message;
}

export const RatesView: FC<IRatesProps> = ({
    curData,
    original,
    prev,
    horizontal,
    injectedFields,
}) => {
    const style = useStyle();
    const [rateToShow, setRateToShow] = useState(ERates.regular);

    const hasNightWage = useMemo(
        () => !!(curData.useNightWage && curData.nightWage.wage),
        [curData],
    );

    const hasSundayWage = useMemo(
        () => !!(curData.useSundayWage && curData.sundayWage.wage),
        [curData],
    );

    const hasHolidayWage = useMemo(
        () => !!(curData.useHolidayWage && curData.holidayWage.wage),
        [curData],
    );

    return (
        <View>
            <View style={style.horizontal}>
                {hasNightWage || hasSundayWage || hasHolidayWage ? (
                    <CButton
                        title={wageMessages.regularRate}
                        onPress={() => setRateToShow(ERates.regular)}
                        minor={rateToShow === ERates.regular}
                        transparent
                        style={{ margin: 0, padding: 0, marginHorizontal: 5 }}
                        unstyledText
                    />
                ) : (
                    <View style={{ height: 40, justifyContent: 'center' }}>
                        <CText message={wageMessages.regularRate} />
                    </View>
                )}
                {hasNightWage && (
                    <CButton
                        title={wageMessages.nightRate}
                        onPress={() => setRateToShow(ERates.night)}
                        minor={rateToShow === ERates.night}
                        transparent
                        style={{ margin: 0, padding: 0, marginHorizontal: 5 }}
                        unstyledText
                    />
                )}
                {hasSundayWage && (
                    <CButton
                        title={wageMessages.sundayRate}
                        onPress={() => setRateToShow(ERates.sunday)}
                        minor={rateToShow === ERates.sunday}
                        transparent
                        style={{ margin: 0, padding: 0, marginHorizontal: 5 }}
                        unstyledText
                    />
                )}
                {hasHolidayWage && (
                    <CButton
                        title={wageMessages.holidayRate}
                        onPress={() => setRateToShow(ERates.holiday)}
                        minor={rateToShow === ERates.holiday}
                        transparent
                        style={{ margin: 0, padding: 0, marginHorizontal: 5 }}
                        unstyledText
                    />
                )}
            </View>
            {rateToShow === ERates.regular && (
                <WageView
                    value={curData}
                    original={original}
                    prev={prev}
                    horizontal={horizontal}
                    horizontalSplitting={horizontal}
                    injectedFields={injectedFields}
                />
            )}
            {rateToShow === ERates.night && (
                <WageView
                    value={curData.nightWage}
                    original={original?.nightWage}
                    prev={prev?.nightWage}
                    horizontal={horizontal}
                    horizontalSplitting={horizontal}
                    injectedFields={injectedFields}
                />
            )}
            {rateToShow === ERates.sunday && (
                <WageView
                    value={curData.sundayWage}
                    original={original?.sundayWage}
                    prev={prev?.sundayWage}
                    horizontal={horizontal}
                    horizontalSplitting={horizontal}
                    injectedFields={injectedFields}
                />
            )}
            {rateToShow === ERates.holiday && (
                <WageView
                    value={curData.holidayWage}
                    original={original?.holidayWage}
                    prev={prev?.holidayWage}
                    horizontal={horizontal}
                    horizontalSplitting={horizontal}
                    injectedFields={injectedFields}
                />
            )}
        </View>
    );
};
