import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CIcon, CText, CTextInput } from '../../../../components';
import { contractMessages } from '../../contract.messages';
import { MContract } from '../../../../models';
import { useStyle } from '../../../../utilities/styles';
import { useFireBase } from '../../../../utilities/firebase';
import { useLock } from '../../../../utilities/hooks';
import { isEmployer } from '../../../../utilities/auth';
import { actionMessages } from '../../../../utilities/messages';

export const WorkplaceSettings: FC<{
    contract: MContract;
    issueReload: () => void;
}> = ({ contract, issueReload }) => {
    const style = useStyle();
    const { lock } = useLock();
    const { callFunction, userData } = useFireBase();
    /**
     * local state
     */
    const [leadMail, setLeadMail] = useState(contract.workplaceLeadMail);
    const [leadName, setLeadName] = useState(contract.workplaceLeadName);
    /**
     * memoized check for lead changes
     */
    const hasChanges = useMemo(
        () =>
            leadMail !== contract.workplaceLeadMail ||
            leadName !== contract.workplaceLeadName,
        [leadMail, leadName, contract],
    );
    /**
     * callback to handle lead saves
     */
    const handleSave = useCallback(async () => {
        const unlock = await lock();
        try {
            await callFunction('updateContractSettings', {
                contractId: contract.documentId,
                workplaceLeadMail: leadMail,
                workplaceLeadName: leadName,
            });
            issueReload();
            unlock();
        } catch (e) {
            unlock();
        }
    }, [contract, leadMail, leadName]);
    /**
     * render
     */
    return (
        <View style={[style.card, style.horizontal]}>
            <View style={[{ width: 80, paddingRight: 5 }, style.centeredItems]}>
                <CIcon icon={'accountThin_outline'} size={70} />
            </View>
            <View style={style.flex1}>
                <CText
                    style={style.verticalPadded}
                    headline
                    message={contractMessages.workplaceSettings}
                />
                <CTextInput
                    label={contractMessages.leadMail}
                    value={leadMail}
                    onChangeText={setLeadMail}
                    autoExtend
                />
                <CTextInput
                    label={contractMessages.leadName}
                    value={leadName}
                    onChangeText={setLeadName}
                    autoExtend
                />
                <CButton
                    title={actionMessages.save}
                    disabled={!hasChanges}
                    onPress={handleSave}
                />
            </View>
        </View>
    );
};
