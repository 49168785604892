import React, { FC, useCallback } from 'react';
import { View } from 'react-native';
import { CButton } from '../../../../components';
import { contractMessages } from '../../contract.messages';
import { MContract } from '../../../../models';
import { useStyle } from '../../../../utilities/styles';
import { useFireBase } from '../../../../utilities/firebase';
import { useLock } from '../../../../utilities/hooks';
import { actionMessages } from '../../../../utilities/messages';
import { useDialog } from '../../../../utilities/dialog';
import { jobMessages } from '../../../Job/job.messages';

export const ContractExtensionButton: FC<{
    contract: MContract;
    issueReload: () => void;
}> = ({ contract, issueReload }) => {
    const style = useStyle();
    const dialog = useDialog();
    const { lock } = useLock();
    const { callFunction } = useFireBase();
    /**
     * callback to handle extension request
     */
    const handleExtensionRequest = useCallback(async () => {
        let to = contract.to;
        const ok = await dialog({
            title: contractMessages.requestExtension,
            message: contractMessages.requestExtensionText,
            dateInputs: [
                {
                    title: jobMessages.to,
                    lowerLimit: contract.to,
                    defaultValue: contract.to,
                    id: 'to',
                },
            ],
            buttons: [
                {
                    text: contractMessages.requestExtension,
                    onPress: (inputs) => {
                        const nextTo = inputs?.find(
                            ({ id }) => id === 'to',
                        )?.value;
                        if (nextTo) {
                            to = nextTo;
                        }
                    },
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
        if (!ok || to === contract.to) return;
        /**
         * execute request
         */
        const unlock = await lock();
        try {
            await callFunction('requestContractExtension', {
                contractId: contract.documentId,
                to,
            });
            issueReload();
            unlock();
            dialog({
                icon: 'success',
                title: contractMessages.requestedExtension,
                message: contractMessages.requestedExtensionText,
            });
        } catch (e) {
            unlock();
        }
    }, [contract]);
    /**
     * callback to respond to extension request
     */
    const respondToExtension = useCallback(
        async (approve = true) => {
            const ok = await dialog({
                icon: approve ? 'success' : 'error',
                title: approve
                    ? contractMessages.approveExtension
                    : contractMessages.rejectExtension,
                message: approve
                    ? contractMessages.approveExtensionText
                    : contractMessages.rejectExtensionText,
                buttons: [
                    {
                        text: actionMessages.ok,
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            });
            if (!ok) return;
            /**
             * execute request
             */
            const unlock = await lock();
            try {
                await callFunction('confirmContractExtension', {
                    contractId: contract.documentId,
                    approve,
                });
                issueReload();
                unlock();
            } catch (e) {
                unlock();
            }
        },
        [contract],
    );
    /**
     * render
     */
    return (
        <View>
            {
                !contract.tos.length ||
                contract.tos[contract.tos.length - 1].approved ? (
                    <CButton
                        title={contractMessages.requestExtension}
                        onPress={handleExtensionRequest}
                        minor
                        style={style.smallMargin}
                    />
                ) : undefined
                // (
                //     <View>
                //         <CText message={contractMessages.openExtension} />
                //         <CText
                //             message={`${format(jobMessages.to)}: ${new Date(
                //                 contract.tos[contract.tos.length - 1].to,
                //             ).toLocaleDateString('de')}`}
                //         />
                //         {contract.tos[contract.tos.length - 1].from !==
                //             userData.documentId && (
                //             <View style={style.horizontal}>
                //                 <CButton
                //                     onPress={() => respondToExtension(false)}
                //                     icon="close"
                //                     iconColor={theme.errorColor}
                //                     minor
                //                     small
                //                     transparent
                //                 />
                //                 <CButton
                //                     onPress={respondToExtension}
                //                     icon="check"
                //                     iconColor={theme.successColor}
                //                     minor
                //                     small
                //                     transparent
                //                 />
                //             </View>
                //         )}
                //     </View>
                // )
            }
        </View>
    );
};
