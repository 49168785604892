import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCard, CIcon, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import {
    useNavigate,
    useParams,
    useSecureNavigate,
} from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ChatView } from '../../Chat';
import { ContractTitle } from '../components/ContractTitle';
import { contractMessages } from '../contract.messages';
import { ContractFiles } from './components/ContractFiles';
import { useContractViewState } from './useContractViewState';
import { EApplicationType } from '../../../enums';
import { useDimensions } from '../../../utilities/hooks';
import { BasicContractView } from './BasicContractView';
import { jobMessages } from '../../Job/job.messages';
import { TimesheetSettings } from './components/TimesheetSettings';
import { ContractDetails } from './components/ContractDetails';
import { generalMessages } from '../../../utilities/messages';
import { ContractChatTitle } from './components/ContractChatTitle';
/**
 * enum to declare which overview to show
 */
enum EContractViewModes {
    contractDetails = 'contractDetails',
    files = 'files',
    timesheet = 'timesheet',
    chat = 'chat',
}
/**
 * minimal width to display chat on side
 */
const minWidth = 1100;
/**
 * Contract view
 * - overview over contract values
 * - all documents related to contract
 * - options to upload documents and request a signature
 * - display the chat related to this contract
 * @returns
 */
export const ContractView: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { m } = useParams<{ m: EContractViewModes }>();
    const { width } = useDimensions();
    const format = useFormat();
    const navigate = useNavigate();
    const { secureNavigate } = useSecureNavigate();
    const {
        contract,
        job,
        application,
        chat,
        request,
        timesheetComplete,
        openReview,
        handleSigningEmployerChange,
        preFile,
        handleFile,
        joinChat,
        setContract,
        setReload,
        reload,
        hpw,
        reviewAndComplete,
    } = useContractViewState();
    const [collapseChat, setCollapseChat] = useState(false);
    const [mode, setMode] = useState<EContractViewModes>(
        m || EContractViewModes.contractDetails,
    );
    const { height } = useDimensions();
    /**
     * memoized base height  (-40 since padded main view)
     */
    const baseHeight = useMemo(() => height - 40, [height]);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [headerHeight2, setHeaderHeight2] = useState(0);
    const [chatheaderHeight, setChatHeaderHeight] = useState(0);
    /**
     * change mode persistently
     */
    const changeMode = useCallback(
        (next: EContractViewModes) => {
            if (!contract?.documentId) return;
            navigate(`/contract/${contract.documentId}/${next}`, {
                replace: true,
            });
            setMode(next);
        },
        [contract],
    );
    /**
     * return spinner if no contract
     */
    if (!contract) {
        return <Spinner />;
    }
    /**
     * return basic view if type is basic
     */
    if (contract.type === EApplicationType.default) {
        return (
            <BasicContractView
                {...{
                    request,
                    contract,
                    job,
                    application,
                    chat,
                    timesheetComplete,
                    openReview,
                    handleSigningEmployerChange,
                    preFile,
                    handleFile,
                    joinChat,
                    setContract,
                    setReload,
                    reload,
                    hpw,
                    reviewAndComplete,
                }}
            />
        );
    }
    /**
     * render
     */
    return (
        <View
            style={[
                style.paddedMainView,
                { maxWidth: Number.MAX_SAFE_INTEGER },
            ]}
        >
            <View
                onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
            >
                <CCard>
                    <View
                        style={[style.horizontal, style.centeredItems]}
                        {...{
                            dataSet: {
                                cy: 'contract-view-headline-container',
                            },
                        }}
                    >
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <View style={style.leftHeavyPadded}>
                            <ContractTitle
                                contract={contract}
                                horizontalPadded
                            />
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CIcon
                                    icon="calendar"
                                    tint={theme.textSecondaryColor}
                                    size={14}
                                />
                                <CText message={jobMessages.fromTo} />
                                <CText style={style.leftPadded}>
                                    {`${new Date(
                                        contract.from,
                                    ).toLocaleDateString('de')} - ${new Date(
                                        contract.to,
                                    ).toLocaleDateString('de')}`}
                                </CText>
                                {hpw > 0 && (
                                    <CText style={style.leftPadded}>
                                        {`| ${hpw} ${format(
                                            contractMessages.hoursPerWeekUnit,
                                        )}`}
                                    </CText>
                                )}
                            </View>
                        </View>
                    </View>
                </CCard>
                {contract.to <= Date.now() && (
                    <CCard>
                        <CText message={contractMessages.contractExpired} />
                        <CButton
                            onPress={reviewAndComplete}
                            title={contractMessages.completeContract}
                        />
                    </CCard>
                )}
            </View>
            <View style={[style.horizontal, style.flex1]}>
                <View style={style.flex1}>
                    <View
                        style={style.horizontal}
                        onLayout={(e) =>
                            setHeaderHeight2(e.nativeEvent.layout.height)
                        }
                    >
                        <CCard
                            style={[style.horizontalWrap]}
                            outsideStyle={style.flex1}
                        >
                            {Object.values(EContractViewModes)
                                .filter(
                                    (m) =>
                                        m !== EContractViewModes.chat ||
                                        width <= minWidth,
                                )
                                .map((thisMode) => (
                                    <View
                                        key={thisMode}
                                        style={[style.flex1, { minWidth: 151 }]}
                                    >
                                        <CButton
                                            title={contractMessages[thisMode]}
                                            onPress={() => {
                                                changeMode(thisMode);
                                            }}
                                            small
                                            style={[
                                                style.smallMargin,
                                                style.flex1,
                                            ]}
                                            minor={mode !== thisMode}
                                        />
                                    </View>
                                ))}
                        </CCard>
                        {width > minWidth && collapseChat && (
                            <CCard>
                                <CButton
                                    icon="chat_outline"
                                    small
                                    minor
                                    onPress={() => setCollapseChat(false)}
                                    style={style.smallMargin}
                                    title={contractMessages.showChat}
                                />
                            </CCard>
                        )}
                    </View>
                    {mode === EContractViewModes.contractDetails && (
                        <ContractDetails
                            contract={contract}
                            job={job}
                            request={request}
                            application={application}
                            setReload={setReload}
                            openReview={openReview}
                            handleSigningEmployerChange={
                                handleSigningEmployerChange
                            }
                        />
                    )}
                    {mode === EContractViewModes.files && (
                        <ContractFiles
                            contract={contract}
                            maxHeight={
                                baseHeight - headerHeight - headerHeight2
                            }
                            split={width > minWidth && collapseChat}
                        />
                    )}
                    {mode === EContractViewModes.timesheet && (
                        <TimesheetSettings
                            contract={contract}
                            issueReload={() => setReload(Date.now())}
                            maxHeight={
                                baseHeight - headerHeight - headerHeight2
                            }
                        />
                    )}
                    {mode === EContractViewModes.chat && !!chat && (
                        <CCard>
                            <View
                                style={{
                                    height:
                                        baseHeight -
                                        headerHeight -
                                        headerHeight2 -
                                        40,
                                }}
                            >
                                <ChatView
                                    chat={chat}
                                    handleFile={handleFile}
                                    preFile={preFile}
                                    embedded
                                />
                            </View>
                        </CCard>
                    )}
                </View>
                {width > minWidth &&
                    !collapseChat &&
                    (!!chat ? (
                        <View
                            style={[
                                style.flex1,
                                { height: baseHeight - headerHeight },
                            ]}
                        >
                            <CCard>
                                <View
                                    onLayout={(e) =>
                                        setChatHeaderHeight(
                                            e.nativeEvent.layout.height,
                                        )
                                    }
                                    style={[
                                        style.horizontalSplit,
                                        style.centeredItems,
                                        style.verticalPadded,
                                    ]}
                                >
                                    <ContractChatTitle
                                        chat={chat}
                                        contract={contract}
                                    />
                                    <CButton
                                        onPress={() => setCollapseChat(true)}
                                        small
                                        minor
                                        style={style.smallMargin}
                                        icon={'chat'}
                                        title={contractMessages.hideChat}
                                    />
                                </View>
                                <View
                                    style={[
                                        {
                                            height:
                                                baseHeight -
                                                headerHeight -
                                                40 -
                                                chatheaderHeight,
                                        },
                                    ]}
                                    nativeID="contract-chat"
                                >
                                    <ChatView
                                        chat={chat}
                                        handleFile={handleFile}
                                        preFile={preFile}
                                        embedded
                                    />
                                </View>
                            </CCard>
                        </View>
                    ) : (
                        <View
                            style={[style.card, style.flex1]}
                            nativeID="contract-chat"
                        >
                            <CText
                                message={
                                    contractMessages.youAreNotParticipatingInThisChat
                                }
                                centered
                            />
                            <View
                                style={[
                                    style.verticalPadded,
                                    style.horizontalSpaced,
                                ]}
                            >
                                <CButton
                                    onPress={joinChat}
                                    title={contractMessages.joinChat}
                                />
                            </View>
                        </View>
                    ))}
            </View>
        </View>
    );
};
