/**
 * toLocalseString options for double digits
 */
const options = {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 0,
    useGrouping: false,
};
/**
 * make time string from hour and minute like 12:30
 * @param h hours
 * @param m minures
 * @return string
 */
export const timeString = (h: number, m?: number, s?: number) => {
    return `${h ? `${h.toLocaleString('de', options)}:` : ''}${
        h || m ? (m || 0).toLocaleString('de', options) : ''
    }${
        s !== undefined
            ? `${h || m ? ':' : ''}${s.toLocaleString('de', options)}`
            : ''
    }`;
};
/**
 * make time string from hour and minute like 12:30
 * @param h hours
 * @param m minures
 * @return string
 */
export const alwaysFullTimeString = (h: number, m?: number, s?: number) => {
    return `${h.toLocaleString('de', options)}${
        m !== undefined ? `:${m.toLocaleString('de', options)}` : ''
    }${s !== undefined ? `:${s.toLocaleString('de', options)}` : ''}`;
};
/**
 * make time string from hour with decimals
 * @param h hours
 * @return string
 */
export const timeStringFromHours = (h: number) => {
    const min = ((h % 1) * 60).toLocaleString('de', options);
    const hours = Math.floor(h + (min === '60' ? 1 : 0)).toLocaleString(
        'de',
        options,
    );
    return `${hours}:${min !== '60' ? min : '00'}`;
};
