import React, { FC, useContext } from 'react';
import { CText, CPicker, CTextInput, Radio } from '../../../../components';
import { useStyle } from '../../../../utilities/styles';
import { View } from 'react-native';
import { CDatePicker } from '../../../../components';
import { keyof, year } from '../../../../utilities/functions';
import { useFormat } from '../../../../utilities/intl';
import { profileMessages } from '../../../Profile/Profile.messages';
import { countryMessages } from '../../../../utilities/messages/country.messages';
import { ProfileContext } from '../../../Profile/Profile.context';
import { EGender } from '../../../../enums';
import { DoctorRadio } from '../../../../components/Radio/DoctorRadio';
/**
 * common country keys sorted as displayed
 */
const priorisedCountryKeys = ['germany' as const];
/**
 * all remaining country keys sorted alphabetically
 */
const otherCountryKeys = [
    ...keyof(countryMessages)
        .filter((key) => !priorisedCountryKeys.find((k) => k === key))
        .sort(),
];
/**
 * section of professional data that is autofilled based on your account
 * if you only manage your own professional data
 * @param param0
 * @returns
 */
export const TalentOnboardingBasic: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    // context
    const { curData, onChange } = useContext(ProfileContext);
    /**
     * render
     */
    return (
        <>
            <CText message={format(profileMessages.gender2)} />
            <Radio
                onChange={(gender) => onChange({ gender })}
                value={curData.gender}
                values={[
                    {
                        label: format(profileMessages.salutationMale),
                        value: EGender.male,
                    },
                    {
                        label: format(profileMessages.salutationFemale),
                        value: EGender.female,
                    },
                ]}
                horizontal
            />
            <View style={style.verticalPadded}>
                <CText>{format(profileMessages.selectTitle)}</CText>
                <DoctorRadio
                    onChange={(title) => onChange({ title })}
                    title={curData.title}
                />
            </View>
            <CTextInput
                value={curData.firstName}
                onChangeText={(firstName) => onChange({ firstName })}
                label={format(profileMessages.firstName)}
                autoExtend
            />
            <CTextInput
                value={curData.lastName}
                onChangeText={(lastName) => onChange({ lastName })}
                label={format(profileMessages.lastName)}
                autoExtend
            />
            {/* <CTextInput
                value={curData.mail}
                onChangeText={(mail) => onChange({ mail })}
                label={format(profileMessages.mail)}
                autoExtend
            />
            <View>
                <CText message={profileMessages.phone} />
                <PhoneNumberInput
                    value={curData.phone}
                    onChange={(phone) => onChange({ phone })}
                />
            </View> */}
            <CDatePicker
                value={new Date(curData.dateOfBirth)}
                onChange={(dob) => onChange({ dateOfBirth: dob.getTime() })}
                title={profileMessages.dob}
                maxDate={Date.now() - year * 16}
                minDate={Date.now() - year * 100}
            />
            <CTextInput
                label={profileMessages.placeOfBirth}
                value={curData.placeOfBirth}
                onChangeText={(placeOfBirth) => onChange({ placeOfBirth })}
                autoExtend
            />
            <CPicker
                title={profileMessages.nationality}
                values={[
                    {
                        label: `-${format(
                            profileMessages.nationalityPlaceholder,
                        )}-`,
                        value: undefined,
                        hidden: true,
                    },
                    ...priorisedCountryKeys.map((v) => {
                        return {
                            label: format(countryMessages[v]),
                            value: v,
                        };
                    }),
                    {
                        label: `-----------------`,
                        value: undefined,
                        disabled: true,
                    },
                    ...otherCountryKeys.map((v) => {
                        return {
                            label: format(countryMessages[v]),
                            value: v,
                        };
                    }),
                ]}
                value={curData.nationality}
                onChange={(nationality) => onChange({ nationality })}
            />
        </>
    );
};
