import React, { FC } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../../utilities/styles';
import {
    CCheckBox,
    CPicker,
    CText,
    InfoBox,
    RichTextEditor,
    UserWorkplacePicker,
} from '../../../components';
import { useSecureNavigate, useParams } from '../../../utilities/routing';
import { CButton } from '../../../components/elements';
import { CTextInput } from '../../../components';
import { Spinner } from '../../../components';
import { CDatePicker } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { jobMessages } from '../job.messages';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { EmployeeData } from './components/EmployeeData';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { EField, EJobStatus, EWageLevel } from '../../../enums';
import { useJobEditState } from './useJobEditState';
import { EEmploymentType } from '../../../enums/EEmploymentTypes';
import { employmentTypeMessages } from '../../../utilities/messages/employmentType.messages';
import { WorkplaceZipCodeTypeahead } from '../../../components/Typeahead/WorkplaceZipCodeTypeahead';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { day } from '../../../utilities/functions';
import { MJob } from '../../../models';
import { JobWage } from './components/JobWage';
import { WorkingTimes } from './components/WorkingTimes';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';

export const JobEdit: FC = () => {
    // global state
    const style = useStyle();
    const { userData } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const { id } = useParams<{ id: string }>();
    const format = useFormat();
    const { theme } = useTheme();
    // local state
    const {
        loading,
        isCreating,
        onChange,
        job,
        updateValidity,
        isInvalid,
        fromNow,
        setFromNow,
        handleDelete,
        curValidity,
        onChangeLocation,
        currentHospital,
        handleSave,
        unsavedChanges,
        workingHoursSum,
    } = useJobEditState();
    /**
     * return spinner during load
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card, style.horizontalSplit]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText style={style.horizontalHeavyPadded} bold headline>
                        {format(
                            isCreating
                                ? jobMessages.creating
                                : jobMessages.editing,
                            { job: job.id },
                        )}
                    </CText>
                </View>
                <View style={style.horizontal}>
                    {!isCreating && job.status === EJobStatus.draft && (
                        <CButton
                            onPress={() => {
                                secureNavigate(`/job/publish/${id}`, {
                                    replace: true,
                                });
                            }}
                            icon={'eye'}
                            title={jobMessages.publish}
                        />
                    )}
                    {!isCreating && (
                        <CButton
                            onPress={() => {
                                secureNavigate(`/job/duplicate/${id}`, {
                                    replace: true,
                                });
                            }}
                            icon={'plus'}
                            title={jobMessages.duplicate}
                        />
                    )}
                </View>
            </View>
            <View style={[style.card]}>
                <CTextInput
                    value={job.title}
                    placeholder={jobMessages.personalizedJobTitlePlaceholder}
                    label={jobMessages.personalizedJobTitle}
                    onChangeText={(title) => onChange({ title })}
                    maximumLength={40}
                    autoExtend
                    displayMaximumLength
                />
                <View style={style.verticalPadded}>
                    <CCheckBox
                        cy={'start-now'}
                        title={format(jobMessages.fromNow)}
                        checked={fromNow}
                        onCheckedChanged={setFromNow}
                    />
                </View>
                {!fromNow && (
                    <View style={[style.horizontal, { zIndex: 23 }]}>
                        <CDatePicker
                            cy={'from'}
                            title={format(jobMessages.from)}
                            value={job.from ? new Date(job.from) : undefined}
                            onChange={(next) => {
                                const nextFrom = next.getTime();
                                const change: Partial<MJob> = {
                                    from: nextFrom,
                                };
                                if (nextFrom > (job.to || 0)) {
                                    change.to = nextFrom + day;
                                }
                                onChange(change);
                            }}
                            zIndex={22}
                            minDate={Date.now() + day}
                        />
                        <CDatePicker
                            cy={'to'}
                            title={format(jobMessages.to)}
                            value={job.to ? new Date(job.to) : undefined}
                            onChange={(next) =>
                                onChange({ to: next.getTime() })
                            }
                            minDate={job.from}
                        />
                    </View>
                )}
            </View>
            <View style={[style.card, !workingHoursSum && style.errorBorder]}>
                <CText secondaryHeadline message={jobMessages.workingTimes} />
                {!workingHoursSum && (
                    <CText
                        style={style.error}
                        message={jobMessages.enterDays}
                    />
                )}
                <WorkingTimes
                    date={new Date(job.from)}
                    days={job.days || []}
                    onChangeDays={(next) => onChange({ days: next })}
                />
                <CTextInput
                    cy={'working-times'}
                    label={format(jobMessages.extraInfoWorkingTimes)}
                    placeholder={format(jobMessages.workingTimesPlaceholder)}
                    value={job.workingTimes}
                    onChangeText={(workingTimes) => onChange({ workingTimes })}
                    autoExtend
                />
            </View>
            <View
                style={[
                    style.card,
                    !!(curValidity.eduV || curValidity.posV) &&
                        style.errorBorder,
                ]}
            >
                <View style={style.verticalPadded}>
                    <CText headline>{format(jobMessages.employeeInfos)}</CText>
                </View>
                <EmployeeData
                    onChange={onChange}
                    curData={job}
                    onValidityChange={updateValidity}
                    // TODO: probably show title based on profession
                    showAcademicTitleRequired={false}
                />
            </View>
            <View style={[style.card]}>
                <View style={style.verticalPadded}>
                    <CText headline>{format(jobMessages.jobInfos)}</CText>
                </View>
                <CPicker
                    cy={'employment-type'}
                    title={jobMessages.employType}
                    values={[
                        // ...Object.values(EEmploymentType).map((v) => {
                        ...[
                            EEmploymentType.personnelLeasing,
                            EEmploymentType.direct,
                        ].map((v) => {
                            return {
                                label: format(employmentTypeMessages[v]),
                                value: v,
                            };
                        }),
                    ]}
                    onChangeMulti={(type) => onChange({ type })}
                    multiValues={job.type}
                />
                {/* {job.type === EEmploymentType.personnelLeasing && (
                    <CButton
                        cy={'getLeasing'}
                        onPress={() => {
                            callFunction('getLeasingSelfInfo');
                        }}
                    />
                )} */}
                {job.type.includes(EEmploymentType.fullTime) && (
                    <JobWage curData={job} onChange={onChange} />
                )}
                <View style={style.verticalPadded}>
                    <CCheckBox
                        cy={'travel-pay'}
                        checked={job.travelPay}
                        onCheckedChanged={(travelPay) =>
                            onChange({ travelPay })
                        }
                        title={format(jobMessages.travelPay)}
                    />
                </View>
            </View>
            <View
                style={[
                    style.card,
                    {
                        zIndex: 10,
                    },
                    !!curValidity.workplace && {
                        borderColor: theme.errorColor,
                        borderWidth: 1,
                    },
                ]}
            >
                <View style={style.verticalPadded}>
                    <CText headline>{format(jobMessages.employerInfos)}</CText>
                </View>
                {isSuperUser(userData) ? (
                    <WorkplaceZipCodeTypeahead
                        currentWorkplace={currentHospital}
                        onChangeWorkplace={onChangeLocation}
                    />
                ) : (
                    <UserWorkplacePicker
                        value={currentHospital}
                        onChange={onChangeLocation}
                        autoSet
                    />
                )}
                {!!currentHospital && (
                    <View style={style.verticalPadded}>
                        <CText>
                            {`${currentHospital.address.street}  ${currentHospital.address.number}`}
                        </CText>
                        <CText>
                            {`${currentHospital.address.zipCode}  ${currentHospital.address.city}`}
                        </CText>
                    </View>
                )}
                {/* // ! unused because the mind changed :) */}
                {/* {!!currentHospital && job.employeeField !== EField.medic && (
                    <View style={style.verticalPadded}>
                        <CCheckBox
                            title={jobMessages.showFullAddress}
                            checked={job.useFullAddress}
                            onCheckedChanged={(useFullAddress) =>
                                onChange({ useFullAddress })
                            }
                        />
                    </View>
                )} */}
            </View>
            <View style={[style.card]}>
                <CTextInput
                    cy={'liability'}
                    label={format(jobMessages.liability)}
                    placeholder={format(jobMessages.liabilityPlaceholder)}
                    value={job.liability}
                    onChangeText={(liability) => onChange({ liability })}
                    autoExtend
                />
                <CPicker
                    cy={'accommodation'}
                    title={format(jobMessages.accommodation)}
                    value={job.accommodation}
                    values={[
                        {
                            label: `-${format(
                                jobMessages.accommodationPlaceholder,
                            )}-`,
                            hidden: true,
                            value: '',
                        },
                        {
                            label: format(jobMessages.providedAccommodation),
                            value: 'providedAccommodation',
                        },
                        {
                            label: format(jobMessages.selfselfAccommodation),
                            value: 'selfselfAccommodation',
                        },
                    ]}
                    onChange={(accommodation) => onChange({ accommodation })}
                    allowOther
                    onChangeOther={(otherSelfAccommodation) =>
                        onChange({ otherSelfAccommodation })
                    }
                    valueOther={job.otherSelfAccommodation}
                />
            </View>
            <View style={style.card}>
                <CText secondaryHeadline message={jobMessages.freeText} />
                <RichTextEditor
                    onChange={(freeText) => {
                        onChange({ freeText });
                    }}
                    text={job.freeText}
                />
            </View>
            {/* <View style={[style.card]}>
                <View style={style.verticalPadded}>
                    <CText bold headline>
                        {format(jobMessages.saveAs)}
                    </CText>
                </View>
                <View style={[style.horizontalWrap]}>
                    <CButton
                        title={format(jobMessages.draft)}
                        onPress={() => onChange({ status: EJobStatus.draft })}
                        minor={job.status !== EJobStatus.draft}
                        small
                        style={[style.horizontalPadded, { marginVertical: 5 }]}
                        disabled={job.status === EJobStatus.filled}
                    />
                    <CButton
                        title={format(jobMessages.public)}
                        onPress={() => onChange({ status: EJobStatus.public })}
                        minor={job.status !== EJobStatus.public}
                        small
                        style={[style.horizontalPadded, { marginVertical: 5 }]}
                        disabled={job.status === EJobStatus.filled}
                    />
                    <CButton
                        title={`${format(jobMessages.private)}${
                            currentHospital
                                ? ` (${currentHospital?.agencies.length})`
                                : ''
                        }`}
                        onPress={() => onChange({ status: EJobStatus.private })}
                        minor={job.status !== EJobStatus.private}
                        small
                        style={[style.horizontalPadded, { marginVertical: 5 }]}
                        disabled={job.status === EJobStatus.filled}
                    />
                    <CButton
                        title={format(jobMessages.filled)}
                        onPress={() => onChange({ status: EJobStatus.filled })}
                        small
                        style={[style.horizontalPadded, { marginVertical: 5 }]}
                        disabled
                    />
                </View>
                {job.status === EJobStatus.private && (
                    <InfoBox
                        message={format(jobMessages.privateJobInfo, {
                            x: currentHospital?.agencies.length || 0,
                            workplace: currentHospital?.name,
                        })}
                        type={'warning'}
                    />
                )}
            </View> */}
            <View style={style.verticalPadded}>
                {isInvalid && (
                    <View style={{ marginHorizontal: 'auto' }}>
                        <CText style={style.error}>
                            {format(generalMessages.somethingWrong)}
                        </CText>
                    </View>
                )}
                <View style={style.horizontalSplit}>
                    <View />
                    <View style={{ alignItems: 'flex-end' }}>
                        {unsavedChanges && (
                            <CText style={style.warning}>
                                {format(generalMessages.unsavedChanges)}
                            </CText>
                        )}
                        <View style={style.horizontal}>
                            {!isCreating && (
                                <CButton
                                    disabled={job.status === EJobStatus.filled}
                                    title={format(actionMessages.delete)}
                                    onPress={handleDelete}
                                    danger
                                />
                            )}
                            <CButton
                                cy={'save'}
                                disabled={
                                    !!isInvalid ||
                                    job.status === EJobStatus.filled ||
                                    !workingHoursSum
                                }
                                title={format(actionMessages.save)}
                                onPress={handleSave}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </ScrollProvider>
    );
};
