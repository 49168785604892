import account from './account.svg';
import account_outline from './account-outline.svg';
import accountCash from './account-cash.svg';
import accountCash_outline from './account-cash-outline.svg';
import accountMulti from './account-multiple.svg';
import accountMulti_outline from './account-multiple-outline.svg';
import accountMultiPlus from './account-multiple-plus.svg';
import accountMultiPlus_outline from './account-multiple-plus-outline.svg';
import accountThin_outline from './accoun_thin_outline.svg';
import agency from './account-network.svg';
import agency_outline from './account-network-outline.svg';
import alert from './alert.svg';
import badgeAccount from './badge-account.svg';
import badgeAccount_outline from './badge-account-outline.svg';
import bell from './bell.svg';
import bell_outline from './bell-outline.svg';
import calendar from './calendar.svg';
import calendar_outline from './calendar-outline.svg';
import calendarClock from './calendar-clock.svg';
import car from './car.svg';
import car_outline from './car-outline.svg';
import cardAccountDetails from './card-account-details.svg';
import cardAccountDetails_outline from './card-account-details-outline.svg';
import cash from './cash.svg';
import chat from './chat.svg';
import chat_outline from './chat-outline.svg';
import check from './check.svg';
import checkDouble from './check-double.svg';
import chevronDown from './chevron-down.svg';
import chevronLeft from './chevron-left.svg';
import chevronRight from './chevron-right.svg';
import chevronUp from './chevron-up.svg';
import clipboardAccount from './clipboard-account.svg';
import clipboardAccount_outline from './clipboard-account-outline.svg';
import clipboardMulti from './clipboard-multiple.svg';
import clipboardMulti_outline from './clipboard-multiple-outline.svg';
import clock from './clock.svg';
import clock_outline from './clock-outline.svg';
import close from './close.svg';
import cog from './cog.svg';
import cog_outline from './cog-outline.svg';
import commentQuestion from './comment-question.svg';
import commentQuestion_outline from './comment-question-outline.svg';
import contract from './contract.svg';
import contractInfos from './contractinfos.svg';
import dotsVertical from './dots-vertical.svg';
import download from './download.svg';
import duplicate from './content-duplicate.svg';
import email from './email.svg';
import eye from './eye-outline.svg';
import eyeStrikethrought from './eye_strikethrough.svg';
import fileDocument from './file-document-edit.svg';
import fileDocument_outline from './file-document-edit-outline.svg';
import globe from './globe.svg';
import hamburger from './hamburger.svg';
import helpCircle from './help-circle.svg';
import helpCircle_outline from './help-circle-outline.svg';
import history from './history.svg';
import home from './home.svg';
import home_outline from './home-outline.svg';
import info_outline from './info-outline.svg';
import lock from './lock.svg';
import login from './login.svg';
import logout from './logout.svg';
import mail from './mail.svg';
import mapMarker_outline from './map-marker-outline.svg';
import message from './message.svg';
import minus from './minus.svg';
import namirial from './namirial.svg';
import nextSteps from './nextsteps.svg';
import phone from './phone.svg';
import pin from './pin.svg';
import plan from './plan-outline.svg';
import plan_outline from './plan-outline.svg';
import plane from './plane.svg';
import plus from './plus.svg';
import publish from './publish.svg';
import repeat from './repeat.svg';
import repeatOff from './repeat-off.svg';
import republish from './republish.svg';
import resume from './resume.svg';
import resume_outline from './resume_outline.svg';
import review from './review.svg';
import school from './school.svg';
import share from './share.svg';
import signature from './signature.svg';
import smartphone from './smartphone.svg';
import star from './star.svg';
import star_half from './star-half.svg';
import star_outline from './star-outline.svg';
import timer from './timer.svg';
import tune from './tune.svg';
import upload from './upload.svg';
import verified from './verified.svg';
import web from './web.svg';
import whatsapp from './whatsapp.svg';
import workplace from './office-building.svg';
import workplace_outline from './office-building-outline.svg';

export const icons = {
    account_outline,
    account,
    accountCash_outline,
    accountCash,
    accountMulti_outline,
    accountMulti,
    accountMultiPlus_outline,
    accountMultiPlus,
    accountThin_outline,
    agency_outline,
    agency,
    alert,
    badgeAccount_outline,
    badgeAccount,
    bell_outline,
    bell,
    calendar_outline,
    calendar,
    calendarClock,
    car_outline,
    car,
    cardAccountDetails_outline,
    cardAccountDetails,
    cash,
    chat_outline,
    chat,
    check,
    checkDouble,
    chevronDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    clipboardAccount_outline,
    clipboardAccount,
    clipboardMulti_outline,
    clipboardMulti,
    clock_outline,
    clock,
    close,
    cog_outline,
    cog,
    commentQuestion_outline,
    commentQuestion,
    contract,
    contractInfos,
    dotsVertical,
    download,
    duplicate,
    email,
    eye,
    eyeStrikethrought,
    fileDocument_outline,
    fileDocument,
    globe,
    hamburger,
    helpCircle_outline,
    helpCircle,
    history,
    home_outline,
    home,
    info_outline,
    lock,
    login,
    logout,
    mail,
    mapMarker_outline,
    message,
    minus,
    namirial,
    nextSteps,
    phone,
    pin,
    plan_outline,
    plan,
    plane,
    plus,
    private: lock,
    public: globe,
    publish,
    repeat,
    repeatOff,
    republish,
    resume_outline,
    resume,
    review,
    school,
    share,
    signature,
    smartphone,
    star_half,
    star_outline,
    star,
    timer,
    tune,
    upload,
    verified,
    web,
    whatsapp,
    workplace_outline,
    workplace,
};
