import React, { FC } from 'react';
import { View } from 'react-native';
import { MApplication, MContract, MJob, MRequest } from '../../../../models';
import { ApplicantOverview } from '../../components/ApplicantOverview';
import { isEmployer, isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { WorkplaceSettings } from './WorkplaceSettings';
import { CIcon, CText } from '../../../../components';
import { filenameMessages } from '../../../../utilities/messages';
import { EUserType } from '../../../../enums';
import { contractMessages } from '../../contract.messages';
import { useStyle } from '../../../../utilities/styles';
import { SelectSigningEmployer } from '../../components/SelectSigningEmployer';
import { useFormat } from '../../../../utilities/intl';
import { ScrollProvider } from '../../../../utilities/contexts';
import { ContractOverview } from '../../components/ContractOverview';

export const ContractDetails: FC<{
    contract: MContract;
    job?: MJob;
    request?: MRequest;
    application?: MApplication;
    setReload: (n: number) => void;
    openReview: () => Promise<boolean>;
    handleSigningEmployerChange: (signingEmployerId: string) => void;
}> = ({
    contract,
    job,
    request,
    application,
    setReload,
    openReview,
    handleSigningEmployerChange,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { userData } = useFireBase();
    /**
     * render
     */
    return (
        <ScrollProvider>
            {(application || contract.employee) && (
                <ApplicantOverview
                    contract={contract}
                    request={request}
                    application={application}
                    applicant={contract.employee}
                />
            )}
            <ContractOverview
                job={job}
                contract={contract}
                setReload={setReload}
                openReview={openReview}
            />
            {(isSuperUser(userData) || isEmployer(userData)) && (
                <WorkplaceSettings
                    contract={contract}
                    issueReload={() => setReload(Date.now())}
                />
            )}
            <SelectSigningEmployer
                contract={contract}
                onChange={handleSigningEmployerChange}
            />
            {userData.type !== EUserType.talent && contract.to > Date.now() && (
                <View style={[style.card, style.horizontal]}>
                    <View
                        style={[
                            { width: 80, paddingRight: 5 },
                            style.centeredItems,
                        ]}
                    >
                        <CIcon icon={'nextSteps'} size={70} />
                    </View>
                    <View style={style.flex1}>
                        <CText
                            style={style.verticalPadded}
                            headline
                            message={contractMessages.infoTitle}
                        />
                        <CText message={contractMessages.mainInfoText} />
                        {[
                            filenameMessages.leasingContract,
                            filenameMessages.equalPayDeclaration,
                        ].map((m, i) => (
                            <CText
                                key={m.id}
                                message={`${i + 1}. ${format(m)}`}
                            />
                        ))}
                        <View style={style.paddedThinSeparator} />
                        <CText
                            style={style.verticalPadded}
                            secondaryHeadline
                            message={contractMessages.secondaryInfoText}
                        />
                        {[
                            filenameMessages.houseRules,
                            filenameMessages.locationPlan,
                            filenameMessages.contactDetails,
                            filenameMessages.educationCertificate,
                            filenameMessages.driversLicense,
                            filenameMessages.timesheetTemplate,
                        ].map((m, i) => (
                            <CText
                                key={m.id}
                                message={`${i + 1}. ${format(m)}`}
                            />
                        ))}
                    </View>
                </View>
            )}
        </ScrollProvider>
    );
};
