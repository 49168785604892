import { ERegion } from '../enums';
import { ETalentState } from '../enums/ETalentState';
import { MUserFile } from './MFile';
import { MProfessionalInfo } from './MProfessionalInfo';
import { MRates } from './MRates';

export class MProfessionalProfile extends MProfessionalInfo {
    /**
     * all assigned non admin users that can manage this profile
     */
    public moderators: string[] = [];
    /**
     * agency that is assigned to this user
     */
    public agencyId = '';
    /**
     * optional: the user that is linked to this profile
     */
    public author = '';
    /**
     * temp status for when the profile was created during application process or for invitation
     */
    public temp = false;
    /**
     * invite set true to check if this profile was invited
     */
    public invite = false;
    /**
     * open set true to be accessible from url from unregistered users
     */
    public open = false;
    /**
     * optional: value to be set if created from form and not jet seen in detail view (notification bubble)
     */
    public filledOn?: number;
    /**
     * optional: rate of profile. (hourly wage)
     */
    public wage = new MRates();
    /**
     * set to true for automatic application on new jobs matchiung this professional profile
     */
    public isWatching = false;

    public region = ERegion.de;
    /**
     * optional: the user that is linked to this profile
     */
    public uid?: string;

    public state = ETalentState.invite;

    public internalRating = 0;
    public hasContract = false;
    public terminated = false;
    public terminateOn = 0;

    public disabled = false;
    /**
     * mirror of the profile files
     * ! only for loose reference
     */
    public files: MUserFile[] = [];
    /**
     * mirror of the private profile files
     * ! only for loose reference
     */
    public privateFiles: MUserFile[] = [];

    constructor(params: Partial<MProfessionalProfile> = {}) {
        super(params);

        Object.assign(this, params);
        // fixes from super
        MProfessionalInfo.fixConstructor(this, params);

        if (params.wage) {
            /**
             * * legacy wage fix
             */
            if (typeof params.wage === 'number') {
                const uPs = params as any;

                this.wage = new MRates({
                    wage: uPs.wage,
                    regularWage: uPs.regularWage,
                    standbyWage: uPs.standbyWage,
                    onCallActiveWage: uPs.onCallActiveWage,
                    onCallPassiveWage: uPs.onCallPassiveWage,
                });
            } else {
                /**
                 * wage assign
                 */
                this.wage = new MRates(params.wage);
            }
        }
        const unsafeParams = params as any;
        /**
         * legacy missnamed field fix
         */
        if (unsafeParams.user) {
            this.author = unsafeParams.user;
        }

        if (params.files) {
            this.files = params.files.map((f) => new MUserFile(f));
        }

        if (params.privateFiles) {
            this.privateFiles = params.privateFiles.map(
                (f) => new MUserFile(f),
            );
        }
    }
}
