import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CIcon, CText } from '../../../../../components';
import { FilePicker } from '../../../../../components/FilePicker';
import {
    EProfession,
    EDoctorFiles,
    EGeneralFiles,
    EUserFiles,
    ECollections,
    EEmployeeFiles,
    EFile,
} from '../../../../../enums';
import { useFireBase } from '../../../../../utilities/firebase';
import { useFormat } from '../../../../../utilities/intl';
import {
    actionMessages,
    filenameMessages,
    generalMessages,
} from '../../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { ProfileContext } from '../../../../Profile/Profile.context';
import { MUserFile } from '../../../../../models';
import { useDialog } from '../../../../../utilities/dialog';
import { useLock } from '../../../../../utilities/hooks';
import { fileInputMessages } from '../../../../../components/Files/fileInput.messages';
import { DocumentRequest } from './components/DocumentRequest';
import { profProfileMessages } from '../../../../ProfProfile/ProfProfile.messages';
import { useDefaultFileRequests } from './functions';
import { getFileColor } from '../../../../../utilities/constants';
import { profileMessages } from '../../../../Profile/Profile.messages';
/**
 * view to display documents and upload
 * @param param0
 * @returns
 */
export const TalentOnboardingDocuments: FC = () => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { uploadToStorage, callFunction } = useFireBase();
    const { curTalentData, onChangeTalentData } = useContext(ProfileContext);
    const dialog = useDialog();
    const { lock } = useLock();
    // local state
    const mountedOn = useMemo(() => Date.now(), []);
    /**
     * memoized files
     */
    const files = useMemo(() => {
        console.log(curTalentData?.files);
        return [
            ...(curTalentData?.files || []),
            ...(curTalentData?.privateFiles.map(
                (f) => new MUserFile({ ...f, private: true }),
            ) || []),
        ];
    }, [curTalentData?.files, curTalentData?.privateFiles]);
    /**
     * open file requests
     */
    const fileRequests = useMemo(
        () => files.filter((f) => f.request && !f.name) || [],
        [files],
    );
    /**
     * memoized new files
     */
    const filesUploadedDuringThisSession = useMemo(
        () => files.filter((f) => f.createdOn > mountedOn && f.name),
        [files, mountedOn],
    );
    /**
     * memoized agencyId
     */
    const agencyId = useMemo(
        () => curTalentData?.agencyId,
        [curTalentData?.agencyId],
    );
    /**
     * memoized list of accessible documents
     */
    const docs = useMemo(() => {
        const generalFiles: EFile[] = Object.values(EGeneralFiles);
        const employeeFiles: EFile[] = Object.values(EEmployeeFiles);
        const next = [...generalFiles, ...employeeFiles];
        if (!curTalentData) {
            return next;
        } else if (curTalentData.profession === EProfession.doctor) {
            next.push(...Object.values(EDoctorFiles));
        } else if (curTalentData.profession === EProfession.nurse) {
            // * No nurse files
        }
        return next;
    }, [curTalentData]);
    /**
     * pre file
     */
    const preFile = useCallback(async () => {
        let nextType: EFile = docs[0] || EGeneralFiles.other;
        let isPrivateFile = false;
        if (
            await dialog({
                title: fileInputMessages.selectFileType,
                message: fileInputMessages.selectFileTypeText,
                icon: 'info',
                pickerInputs: [
                    {
                        id: 'type',
                        title: fileInputMessages.fileType,
                        placeholder: fileInputMessages.fileTypePlaceholder,
                        values: docs.map((ecf) => {
                            return {
                                label: filenameMessages[ecf],
                                value: ecf,
                            };
                        }),
                        defaultValue: EEmployeeFiles.resume,
                    },
                ],
                checkInputs: [
                    {
                        id: 'private',
                        title: profProfileMessages.fileCanBeShared,
                        defaultValue: true,
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.ok,
                        disabled: (inputs) =>
                            !inputs?.find((i) => i.id === 'type'),
                        onPress: (inputs) => {
                            const ioi = inputs?.find((i) => i.id === 'type');
                            if (ioi) {
                                nextType = ioi.value as EFile;
                            }
                            const coi = inputs?.find(
                                (i) => i.id === 'private',
                            )?.value;
                            if (!coi) {
                                isPrivateFile = true;
                            }
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            })
        ) {
            return { nextType, isPrivateFile };
        }
    }, [dialog, docs]);
    /**
     * callback to handle a new fileupload
     */
    const handleFile = useCallback(
        async (
            newFn: string,
            file: Uint8Array,
            preFileResult: { nextType: EUserFiles; isPrivateFile?: boolean },
        ) => {
            if (!curTalentData || !onChangeTalentData) return;
            const unlock = lock();
            const fnS = newFn.split('.');
            const ending = fnS.pop();
            if (ending !== 'pdf') {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: profProfileMessages.onlyPDFUploadAllowed,
                    icon: 'error',
                });
                throw 'Only PDF upload allowed';
            }
            // TODO: better name generation
            const fakeId = Date.now().toString(36);
            const basename = fakeId + fnS.join('.');
            try {
                const preassignMeta = await uploadToStorage(
                    `${ECollections.agencies}/${agencyId}/profiles/${curTalentData.documentId}/tmp/unsafeupload.${ending}`,
                    file,
                );
                const fileRef = new MUserFile({
                    documentId: fakeId,
                    type: preFileResult.nextType,
                    name: `${fnS.shift()}.${ending}`,
                });
                const fullPath = await callFunction('assignProfProfileFile', {
                    preassignMeta,
                    basename: basename,
                    file: fileRef,
                });
                fileRef.path = fullPath;
                unlock();
                if (preFileResult.isPrivateFile) {
                    onChangeTalentData({
                        privateFiles: [
                            ...curTalentData.privateFiles.filter(
                                (f) =>
                                    !(
                                        f.request === true &&
                                        f.type === fileRef.type
                                    ),
                            ),
                            fileRef,
                        ],
                    });
                } else {
                    onChangeTalentData({
                        files: [
                            ...curTalentData.files.filter(
                                (f) =>
                                    !(
                                        f.request === true &&
                                        f.type === fileRef.type
                                    ),
                            ),
                            fileRef,
                        ],
                    });
                }
                dialog({
                    title: generalMessages.uploadSuccess,
                    icon: 'success',
                });
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [curTalentData, agencyId, onChangeTalentData],
    );
    /**
     * callback to handle profile picture upload
     */
    const handleProfilePicture = useCallback(
        async (newFn: string, file: Uint8Array) => {
            if (!curTalentData || !onChangeTalentData) return;
            const unlock = lock();
            const fnS = newFn.split('.');
            const ending = fnS.pop();
            // TODO: better name generation
            const fakeId = Date.now().toString(36);
            const basename = fakeId + fnS.join('.');
            try {
                const preassignMeta = await uploadToStorage(
                    `${ECollections.agencies}/${agencyId}/profiles/${curTalentData.documentId}/tmp/unsafeupload.${ending}`,
                    file,
                );
                const downloadUrl = await callFunction('assignTalentPicture', {
                    preassignMeta,
                    basename: basename,
                });
                unlock();
                onChangeTalentData({
                    picture: downloadUrl,
                });
                dialog({
                    title: generalMessages.uploadSuccess,
                    icon: 'success',
                });
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [curTalentData, agencyId, onChangeTalentData],
    );
    /**
     * effect to set default request
     */
    useDefaultFileRequests();
    /**
     * render
     */
    return (
        <>
            {(!!fileRequests.length || !curTalentData?.picture) && (
                <CText
                    secondaryHeadline
                    message={profProfileMessages.requestedDocuments}
                />
            )}
            {!curTalentData?.picture && (
                <DocumentRequest {...{ handleProfilePicture }} />
            )}
            {fileRequests.map((f, i) => {
                return (
                    <DocumentRequest
                        key={i}
                        request={f}
                        handleFile={handleFile}
                    />
                );
            })}
            <View style={[style.centeredItems]}>
                <CText message={profProfileMessages.uploadOtherFile} />
                <FilePicker
                    preFile={preFile}
                    onFile={(fn, f, pfr) => handleFile(fn, f, pfr)}
                />
            </View>
            {!!filesUploadedDuringThisSession.length && (
                <CText
                    secondaryHeadline
                    message={profProfileMessages.uploadedFiles}
                />
            )}
            {!!curTalentData?.picture && (
                <View
                    style={[
                        style.horizontal,
                        style.centeredItems,
                        style.embeddedCard,
                    ]}
                >
                    <CIcon icon="check" tint={theme.successColor} />
                    <CText
                        message={profileMessages.profilePicture}
                        style={[
                            {
                                color: theme.accentColor,
                                borderColor: theme.accentColor,
                                borderWidth: 1,
                                borderRadius: 3,
                                padding: 2,
                                fontSize: 12,
                            },
                            style.horizontalPadded,
                        ]}
                    />
                </View>
            )}
            {(filesUploadedDuringThisSession || []).map((f, i) => {
                return (
                    <View
                        key={i}
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            style.embeddedCard,
                        ]}
                    >
                        <CIcon icon="check" tint={theme.successColor} />
                        {f.private && <CIcon icon="lock" />}
                        <CText
                            message={filenameMessages[f.type]}
                            style={[
                                {
                                    color: getFileColor(f.type),
                                    borderColor: getFileColor(f.type),
                                    borderWidth: 1,
                                    borderRadius: 3,
                                    padding: 2,
                                    fontSize: 12,
                                },
                                style.horizontalPadded,
                            ]}
                        />
                        <CText numberOfLines={1} bold>
                            {f.name}
                        </CText>
                    </View>
                );
            })}
        </>
    );
};
