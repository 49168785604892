import React, {
    FC,
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { useFireBase } from '../../../utilities/firebase';
import { MProfessionalProfile } from '../../../models';
import { CButton, CIcon, CText, ProgressBar } from '../../../components';
import { actionMessages } from '../../../utilities/messages';
import { useSearchParams } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { urlHandlerMessages } from '../urlHandler.messages';
import { ECollections } from '../../../enums';
import { useLock } from '../../../utilities/hooks';
import { keyof } from '../../../utilities/functions';
import { useTalentEditState } from '../../ProfProfile/Edit/useTalentEditState';
import {
    ProfessionalHistory,
    ProfessionalInformation,
    ProfessionalLanguage,
} from '../../ProfProfile/Edit/components';
import { ProfileContext } from '../../Profile/Profile.context';
import {
    TalentOnboardingBasic,
    TalentOnboardingContact,
    TalentOnboardingDocuments,
    TalentOnboardingTravel,
} from './components';

enum ETalentOnboardingStep {
    basic = 'basic',
    contact = 'contact',
    travel = 'travel',
    professional = 'professional',
    langs = 'langs',
    history = 'history',
    documents = 'documents',
}

export const TalentOnboarding: FC = () => {
    // global state
    const { isLoggedIn, logOut, callFunction, getDataById } = useFireBase();
    const [searchParams] = useSearchParams();
    const { theme } = useTheme();
    const { lock } = useLock();
    const style = useStyle();
    // local state
    const [step, setStep] = useState(ETalentOnboardingStep.basic);
    const [done, setDone] = useState(false);
    const [register, setRegister] = useState(false);
    const [mail, setMail] = useState<string>();
    const [error, setError] = useState<string>();
    /**
     * preloaded profile data
     */
    const [predefinedData, setPredefinedData] =
        useState<MProfessionalProfile>();
    /**
     * selected step index
     */
    const selectedStepIndex = useMemo(
        () => keyof(ETalentOnboardingStep).indexOf(step),
        [step],
    );
    /**
     * callback to handle save
     */
    const saveOverride = useCallback((next: MProfessionalProfile) => {
        const unlock = lock();
        callFunction('fillProfProfile', next).then((r) => {
            if (r === 'success') {
                setDone(true);
            } else {
                setError(r);
            }
            unlock();
        });
    }, []);
    /**
     * callback to handle saving and registering as talent user
     */
    const saveAndRegisterNewUser = useCallback((next: MProfessionalProfile) => {
        const unlock = lock();
        callFunction('fillProfProfile', next).then((r) => {
            if (r === 'success') {
                if (next.mail) {
                    setMail(next.mail);
                }
                setRegister(true);
            } else {
                setError(r);
            }
            unlock();
        });
    }, []);
    /**
     * profile edit state
     */
    const {
        profInfos,
        prevProfInfos,
        curData,
        prevData,
        prevProfileName,
        chat,
        agency,
        agencies,
        onChange,
        setNote,
        setEditingNote,
        handleNoteSave,
        note,
        editingNote,
        inviteUrl,
        handleReinvite,
        handleInviteCancel,
        handleTermination,
        handleTerminationRevert,
        handleDelete,
        handleSave,
        unsavedChanges,
        curValidity,
        curRecommends,
        validities,
    } = useTalentEditState({
        nested: true,
        saveOverride,
        saveAndRegisterNewUser,
        predefinedData,
    });

    const stepValidity = useMemo(() => {
        switch (step) {
            case ETalentOnboardingStep.professional:
                return !!curValidity.professional;
            case ETalentOnboardingStep.langs:
                return !!curValidity.lang;
        }
        return false;
    }, [step, curValidity]);
    /**
     * effect to pre load prof profile
     */
    useEffect(() => {
        const documentId = searchParams.get('profile');
        if (documentId) {
            getDataById(ECollections.profProfiles, documentId).then((v) => {
                setPredefinedData(new MProfessionalProfile(v));
            });
        }
    }, [searchParams]);

    // if (register) {
    //     return (
    //         <StandaloneWrapper>
    //             <Register mail={mail} />
    //         </StandaloneWrapper>
    //     );
    // }

    if (done) {
        return (
            <StandaloneWrapper>
                <View style={[style.centeredItems, style.horizontalSpaced]}>
                    <View style={style.centeredItems}>
                        <CIcon
                            icon={'check'}
                            tint={theme.successColor}
                            size={50}
                        />
                        <CText
                            message={
                                urlHandlerMessages.successfullyEnteredInformations
                            }
                        />
                    </View>
                </View>
            </StandaloneWrapper>
        );
    }

    if (!predefinedData || error) {
        return (
            <StandaloneWrapper>
                <View style={[style.centeredItems, style.horizontalSpaced]}>
                    <View style={style.centeredItems}>
                        <CIcon
                            icon={'close'}
                            tint={theme.errorColor}
                            size={50}
                        />
                        <CText
                            message={urlHandlerMessages.thereWasAnErrorEntering}
                        />
                        <CText>{error}</CText>
                    </View>
                </View>
            </StandaloneWrapper>
        );
    }

    if (!isLoggedIn) {
        return (
            <StandaloneWrapper>
                <ProfileContext.Provider
                    value={{
                        curData,
                        curTalentData: curData,
                        prevData,
                        prevTalentData: prevData,
                        curValidity,
                        curRecommends,
                        onChange,
                        onChangeTalentData: onChange,
                    }}
                >
                    <View style={style.verticalPadded}>
                        <ProgressBar
                            percentage={
                                (selectedStepIndex + 1) /
                                keyof(ETalentOnboardingStep).length
                            }
                        />
                    </View>
                    {step === ETalentOnboardingStep.basic && (
                        <TalentOnboardingBasic />
                    )}
                    {step === ETalentOnboardingStep.contact && (
                        <TalentOnboardingContact />
                    )}
                    {step === ETalentOnboardingStep.travel && (
                        <TalentOnboardingTravel />
                    )}
                    {step === ETalentOnboardingStep.professional && (
                        <ProfessionalInformation />
                    )}
                    {step === ETalentOnboardingStep.langs && (
                        <ProfessionalLanguage />
                    )}
                    {step === ETalentOnboardingStep.history && (
                        <ProfessionalHistory />
                    )}
                    {step === ETalentOnboardingStep.documents && (
                        <TalentOnboardingDocuments />
                    )}
                    <View
                        style={[
                            style.horizontalSpaced,
                            style.verticalHeavyPadded,
                        ]}
                    >
                        {!!selectedStepIndex && (
                            <CButton
                                title={actionMessages.back}
                                onPress={() =>
                                    setStep(
                                        keyof(ETalentOnboardingStep)[
                                            selectedStepIndex - 1
                                        ] as ETalentOnboardingStep,
                                    )
                                }
                                warning
                            />
                        )}
                        {selectedStepIndex !==
                            keyof(ETalentOnboardingStep).length - 1 && (
                            <CButton
                                title={actionMessages.continue}
                                onPress={() =>
                                    setStep(
                                        keyof(ETalentOnboardingStep)[
                                            selectedStepIndex + 1
                                        ] as ETalentOnboardingStep,
                                    )
                                }
                                disabled={!!stepValidity}
                            />
                        )}
                        {selectedStepIndex ===
                            keyof(ETalentOnboardingStep).length - 1 && (
                            <CButton
                                title={actionMessages.save}
                                onPress={handleSave}
                            />
                        )}
                    </View>
                </ProfileContext.Provider>
            </StandaloneWrapper>
        );
    }

    return (
        <View>
            <CText message={urlHandlerMessages.thisViewIsForLoogedOutUsers} />
            <CText message={urlHandlerMessages.pleaseLogOut} />
            <CButton title={actionMessages.logout} onPress={() => logOut()} />
        </View>
    );
};
