import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CIcon,
    CImage,
    CText,
    FilePicker,
    InfoBox,
} from '../../components';
import { useFireBase } from '../../utilities/firebase';
import { useStyle, useTheme } from '../../utilities/styles';
import { documentScanMessages } from './documentScan.messages';
import { ECollections } from '../../enums';
import { MUserIdentDoc } from '../../models';
import { useFormat } from '../../utilities/intl';
import { useDialog, useUnsavedChangesDialog } from '../../utilities/dialog';
import { generalMessages } from '../../utilities/messages';
import { useSecureNavigate } from '../../utilities/routing';
import { DocumentValues } from './components/DocumentValues';
import { ScrollProvider } from '../../utilities/contexts';
import { useLock } from '../../utilities/hooks';

export const DocumentScan: FC = () => {
    const style = useStyle();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const dialog = useDialog();
    const unsavedChangesDialog = useUnsavedChangesDialog();
    const { lock, unlock } = useLock();
    const { theme } = useTheme();
    const format = useFormat();
    const {
        callFunction,
        uploadToStorage,
        userData,
        getDataById,
        getFileDownloadUrl,
    } = useFireBase();
    // local state
    const [doc, setDoc] = useState<MUserIdentDoc>();
    const [frontImage, setFrontImage] = useState<string>();
    const [backImage, setBackImage] = useState<string>();
    const [personImage, setPersonImage] = useState<string>();
    /**
     * callback to load a new doc instance
     */
    const loadDoc = useCallback((docId: string) => {
        if (!userData.documentId) {
            return;
        }
        getDataById(
            `${ECollections.users}/${userData.documentId}/${ECollections.identDocuments}`,
            docId,
        ).then((v) => {
            const next = new MUserIdentDoc(v);

            setDoc(next);

            if (next.front) {
                getFileDownloadUrl(next.front).then(setFrontImage);
            }
            if (next.back) {
                getFileDownloadUrl(next.back).then(setBackImage);
            }
            if (next.userWithDoc) {
                getFileDownloadUrl(next.userWithDoc).then(setPersonImage);
            }
            unlock();
        });
    }, []);
    /**
     * callback to handle new file upload / set
     */
    const handleFile = useCallback(
        async (type: 'front' | 'back' | 'person', fn: string, file: any) => {
            const unlock = lock();
            const path = `${ECollections.users}/${
                userData.documentId
            }/temp/${Date.now().toString(36)}.${fn.split('.').pop()}`;
            try {
                await uploadToStorage(path, file);

                const res = await callFunction('checkPersonalDocument', {
                    filePath: path,
                    fileType: type,
                    documentId: doc ? doc.documentId : undefined,
                });

                console.log(res);
                unlock();
                if (res === 'document did not match any expected type') {
                    dialog({
                        title: format(
                            documentScanMessages.documentDidNotMatchExpectedType,
                            { type: format(documentScanMessages[type]) },
                        ),
                        message: format(
                            documentScanMessages.documentDidNotMatchExpectedTypeText,
                            { type: format(documentScanMessages[type]) },
                        ),
                        icon: 'error',
                    });
                } else if (res) {
                    loadDoc(res);
                }
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [doc, loadDoc],
    );
    /**
     * callback to handle submission of document file
     */
    const handleSubmit = useCallback(() => {
        if (!doc) {
            return;
        }
        const unlock = lock();
        callFunction('submitPersonalDocumentForReview', {
            documentId: doc.documentId,
        }).then(() => {
            unlock();
            dialog({
                icon: 'success',
                title: documentScanMessages.submittedForReview,
                message: documentScanMessages.submittedForReviewText,
            }).then(() => secureNavigate(-1, { force: true }));
        });
    }, [doc, callFunction, lock, secureNavigate]);
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking = !!doc && !(await unsavedChangesDialog());
        return locking;
    }, [doc, unsavedChangesDialog]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.headlineCard}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        style={style.horizontalHeavyPadded}
                        message={
                            documentScanMessages.uploadANewIdentityDocument
                        }
                        headline
                    />
                </View>
            </View>
            <View style={[style.card, style.horizontal]}>
                <InfoBox
                    message={documentScanMessages.uploadPictures}
                    type={'info'}
                />
                <CButton
                    onPress={() => {
                        setDoc(undefined);
                        setFrontImage(undefined);
                        setBackImage(undefined);
                        setPersonImage(undefined);
                    }}
                    title={documentScanMessages.restart}
                />
            </View>
            <View style={[style.card]}>
                <CText
                    message={documentScanMessages.front}
                    secondaryHeadline
                    centered
                />
                <View
                    style={[
                        style.horizontalPadded,
                        style.verticalPadded,
                        style.horizontalSpaced,
                    ]}
                >
                    <CImage image={'front'} uri={frontImage} />
                </View>
                {doc?.frontValid ? (
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CIcon icon="check" tint={theme.successColor} />
                    </View>
                ) : doc?.front ? (
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CIcon icon="alert" tint={theme.warningColor} />
                        <FilePicker
                            onFile={(fn, file) => handleFile('front', fn, file)}
                        />
                    </View>
                ) : (
                    <FilePicker
                        onFile={(fn, file) => handleFile('front', fn, file)}
                    />
                )}
            </View>
            <View
                style={[
                    style.horizontalSplit,
                    { width: '100%', overflow: 'hidden' },
                ]}
            >
                <View style={[style.card, { flexGrow: 1, flex: 1 }]}>
                    <CText
                        message={documentScanMessages.back}
                        secondaryHeadline
                        centered
                    />
                    <View
                        style={[
                            style.horizontalPadded,
                            style.verticalPadded,
                            style.horizontalSpaced,
                        ]}
                    >
                        <CImage
                            image={'back'}
                            uri={backImage}
                            fixedHeight={250}
                        />
                    </View>
                    {doc?.backValid ? (
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CIcon icon="check" tint={theme.successColor} />
                        </View>
                    ) : doc?.back ? (
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CIcon icon="alert" tint={theme.warningColor} />
                            <FilePicker
                                onFile={(fn, file) =>
                                    handleFile('back', fn, file)
                                }
                            />
                        </View>
                    ) : (
                        <FilePicker
                            onFile={(fn, file) => handleFile('back', fn, file)}
                        />
                    )}
                </View>
                <View style={[style.card, { flexGrow: 1, flex: 1 }]}>
                    <CText
                        message={documentScanMessages.documentWithUser}
                        secondaryHeadline
                        centered
                    />
                    <View
                        style={[
                            style.horizontalPadded,
                            style.verticalPadded,
                            style.horizontalSpaced,
                        ]}
                    >
                        <CImage
                            image={'frontWithHuman'}
                            uri={personImage}
                            fixedHeight={250}
                        />
                    </View>
                    {personImage ? (
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CIcon icon="check" tint={theme.successColor} />
                        </View>
                    ) : (
                        <FilePicker
                            onFile={(fn, file) =>
                                handleFile('person', fn, file)
                            }
                            disabled={!doc || !doc.documentId}
                        />
                    )}
                </View>
            </View>
            {doc && (
                <View style={style.card}>
                    <DocumentValues doc={doc} />
                </View>
            )}
            <CButton
                title={documentScanMessages.submitForReview}
                onPress={handleSubmit}
                disabled={!doc || !doc.back || !doc.front || !doc.userWithDoc}
            />
        </ScrollProvider>
    );
};
