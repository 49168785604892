import React, { FC, useMemo, useState } from 'react';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { View } from 'react-native';
import { IDayAvailability } from '../../../../models';
import { dayMessages } from '../../../../utilities/messages';
import {
    alwaysFullTimeString,
    keyof,
    timeString,
} from '../../../../utilities/functions';
import { CText } from '../../../../components';

export const DayMap: FC<{ days: IDayAvailability[] }> = ({ days }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const [availableWidth, setAW] = useState(0);
    const baseDays = useMemo(() => {
        return keyof(dayMessages).filter((k) => k.includes('Short'));
    }, []);
    return (
        <View
            style={[style.horizontal, style.verticalHeavyPadded]}
            onLayout={(e) => setAW(e.nativeEvent.layout.width)}
        >
            {baseDays.map((d, i) => {
                const matchedDay = days.find((d) => d.day === i + 1);
                return (
                    <View key={d}>
                        <View
                            style={[
                                !i && {
                                    borderTopLeftRadius: 5,
                                    marginLeft: 5,
                                },
                                i === baseDays.length - 1 && {
                                    borderTopRightRadius: 5,
                                    marginRight: 5,
                                },
                                {
                                    paddingHorizontal: 40,
                                    paddingVertical: 5,
                                    margin: 5,
                                    marginHorizontal: 2.5,
                                    marginBottom: 2.5,
                                    backgroundColor: theme.borderColor,
                                },
                                matchedDay && {
                                    backgroundColor: theme.accentColor,
                                },
                                style.centeredItems,
                                availableWidth < 800 && {
                                    paddingHorizontal: 15,
                                },
                            ]}
                        >
                            <CText
                                style={
                                    matchedDay && {
                                        color: theme.textAccentColor,
                                    }
                                }
                                secondaryHeadline
                                message={dayMessages[d]}
                            />
                        </View>
                        <View
                            style={[
                                !i && {
                                    borderBottomLeftRadius: 5,
                                    marginLeft: 5,
                                },
                                i === baseDays.length - 1 && {
                                    borderBottomRightRadius: 5,
                                    marginRight: 5,
                                },
                                {
                                    paddingVertical: 2.5,
                                    margin: 5,
                                    marginHorizontal: 2.5,
                                    marginTop: 2.5,
                                    backgroundColor: theme.borderColor,
                                },
                                matchedDay && {
                                    backgroundColor: theme.accentColor,
                                },
                                style.centeredItems,
                            ]}
                        >
                            {availableWidth < 800 && matchedDay ? (
                                <>
                                    <CText
                                        style={style.accentText}
                                        message={`${alwaysFullTimeString(
                                            matchedDay.from,
                                            matchedDay.fromMinute,
                                        )}`}
                                    />
                                    <CText
                                        style={style.accentText}
                                        message={`-`}
                                    />
                                    <CText
                                        style={style.accentText}
                                        message={`${timeString(
                                            matchedDay.to || 24,
                                            matchedDay.toMinute,
                                        )}`}
                                    />
                                </>
                            ) : matchedDay ? (
                                <CText
                                    style={style.accentText}
                                    message={`${alwaysFullTimeString(
                                        matchedDay.from,
                                        matchedDay.fromMinute,
                                    )} - ${timeString(
                                        matchedDay.to || 24,
                                        matchedDay.toMinute,
                                    )}`}
                                />
                            ) : (
                                <>
                                    {availableWidth < 800 && (
                                        <CText message={' '} />
                                    )}
                                    <CText message={`-`} />
                                    {availableWidth < 800 && (
                                        <CText message={' '} />
                                    )}
                                </>
                            )}
                        </View>
                    </View>
                );
            })}
        </View>
    );
};
