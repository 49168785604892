import React, { FC } from 'react';
import { View } from 'react-native';
import { useNavigate, useParams } from '../../../utilities/routing';
import {
    CButton,
    CCheckBox,
    CDatePicker,
    CPicker,
    CText,
    DocumentPreview,
    ExpandableCard,
    FilePicker,
    InfoBox,
} from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import {
    actionMessages,
    educationMessages,
    filenameMessages,
    generalMessages,
} from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { JobRow } from '../../Job/List/components/JobRow';
import { applicationMessages } from '../application.messages';
import { ApplicantData } from '../components/ApplicantData';
import { EUserType } from '../../../enums';
import { ResumeView } from '../../../components/ResumeView/ResumeView';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { isSuperUser } from '../../../utilities/auth';
import { Rates } from '../../../components/Wage/Rates';
import { useApplicationCreateState } from './useApplicationCreateState';
import { ApplicationRow } from '../components/ApplicationRow';
import { ContractRow } from '../../Contract/List/components/ContractRow';
import { jobMessages } from '../../Job/job.messages';
import { day } from '../../../utilities/functions';
import { MProfessionalInfo } from '../../../models';
import { WorkHoursDisplay } from '../../Contract/components/WorkHoursDisplay';
/**
 * apply screen
 * @returns
 */
export const ApplicationCreate: FC = () => {
    // global state
    const style = useStyle();
    const navigate = useNavigate();
    const { jobId } = useParams<{ id: string; jobId: string }>();
    const { userData } = useFireBase();
    const format = useFormat();
    const { theme } = useTheme();
    // local state
    const {
        files,
        application,
        job,
        profProfiles,
        setCurrentProfile,
        currentProfile,
        matchingProfile,
        profileDistances,
        createMatchingProfile,
        customDocumentPath,
        setCustomDocumentPath,
        handleNewResumeFile,
        expandResume,
        setExpandResume,
        wage,
        setWage,
        handleApply,
        overlappingApplications,
        overlappingActiveContracts,
        overlappingNegotiations,
        from,
        setFrom,
        to,
        setTo,
        fromNow,
        setFromNow,
    } = useApplicationCreateState();
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.headlineCard]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            navigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText bold headline style={style.horizontalHeavyPadded}>
                        {jobId ? format(applicationMessages.applyForJob) : ''}
                    </CText>
                </View>
            </View>
            {!!job?.documentId && <JobRow job={job} noButtons />}
            {(userData.type === EUserType.agency || isSuperUser(userData)) && (
                <View style={[style.card]}>
                    {!!profProfiles.length && (
                        <CPicker
                            onChange={(value: number) => {
                                setCurrentProfile(profProfiles[value - 1]);
                            }}
                            values={profProfiles
                                .filter((pp) => pp.educations.length)
                                .map((pp, i) => {
                                    const key = pp
                                        .educations[0] as keyof typeof educationMessages;
                                    const distance =
                                        profileDistances.find(
                                            (d) =>
                                                d.profileId === pp.documentId,
                                        )?.distance || 0;
                                    const distanceString = distance
                                        ? format(
                                              applicationMessages.talentDistance,
                                              { x: distance.toFixed(0) },
                                          )
                                        : '';
                                    return {
                                        value: i + 1,
                                        label: `${pp.lastName} ${
                                            educationMessages[key]
                                                ? format(educationMessages[key])
                                                : ''
                                        }${
                                            distance ? ` ${distanceString}` : ''
                                        }`,
                                    };
                                })}
                            value={
                                currentProfile
                                    ? profProfiles.findIndex(
                                          (p) =>
                                              p.documentId ===
                                              currentProfile.documentId,
                                      ) + 1
                                    : 0
                            }
                            placeholder={generalMessages.professionalProfile}
                            title={generalMessages.for}
                            horizontal
                        />
                    )}
                    {!profProfiles.length && (
                        <View style={style.verticalPadded}>
                            <InfoBox
                                type={'warning'}
                                message={applicationMessages.noProfiles}
                            />
                        </View>
                    )}
                    {!matchingProfile && !!profProfiles.length && (
                        <View style={style.verticalPadded}>
                            <InfoBox
                                type={'warning'}
                                message={applicationMessages.noMatchingProfile}
                            />
                        </View>
                    )}
                    <View style={[style.horizontal, style.horizontalWrap]}>
                        {!matchingProfile && (
                            <CButton
                                cy={'quickprofile'}
                                style={style.horizontalPadded}
                                title={format(
                                    applicationMessages.createMatchingProfile,
                                )}
                                onPress={createMatchingProfile}
                                minor
                            />
                        )}
                        <CButton
                            style={style.horizontalPadded}
                            title={format(applicationMessages.createNewProfile)}
                            onPress={() => navigate('/profUser/new')}
                            minor
                        />
                    </View>
                </View>
            )}
            {(userData.type === EUserType.user ||
                !!currentProfile?.documentId) && (
                <>
                    <View style={style.card}>
                        <CText style={style.verticalPadded} bold headline>
                            {format(applicationMessages.yourData)}
                        </CText>
                        <ApplicantData
                            applicantData={new MProfessionalInfo(application)}
                        />
                    </View>
                    {!customDocumentPath && (
                        <ExpandableCard
                            head={
                                <CText
                                    style={style.verticalPadded}
                                    bold
                                    headline
                                >
                                    {format(applicationMessages.yourResume)}
                                </CText>
                            }
                            body={
                                <>
                                    <CText style={style.verticalPadded}>
                                        {format(
                                            applicationMessages.yourResumeWillBeAnonym,
                                        )}
                                    </CText>
                                    <ResumeView
                                        self
                                        profProfileId={
                                            currentProfile?.documentId
                                        }
                                        override={customDocumentPath}
                                    />
                                </>
                            }
                            customExpand={expandResume}
                            customOnClick={(n) => setExpandResume(!n)}
                            preventHover
                        />
                    )}
                    <View style={style.card}>
                        <CText
                            style={style.verticalPadded}
                            bold
                            headline
                            message={applicationMessages.useOwnResume}
                        />
                        {files.length ? (
                            <>
                                <CText
                                    message={
                                        applicationMessages.useOwnResumeDescription
                                    }
                                />
                                <CPicker
                                    placeholder={
                                        applicationMessages.useOwnResumePlaceholder
                                    }
                                    value={customDocumentPath}
                                    values={files.map((v) => ({
                                        value: v.path,
                                        label: `${v.name} ${format(
                                            filenameMessages[v.type],
                                        )}`,
                                    }))}
                                    onChange={setCustomDocumentPath}
                                    allowEmpty
                                />
                            </>
                        ) : (
                            <CText
                                message={applicationMessages.uploadAResume}
                            />
                        )}
                        <FilePicker onFile={handleNewResumeFile} />
                        {!!customDocumentPath && (
                            <DocumentPreview filename={customDocumentPath} />
                        )}
                    </View>
                </>
            )}
            {userData.type === EUserType.agency && (
                // TODO: wage input shall not be displayed for users but autofilled during application
                <View style={[style.card, !wage.wage && style.errorBorder]}>
                    <Rates onChange={(wage) => setWage(wage)} curData={wage} />
                </View>
            )}
            <View style={style.card}>
                <CText
                    secondaryHeadline
                    message={applicationMessages.timeToApplyFor}
                />
                {/* <View style={style.verticalPadded}>
                    <CCheckBox
                        onCheckedChanged={setFromNow}
                        checked={fromNow}
                        title={applicationMessages.fromNowTilUndefined}
                    />
                </View> */}
                {!fromNow && (
                    <View style={style.horizontal}>
                        <CDatePicker
                            cy={'from'}
                            title={format(jobMessages.from)}
                            value={new Date(from)}
                            onChange={(next) => {
                                const nextFrom = next.getTime();
                                setFrom(nextFrom);
                                if (nextFrom > to) {
                                    setTo(nextFrom + day);
                                }
                            }}
                            zIndex={22}
                            minDate={Date.now() + day}
                        />
                        <CDatePicker
                            cy={'to'}
                            title={format(jobMessages.to)}
                            value={new Date(to)}
                            onChange={(next) => setTo(next.getTime())}
                            minDate={from}
                        />
                    </View>
                )}
                {!!job?.days.length && <WorkHoursDisplay days={job.days} />}
            </View>
            {!!overlappingApplications.length && (
                <View style={style.card}>
                    <InfoBox
                        type="warning"
                        message={
                            currentProfile
                                ? applicationMessages.overlappingApplicationsTalent
                                : applicationMessages.overlappingApplications
                        }
                    />
                    {overlappingApplications.map((a) => (
                        <ApplicationRow
                            key={a.documentId}
                            application={a}
                            embedded
                            noResume
                        />
                    ))}
                </View>
            )}
            {!!overlappingNegotiations.length && (
                <View style={style.card}>
                    <InfoBox
                        type="warning"
                        message={
                            currentProfile
                                ? applicationMessages.overlappingNegotiationsTalent
                                : applicationMessages.overlappingNegotiations
                        }
                    />
                    {overlappingNegotiations.map((c) => (
                        <ContractRow key={c.documentId} negotiation={c} />
                    ))}
                </View>
            )}
            {!!overlappingActiveContracts.length && (
                <View style={style.card}>
                    <InfoBox
                        type="warning"
                        message={
                            currentProfile
                                ? applicationMessages.overlappingContractsTalent
                                : applicationMessages.overlappingContracts
                        }
                    />
                    {overlappingActiveContracts.map((c) => (
                        <ContractRow
                            key={c.documentId}
                            negotiation={c}
                            embedded
                            small
                        />
                    ))}
                </View>
            )}
            <View style={style.horizontalSpaced}>
                <View style={style.horizontal}>
                    <CButton
                        cy={'apply'}
                        disabled={
                            userData.type === EUserType.agency &&
                            (!currentProfile?.documentId || !wage.wage)
                        }
                        title={actionMessages.apply}
                        style={{ backgroundColor: theme.successColor }}
                        onPress={handleApply}
                        disableOnClick
                    />
                </View>
            </View>
        </ScrollProvider>
    );
};
