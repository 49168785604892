import React, { FC } from 'react';
import { MUserFile } from '../../../../../../models';
import { View } from 'react-native';
import { EUserFiles } from '../../../../../../enums';
import { CText, FilePicker } from '../../../../../../components';
import { useStyle, useTheme } from '../../../../../../utilities/styles';
import { getFileColor } from '../../../../../../utilities/constants';
import { filenameMessages } from '../../../../../../utilities/messages';
import { profileMessages } from '../../../../../Profile/Profile.messages';

export const DocumentRequest: FC<{
    request?: MUserFile;
    handleFile?: (
        filename: string,
        file: any,
        preFileResult: { nextType: EUserFiles; isPrivateFile?: boolean },
    ) => Promise<void>;
    handleProfilePicture?: (filename: string, file: any) => Promise<void>;
}> = ({ request, handleFile, handleProfilePicture }) => {
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <View
            style={[
                style.horizontalWrap,
                style.centeredItems,
                style.verticalHeavyPadded,
                style.errorBorder,
                style.embeddedCard,
            ]}
        >
            <View style={[style.flex1, style.horizontal]}>
                <CText
                    message={
                        request
                            ? filenameMessages[request.type]
                            : profileMessages.profilePicture
                    }
                    style={[
                        {
                            borderWidth: 1,
                            borderRadius: 3,
                            padding: 2,
                            fontSize: 12,
                        },
                        request
                            ? {
                                  color: getFileColor(request.type),
                                  borderColor: getFileColor(request.type),
                              }
                            : {
                                  color: theme.accentColor,
                                  borderColor: theme.accentColor,
                              },
                        style.horizontalPadded,
                    ]}
                />
            </View>
            <FilePicker
                onFile={async (fn, f) =>
                    handleFile && request
                        ? handleFile(fn, f, {
                              nextType: request.type,
                              isPrivateFile: request.privateRequest,
                          })
                        : handleProfilePicture
                        ? handleProfilePicture(fn, f)
                        : undefined
                }
            />
        </View>
    );
};
