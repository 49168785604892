import React, { FC, useContext, useMemo } from 'react';
import {
    CCheckBox,
    CPicker,
    CText,
    CTextInput,
    Radio,
} from '../../../../components';
import { EEmploymentType } from '../../../../enums';
import { useFormat } from '../../../../utilities/intl';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profProfileMessages } from '../../ProfProfile.messages';
import { employmentTypeMessages } from '../../../../utilities/messages';
import { View } from 'react-native';
import { useStyle } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';

export const ProfessionalEmployment: FC = () => {
    const format = useFormat();
    const style = useStyle();
    const { curData, curValidity, onChange } = useContext(ProfileContext);
    /**
     * memoized travel level
     */
    const w = useMemo(() => {
        if (curData.willingToTravel && !curData.notWillingToTravel) {
            return 3;
        } else if (!curData.willingToTravel && !curData.notWillingToTravel) {
            return 2;
        } else if (!curData.willingToTravel && curData.notWillingToTravel) {
            return 1;
        }
    }, [curData]);

    return (
        <>
            <CPicker
                cy={'employment-type'}
                title={profProfileMessages.employType}
                values={[
                    // ...Object.values(EEmploymentType).map((v) => {
                    ...[
                        EEmploymentType.personnelLeasing,
                        EEmploymentType.direct,
                    ].map((v) => {
                        return {
                            label: format(employmentTypeMessages[v]),
                            value: v,
                        };
                    }),
                ]}
                multiValues={curData.employmentType}
                onChangeMulti={(next) => {
                    onChange({ employmentType: [...next] });
                }}
                keepOpen
                style={!!curValidity.eplmiss && style.errorBorder}
            />
            {!!curValidity.eplmiss && (
                <CText style={style.error} message={curValidity.eplmiss} />
            )}
            <View style={style.verticalPadded}>
                <CText message={profProfileMessages.travelAvailability} />
                <Radio
                    cy={'travel-radio'}
                    values={[
                        {
                            label: format(profProfileMessages.willingToTravel),
                            value: 3,
                        },
                        {
                            label: format(profProfileMessages.neutralToTravel),
                            value: 2,
                        },
                        {
                            label: format(
                                profProfileMessages.notWillingToTravel,
                            ),
                            value: 1,
                        },
                    ]}
                    value={w}
                    onChange={(next) => {
                        if (next === 3) {
                            onChange({
                                willingToTravel: true,
                                notWillingToTravel: false,
                            });
                        } else if (next === 2) {
                            onChange({
                                willingToTravel: false,
                                notWillingToTravel: false,
                            });
                        } else if (next) {
                            onChange({
                                willingToTravel: false,
                                notWillingToTravel: true,
                            });
                        }
                    }}
                />
            </View>
            <View style={style.verticalPadded}>
                {!curData.willingToTravel && !curData.notWillingToTravel && (
                    <CTextInput
                        value={`${curData.maxTravelDistance}`}
                        onChangeText={(t) => {
                            if (!isNaN(+t)) {
                                onChange({ maxTravelDistance: +t });
                            }
                        }}
                        unit={'km'}
                        label={format(profProfileMessages.maxTravelDistance)}
                        placeholder={format(
                            profProfileMessages.maxTravelDistancePlaceholder,
                        )}
                    />
                )}
            </View>
            <View style={[style.verticalPadded, style.horizontal]}>
                <CTextInput
                    label={profileMessages.availableHours}
                    type={'numeric'}
                    onChangeText={(s) =>
                        !isNaN(+s) && onChange({ availableHours: +s })
                    }
                    value={`${curData.availableHours}`}
                    containerStyle={style.rightPadded}
                    style={!!curValidity.avh && style.errorBorder}
                />
                <CTextInput
                    containerStyle={style.horizontalHeavyPadded}
                    label={profileMessages.yearlyAvailableVacation}
                    type={'numeric'}
                    onChangeText={(s) =>
                        !isNaN(+s) &&
                        onChange({ yearlyAvailableVacationDays: +s })
                    }
                    value={`${curData.yearlyAvailableVacationDays}`}
                    style={!!curValidity.avd && style.errorBorder}
                />
            </View>
            {!!curValidity.avh && (
                <CText style={style.error} message={curValidity.avh} />
            )}
            {!!curValidity.avd && (
                <CText style={style.error} message={curValidity.avd} />
            )}
            <View style={style.verticalPadded}>
                <CPicker
                    title={profileMessages.driversLicense}
                    values={[
                        { value: '', label: profileMessages.noDriversLicense },
                        ...[
                            'A',
                            'A1',
                            'B',
                            'BE',
                            'C1',
                            'C1E',
                            'C',
                            'CE',
                            'D1',
                            'D1E',
                            'D',
                            'DE',
                        ].map((v) => {
                            return { value: v, label: v };
                        }),
                    ]}
                    multiValues={curData.driversLicense.split(',')}
                    onChangeMulti={(v) => {
                        onChange({ driversLicense: v.join(',') });
                    }}
                    keepOpen
                />
                <CCheckBox
                    title={profileMessages.hasVehicle}
                    checked={curData.hasVehicle}
                    onCheckedChanged={(c) => onChange({ hasVehicle: c })}
                />
            </View>
        </>
    );
};
