import { defineMessages } from 'react-intl';

const scope = 'con.profProfile.';

export const profProfileMessages = defineMessages({
    unverified: {
        defaultMessage: 'You are not verified.',
        id: scope + 'unverified',
    },
    noProfiles: {
        defaultMessage:
            'No profiles found. Create a new one representing your employees.',
        id: scope + 'noProfiles',
    },
    getVerified: {
        defaultMessage:
            'To create a professional profile you need to book an onboarding to verify yourself with one of our agents.',
        id: scope + 'getVerified',
    },
    estPromotion: {
        defaultMessage: 'Estimated promotion to specialist physician.',
        id: scope + 'estPromotion',
    },
    previewResume: {
        defaultMessage: 'Preview Resume',
        id: scope + 'previewResume',
    },
    previewAnonym: {
        defaultMessage: 'Preview Anonymous Resume',
        id: scope + 'previewAnonym',
    },
    languages: {
        id: scope + 'languages',
        defaultMessage: 'Languages',
    },
    rate: {
        id: scope + 'rate',
        defaultMessage: 'Rate',
    },
    additions: {
        id: scope + 'additions',
        defaultMessage: 'Additions',
    },
    profProfileExplanation: {
        id: scope + 'profProfileExplanation',
        defaultMessage: `Here you will find your created professional user profiles to apply for available jobs.

You have the possibility to create new and more precise profiles by using the plus (+), as well as to adjust & complete the already existing profiles by clicking on the entries in the list below. The filter function helps you find your way more easily in case of a high number of created profiles.`,
    },
    confirmDelete: {
        id: scope + 'confirmDelete',
        defaultMessage: 'Are you sure you want to delete this user?',
    },
    confirmDeleteMessage: {
        id: scope + 'confirmDeleteMessage',
        defaultMessage: 'This action cannot be undone once completed!',
    },
    watchForNewJobs: {
        id: scope + 'watchForNewJobs',
        defaultMessage: 'Watch for new jobs',
    },
    watchForNewJobsForThisProfile: {
        id: scope + 'watchForNewJobsForThisProfile',
        defaultMessage: 'Watch for new jobs',
    },
    watchForNewJobsForThisProfileDescription: {
        id: scope + 'watchForNewJobsForThisProfileDescription',
        defaultMessage:
            'By selecting watch new jobs, you will automatically create an application for new jobs matching this profile (field, profession, education ....)',
    },
    watching: {
        id: scope + 'watching',
        defaultMessage: 'Watching',
    },
    filterBy: {
        id: scope + 'filterBy',
        defaultMessage: 'Filter by',
    },
    showAccessibilityButtons: {
        id: scope + 'showAccessibilityButtons',
        defaultMessage: 'Show import section',
    },
    hideAccessibilityButtons: {
        id: scope + 'hideAccessibilityButtons',
        defaultMessage: 'Hide import section',
    },
    createNewProfile: {
        id: scope + 'createNewProfile',
        defaultMessage: 'Create new talent-profile',
    },
    createInvite: {
        id: scope + 'createInvite',
        defaultMessage: 'Create invite',
    },
    openInvites: {
        id: scope + 'openInvites',
        defaultMessage: 'Open invites',
    },
    rateHint: {
        id: scope + 'rateHint',
        defaultMessage:
            'Specify the rate that you charge clients for this or equivalent employees here. This value, if activated, will be used for the subsequent "Automatically apply" function to automatically apply to suitable positions. Additionally, this value will be pre-filled once you apply for a job with this profile.',
    },
    invite: {
        id: scope + 'invite',
        defaultMessage: 'Please enter the name of the talent to invite.',
    },
    inviteText: {
        id: scope + 'inviteText',
        defaultMessage:
            'If you want to immediately send the invite out via email you have to enter the email address and name of your talent.',
    },
    createInviteAndSendMail: {
        id: scope + 'createInviteAndSendMail',
        defaultMessage: 'Create invite and send mail',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'Email',
    },
    noRate: {
        id: scope + 'noRate',
        defaultMessage: 'No hourly rate',
    },
    viewInvites: {
        id: scope + 'viewInvites',
        defaultMessage: 'View open invites',
    },
    closeInvites: {
        id: scope + 'closeInvites',
        defaultMessage: 'Close invites',
    },
    yourProfProfiles: {
        id: scope + 'yourProfProfiles',
        defaultMessage: 'Your talent profiles',
    },
    csvHint: {
        id: scope + 'csvHint',
        defaultMessage:
            'If you have a existing list of talents, which you can export as csv file, please contact us.',
    },
    mailSend: {
        id: scope + 'mailSend',
        defaultMessage: 'You have sent a mail to {mail}',
    },
    mailSendText: {
        id: scope + 'mailSendText',
        defaultMessage:
            'You have sent a mail to {mail}. As soon as the talent filled his or her details you will receive a notification.',
    },
    saveAndCreateAccount: {
        id: scope + 'saveAndCreateAccount',
        defaultMessage: 'Save and register new account',
    },
    confirmFormulaClose: {
        id: scope + 'confirmFormulaClose',
        defaultMessage: 'Are you sure you want to close this profile link?',
    },
    confirmFormulaCloseText: {
        id: scope + 'confirmFormulaCloseText',
        defaultMessage:
            'The link will become unusable and you will have to open a new one.',
    },
    employType: {
        id: scope + 'employType',
        defaultMessage: 'Preferred Employment Type',
    },
    willingToTravel: {
        id: scope + 'willingToTravel',
        defaultMessage: 'Willing to travel for a job',
    },
    notWillingToTravel: {
        id: scope + 'notWillingToTravel',
        defaultMessage: 'Not willing to travel for a job',
    },
    neutralToTravel: {
        id: scope + 'neutralToTravel',
        defaultMessage: 'Limited travel for a job',
    },
    maxTravelDistance: {
        id: scope + 'maxTravelDistance',
        defaultMessage: 'Your maximum travel distance',
    },
    maxTravelDistancePlaceholder: {
        id: scope + 'maxTravelDistancePlaceholder',
        defaultMessage: 'Your maximum travel distance in km',
    },
    travelAvailability: {
        id: scope + 'travelAvailability',
        defaultMessage: 'Travel availability',
    },
    confirmDeleteFile: {
        id: scope + 'confirmDeleteFile',
        defaultMessage: 'Are you sure you want to delete this file?',
    },
    confirmDeleteFileMessage: {
        id: scope + 'confirmDeleteFileMessage',
        defaultMessage:
            'This action will remove it from this workplace and no user will be able to access this original reference in the future',
    },
    openAgency: {
        id: scope + 'openAgency',
        defaultMessage: 'Open agency',
    },
    internalRating: {
        id: scope + 'internalRating',
        defaultMessage: 'Internal classification',
    },
    hasContract: {
        id: scope + 'hasContract',
        defaultMessage:
            'Has signed permanent employment contract with this agency',
    },
    hasContractLabel: {
        id: scope + 'hasContractLabel',
        defaultMessage: 'Contract',
    },
    selectInternalRating: {
        id: scope + 'selectInternalRating',
        defaultMessage: 'Select internal rating',
    },
    publicFiles: {
        id: scope + 'publicFiles',
        defaultMessage: 'Public files',
    },
    privateFiles: {
        id: scope + 'privateFiles',
        defaultMessage: 'Private files',
    },
    deleteTalent: {
        id: scope + 'deleteTalent',
        defaultMessage: 'Delete talent',
    },
    availabilities: {
        id: scope + 'availabilities',
        defaultMessage: 'Talent availability settings',
    },
    createdTalent: {
        id: scope + 'createdTalent',
        defaultMessage: 'You have successfully create a new talentprofile',
    },
    createdTalentText: {
        id: scope + 'createdTalentText',
        defaultMessage:
            'Check the job listings and apply for a position or set up availabilities to be contacted proactive by employers looking for employees that are available.',
    },
    createAvailabilities: {
        id: scope + 'createAvailabilities',
        defaultMessage: 'Set up availabilities',
    },
    toJobs: {
        id: scope + 'toJobs',
        defaultMessage: 'Visit job lisitings',
    },
    explicitAvailabilitiesDescription: {
        id: scope + 'explicitAvailabilitiesDescription',
        defaultMessage:
            'You can set availabilities in the calendar to indicate time periods where this talent is available and is actively looking for work. This information will be used to match jobs with this talent and will allow employers to request this talent proactively',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
    editNote: {
        id: scope + 'editNote',
        defaultMessage: 'Edit note',
    },
    finishEditing: {
        id: scope + 'finishEditing',
        defaultMessage: 'Finish editing',
    },
    terminate: {
        id: scope + 'terminate',
        defaultMessage: 'Terminate',
    },
    terminateNow: {
        id: scope + 'terminateNow',
        defaultMessage: 'Terminate now',
    },
    terminateOnDate: {
        id: scope + 'terminateOnDate',
        defaultMessage: 'Terminate on date',
    },
    confirmTerminate: {
        id: scope + 'confirmTerminate',
        defaultMessage: 'Are you sure you want to terminate this talent?',
    },
    confirmTerminateMessage: {
        id: scope + 'confirmTerminateMessage',
        defaultMessage:
            'Terminating a talent will remove all future availabilities, cut current availabilities to the selected termination date and unset values like active contract. The talent will stay in your list. If you want to remove it from your list delete it instead.\nYou can choose to terminate at a date in the future.',
    },
    terminated: {
        id: scope + 'terminated',
        defaultMessage: 'Contract terminated',
    },
    sickDays: {
        id: scope + 'sickDays',
        defaultMessage: 'Sick days',
    },
    vacationDays: {
        id: scope + 'vacationDays',
        defaultMessage: 'Vacation days',
    },
    terminationDate: {
        id: scope + 'terminationDate',
        defaultMessage: 'Termination date',
    },
    terminationPlanned: {
        id: scope + 'terminationPlanned',
        defaultMessage: 'Termination on {date}',
    },
    timesheetSection: {
        id: scope + 'timesheetSection',
        defaultMessage: 'Timesheet summary',
    },
    noTimesheetsForSelectedPeriod: {
        id: scope + 'noTimesheetsForSelectedPeriod',
        defaultMessage: 'No timesheets found for the selected period.',
    },
    revertTerminate: {
        id: scope + 'revertTerminate',
        defaultMessage: 'Revert termination',
    },
    revertTerminateTitle: {
        id: scope + 'revertTerminateTitle',
        defaultMessage:
            'Do you want to remove the terminated status from this profile?',
    },
    revertTerminateMessage: {
        id: scope + 'revertTerminateMessage',
        defaultMessage:
            'This will only remove the status. It will not restore any lost data.',
    },
    professional: {
        id: scope + 'professional',
        defaultMessage: 'Skills',
    },
    documents: {
        id: scope + 'documents',
        defaultMessage: 'Documents',
    },
    availabilitiesMode: {
        id: scope + 'availabilitiesMode',
        defaultMessage: 'Availabilities',
    },
    employment: {
        id: scope + 'employment',
        defaultMessage: 'Employment',
    },
    reSendForm: {
        id: scope + 'reSendForm',
        defaultMessage: 'Send form to talent',
    },
    changeEmploymentStatus: {
        id: scope + 'changeEmploymentStatus',
        defaultMessage: 'Change status',
    },
    changeEmploymentStatusTitle: {
        id: scope + 'changeEmploymentStatusTitle',
        defaultMessage: 'Change this talents employment status',
    },
    isTerminated: {
        id: scope + 'isTerminated',
        defaultMessage: 'The talent is currently marked as terminated',
    },
    isToBeTerminated: {
        id: scope + 'isToBeTerminated',
        defaultMessage:
            'The talent is currently marked to be terminated. The termination will conclude {date}',
    },
    isEmployed: {
        id: scope + 'isEmployed',
        defaultMessage:
            'The talent is currently marked to have an active contract.',
    },
    selectANewStatus: {
        id: scope + 'selectANewStatus',
        defaultMessage: 'Select the new status',
    },
    setHasContract: {
        id: scope + 'setHasContract',
        defaultMessage: 'Talent has a active contract',
    },
    otherStates: {
        id: scope + 'otherStates',
        defaultMessage: 'Other',
    },
    selectEState: {
        id: scope + 'selectEState',
        defaultMessage: 'Select employment state',
    },
    willTerminate: {
        id: scope + 'willTerminate',
        defaultMessage: 'Termination imminent',
    },
    whatsappInviteText: {
        id: scope + 'whatsappInviteText',
        defaultMessage:
            'Please follow this url and fill in your profile. {url}',
    },
    whatsappFreshInviteTextLN: {
        id: scope + 'whatsappFreshInviteTextLN',
        defaultMessage:
            'Hello {lastName}! Please follow this url and fill in your profile. {url}',
    },
    whatsappFreshInviteText: {
        id: scope + 'whatsappFreshInviteText',
        defaultMessage:
            'Hello {firstName} {lastName}! Please follow this url and fill in your profile. {url}',
    },
    createInviteAndSendWhatsapp: {
        id: scope + 'createInviteAndSendWhatsapp',
        defaultMessage: 'Create invite and send with Whatsapp',
    },
    phone: {
        id: scope + 'phone',
        defaultMessage: 'Phone',
    },
    noAvailableHours: {
        id: scope + 'noAvailableHours',
        defaultMessage: 'You have to enter available hours',
    },
    noVacationDays: {
        id: scope + 'noVacationDays',
        defaultMessage: 'You have to enter vacation days',
    },
    noEmployType: {
        id: scope + 'noEmployType',
        defaultMessage: 'You have to select at least one employment type',
    },
    noRateSet: {
        id: scope + 'noRateSet',
        defaultMessage: 'You have to set a wage rate',
    },
    createAYearWeekAvailability: {
        id: scope + 'createAYearWeekAvailability',
        defaultMessage: 'Create a year long weekday availability',
    },
    createAYearWeekAvailabilityTitle: {
        id: scope + 'createAYearWeekAvailabilityTitle',
        defaultMessage:
            'Do you want to add a availability for the entire upcoming year?',
    },
    createAYearWeekAvailabilityMessage: {
        id: scope + 'createAYearWeekAvailabilityMessage',
        defaultMessage:
            'This availability will list this talent as available for every weekday (Monday - Friday) until exactly one year in the future. You will be able to modify this availability as you whish after creation.',
    },
    noPicture: {
        id: scope + 'noPicture',
        defaultMessage: 'You should set a profile picture',
    },
    noPictureAlert: {
        id: scope + 'noPictureAlert',
        defaultMessage: 'This talent has no profile picture',
    },
    noPictureAlertMessage: {
        id: scope + 'noPictureAlertMessage',
        defaultMessage:
            'It is highly recommended to set a profile picture for your talents. They provide personality and increase the likelyhood of them bein chosen for a contract.',
    },
    noAvails: {
        id: scope + 'noAvails',
        defaultMessage:
            'You should set at least one availability for this talent',
    },
    noAvailsAlert: {
        id: scope + 'noAvailsAlert',
        defaultMessage: 'This talent has no availabilities',
    },
    noAvailsAlertMessage: {
        id: scope + 'noAvailsAlertMessage',
        defaultMessage:
            'It is highly recommended to define availabilities for your talents. If they are defined, employers will be able to proactively contact you for the talents services.',
    },
    uploadOtherFile: {
        id: scope + 'uploadOtherFile',
        defaultMessage: 'Upload another document',
    },
    fileCanBeShared: {
        id: scope + 'fileCanBeShared',
        defaultMessage:
            'This file can be shared during active contracts with third parties. (e.g. employers)',
    },
    uploadedFiles: {
        id: scope + 'uploadedFiles',
        defaultMessage: 'Uploaded files',
    },
    onlyPDFUploadAllowed: {
        id: scope + 'onlyPDFUploadAllowed',
        defaultMessage:
            'Only PDF files are allowed for upload during formular usage',
    },
    requestedDocuments: {
        id: scope + 'requestedDocuments',
        defaultMessage: 'Requested documents',
    },
    rating: {
        id: scope + 'rating',
        defaultMessage: 'Rating',
    },
});
