import React, { FC, useCallback, useContext, useMemo } from 'react';
import { View } from 'react-native';
import { CButton, CText, InfoBox } from '../../../../components';
import { ECollections } from '../../../../enums';
import { MAgency, MAvailability } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { AvailabilityRow } from '../../../Profile/components/AvailabilityRow';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profileMessages } from '../../../Profile/Profile.messages';
import { useSecureNavigate } from '../../../../utilities/routing';
import { profProfileMessages } from '../../ProfProfile.messages';
import { actionMessages, dayMessages } from '../../../../utilities/messages';
import { getWeekNumber, year } from '../../../../utilities/functions';
import { useDialog } from '../../../../utilities/dialog';
import { useCollectionIndex } from '../../../../utilities/firebase/store/useIndex';
/**
 * Profile Section do display availabilities and
 * edit / set available from to lock account out of notifications until then
 * @param param0 profile section props
 * @returns profile section
 */
export const ProfessionalAvailabilities: FC<{ agency?: MAgency }> = ({
    agency,
}) => {
    const format = useFormat();
    const dialog = useDialog();
    const style = useStyle();
    const { getDataIndex, userData, post } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const { curData, prevData, curTalentData } = useContext(ProfileContext);
    const availabilityParams = useMemo(() => {
        if (!agency) return { suspend: true };
        return {
            filter: [
                { field: 'profileId', value: prevData.documentId },
                { field: 'agencyId', value: agency.documentId },
            ],
        };
    }, [prevData, agency]);
    const availabilityData = useCollectionIndex(
        ECollections.availabilities,
        availabilityParams,
    );
    const availabilities = useMemo(
        () => availabilityData.map((a) => new MAvailability(a)),
        [availabilityData],
    );
    /**
     * create a year availability
     */
    const createAYearWeekAvailability = useCallback(async () => {
        if (
            !(await dialog({
                title: profProfileMessages.createAYearWeekAvailabilityTitle,
                message: profProfileMessages.createAYearWeekAvailabilityMessage,
                buttons: [{ text: actionMessages.create }],
                cancelButton: { text: actionMessages.cancel },
            }))
        )
            return;
        const next: Partial<MAvailability> = {
            uid: userData.documentId,
            profileId: curTalentData?.documentId,
            agencyId: curTalentData?.agencyId,
            field: curTalentData?.field,
            educations: curTalentData?.educations,
            profession: curTalentData?.profession,
            region: curTalentData?.region,
            zipCode: curTalentData?.address.zipCode,
            days: [
                dayMessages.monday,
                dayMessages.tuesday,
                dayMessages.wednesday,
                dayMessages.thursday,
                dayMessages.friday,
            ].map((_, i) => ({
                day: i + 1,
                inheriting: true,
                from: 0,
                fromMinute: 0,
                to: 0,
                toMinute: 0,
            })),
            year: new Date().getFullYear(),
            start: getWeekNumber(new Date()),
            exactEndTime: Date.now() + year,
            exactStartTime: Date.now(),
        };
        await post(ECollections.availabilities, new MAvailability(next));
    }, [curTalentData]);
    /**
     * render
     */
    return (
        <View style={{ zIndex: 1 }}>
            <CText
                secondaryHeadline
                message={profProfileMessages.availabilities}
            />
            {/* <CText style={style.verticalPadded}>
                {format(profileMessages.availableFromDescription)}
            </CText>
            <CDatePicker
                title={format(profileMessages.availableFrom)}
                value={
                    curData.availableFrom
                        ? new Date(curData.availableFrom)
                        : undefined
                }
                onChange={(nextDate) => {
                    onChange({ availableFrom: nextDate.getTime() });
                }}
            /> */}
            {/* <CText secondaryHeadline>
                {format(profileMessages.explicitAvailabilities)}
            </CText> */}
            <View style={style.verticalPadded}>
                <InfoBox
                    message={
                        profProfileMessages.explicitAvailabilitiesDescription
                    }
                />
            </View>
            {!availabilities.length && (
                <CButton
                    onPress={createAYearWeekAvailability}
                    title={profProfileMessages.createAYearWeekAvailability}
                    icon="plus"
                />
            )}
            {!!availabilities.length && (
                <View style={[style.horizontalSplit, style.verticalPadded]}>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.from)}</CText>
                    </View>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.to)}</CText>
                    </View>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.note)}</CText>
                    </View>
                    <View style={[{ flex: 1 }, style.horizontal]}>
                        <View style={{ flex: 1 }}>
                            <CText bold>{format(profileMessages.days)}</CText>
                        </View>
                        <View style={{ flex: 1 }}>
                            <CText bold>{format(profileMessages.times)}</CText>
                        </View>
                    </View>
                    <View style={{ width: 50 }} />
                </View>
            )}
            {availabilities.map((a, i) => (
                <View key={a.documentId}>
                    <AvailabilityRow availability={a} />
                    {i !== availabilities.length - 1 && (
                        <View style={style.paddedThinSeparator} />
                    )}
                </View>
            ))}
            {!availabilities.length && (
                <View style={[style.verticalPadded, style.horizontalSpaced]}>
                    <CText>
                        {format(profileMessages.noExplicitAvailabilities)}
                    </CText>
                </View>
            )}
            <View style={[style.horizontalSpaced]}>
                <CButton
                    onPress={() => {
                        secureNavigate(
                            '/calendar?profileId=' + curData.documentId,
                        );
                    }}
                    title={profileMessages.openCalendar}
                    icon={'calendar'}
                    small
                />
            </View>
        </View>
    );
};
