import React, { FC, useCallback, useContext, useEffect } from 'react';
import { CText, CPicker } from '../../../../components';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { View } from 'react-native';
import { useFormat } from '../../../../utilities/intl';
import { CButton } from '../../../../components';
import { profileMessages } from '../../../Profile/Profile.messages';
import { languageMessages } from '../../../../utilities/messages/language.messages';
import { languageLevelMessages } from '../../../../utilities/messages/languageLevel.messages';
import { profProfileMessages } from '../../ProfProfile.messages';
import { ProfileContext } from '../../../Profile/Profile.context';
import { ERegion } from '../../../../enums';
import { keyof } from '../../../../utilities/functions';

export const ProfessionalLanguage: FC = () => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const { curData, curValidity, onChange, onValidityChange } =
        useContext(ProfileContext);
    /**
     * legacy validityFunctions
     */
    const langsInvalidity = useCallback(
        (langs: { lang: string; level: string }[]) => {
            if (curData.region === ERegion.de) {
                const ger = langs.find((v) => v.lang === 'german');
                if (!ger) {
                    return format(profileMessages.germanMissing);
                } else if (!ger.level) {
                    return format(profileMessages.germanLevelMissing);
                } else if (langs.find((v) => !v.level || !v.lang)) {
                    return format(profileMessages.langValueIncomplete);
                }
            } else if (curData.region === ERegion.za) {
                const ger = langs.find((v) => v.lang === 'english');
                if (!ger) {
                    return format(profileMessages.englishMissing);
                } else if (!ger.level) {
                    return format(profileMessages.englishLevelMissing);
                } else if (langs.find((v) => !v.level || !v.lang)) {
                    return format(profileMessages.langValueIncomplete);
                }
            }
        },
        [curData],
    );
    /**
     * effect to populate empty array with german
     */
    useEffect(() => {
        if (!curData.languages.length) {
            if (curData.region === ERegion.de) {
                onChange({ languages: [{ lang: 'german', level: '' }] });
            } else if (curData.region === ERegion.za) {
                onChange({ languages: [{ lang: 'english', level: '' }] });
            }
        }
    }, [curData]);
    /**
     * legacy validity effect to be removed once profile is fixed to manage its own validity
     */
    useEffect(() => {
        const validity = langsInvalidity(curData.languages) || '';
        onValidityChange && onValidityChange(validity, 'lang');
    }, [curData]);
    return (
        <>
            <View style={style.verticalPadded}>
                <CText>{format(profProfileMessages.languages)}</CText>
                {curData.languages.map((item, i) => (
                    <View style={[style.horizontalSplit]} key={i}>
                        <View style={[style.horizontalWrap, { flex: 1 }]}>
                            <View style={[{ flex: 1 }]}>
                                <CPicker
                                    value={item.lang}
                                    values={[
                                        {
                                            label: `-${format(
                                                profileMessages.languagePlaceholder,
                                            )}-`,
                                            value: undefined,
                                            hidden: true,
                                        },
                                        ...Object.keys(languageMessages).map(
                                            (v) => {
                                                return {
                                                    label: format(
                                                        // @ts-ignore
                                                        languageMessages[v],
                                                    ),
                                                    value: v,
                                                };
                                            },
                                        ),
                                    ].filter(
                                        (v) =>
                                            !curData.languages.find(
                                                (uv) =>
                                                    v.value === uv.lang &&
                                                    item.lang !== uv.lang,
                                            ),
                                    )}
                                    onChange={(lang) => {
                                        const languages = [
                                            ...curData.languages,
                                        ];
                                        languages[i].lang = lang;
                                        onChange({ languages });
                                    }}
                                />
                            </View>
                            <View style={[{ flex: 1 }]}>
                                <CPicker
                                    value={item.level}
                                    values={[
                                        {
                                            label: `-${format(
                                                profileMessages.languageLevelPlaceholder,
                                            )}-`,
                                            value: undefined,
                                            hidden: true,
                                        },
                                        ...keyof(languageLevelMessages).map(
                                            (v) => {
                                                return {
                                                    label: format(
                                                        languageLevelMessages[
                                                            v
                                                        ],
                                                    ),
                                                    value: v,
                                                };
                                            },
                                        ),
                                    ]}
                                    onChange={(level) => {
                                        const languages = [
                                            ...curData.languages,
                                        ];
                                        languages[i].level = level;
                                        onChange({ languages });
                                    }}
                                />
                            </View>
                        </View>
                        <View style={style.centeredContent}>
                            <CButton
                                icon="close"
                                minor
                                iconColor={theme.errorColor}
                                onPress={() => {
                                    const languages = [...curData.languages];
                                    languages.splice(i, 1);
                                    onChange({ languages });
                                }}
                            />
                        </View>
                    </View>
                ))}
                <View style={[style.verticalPadded, style.horizontalSpaced]}>
                    <CButton
                        icon={'plus'}
                        minor
                        title={format(profileMessages.addLanguage)}
                        onPress={() => {
                            const languages = [...curData.languages];
                            languages.push({ lang: '', level: '' });
                            onChange({ languages });
                        }}
                    />
                </View>
                {!!curValidity.lang && (
                    <CText style={style.error}>{curValidity.lang}</CText>
                )}
            </View>
        </>
    );
};
