import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CCard, CImage, CText, Spinner } from '../../../../components';
import { MContract, MFile, MTimesheet } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { TimesheetRow } from './TimesheetRow';
import { timesheetMessages } from '../../../Timesheet/timesheet.messages';
import { useStyle } from '../../../../utilities/styles';
import { ScrollProvider } from '../../../../utilities/contexts';

export const TimesheetList: FC<{ contract: MContract; maxHeight?: number }> = ({
    contract,
    maxHeight,
}) => {
    const style = useStyle();
    const { getDataIndex, getDataById } = useFireBase();
    const [sheets, setSheets] = useState<{ sheet: MTimesheet; file?: MFile }[]>(
        [],
    );
    const [didLoad, setDidLoad] = useState(false);
    /**
     * memoized split sheets in signed & unsigned
     */
    const { unsigned, signed } = useMemo(() => {
        const unsigned = [];
        const signed = [];
        for (const s of sheets) {
            const file = s.file;
            if (
                !!file &&
                file?.signaturePositions.length &&
                !file?.signaturePositions.find((sP) => !sP.signed)
            ) {
                signed.push(s);
            } else {
                unsigned.push(s);
            }
        }
        return { unsigned, signed };
    }, [sheets]);
    /**
     * effect to load all data related to contract after contract did load
     */
    useEffect(() => {
        if (!contract) return;
        const timesheetCollection = `${ECollections.contracts}/${contract.documentId}/${ECollections.timesheets}`;
        getDataIndex(timesheetCollection).then(async (ts) => {
            const results = (ts as any[]).map((t) => new MTimesheet(t));
            const rwf: { sheet: MTimesheet; file?: MFile }[] = [];
            for (const s of results) {
                if (s.fileId) {
                    rwf.push({
                        sheet: s,
                        file: new MFile(
                            await getDataById(
                                `${ECollections.contracts}/${s.contractId}/${ECollections.files}`,
                                s.fileId,
                            ),
                        ),
                    });
                } else {
                    rwf.push({ sheet: s });
                }
            }

            setSheets(
                rwf.sort((a, b) => b.sheet.createdOn - a.sheet.createdOn),
            );
            setDidLoad(true);
        });
    }, [contract]);
    /**
     * return spinner pre first load
     */
    if (!didLoad) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={{ maxHeight: maxHeight || undefined }}>
            {!!unsigned.length && (
                <CCard>
                    <CText
                        secondaryHeadline
                        message={timesheetMessages.openTimesheets}
                    />
                </CCard>
            )}
            {unsigned.map(({ sheet, file }) => (
                <TimesheetRow
                    key={sheet.documentId}
                    sheet={sheet}
                    preLoadFile={file}
                />
            ))}
            {!!signed.length && (
                <CCard>
                    <CText
                        secondaryHeadline
                        message={timesheetMessages.signedTimesheets}
                    />
                </CCard>
            )}
            {signed.map(({ sheet, file }) => (
                <TimesheetRow
                    key={sheet.documentId}
                    sheet={sheet}
                    preLoadFile={file}
                />
            ))}
            {!sheets.length && (
                <View style={style.centeredItems}>
                    <CImage image="contracts" />
                    <CText message={timesheetMessages.noTimesheets} />
                </View>
            )}
        </ScrollProvider>
    );
};
