import React, { FC, useContext } from 'react';
import { View } from 'react-native';
import {
    AddressInput,
    CText,
    CTextInput,
    PhoneNumberInput,
} from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profileMessages } from '../../../Profile/Profile.messages';

export const TalentOnboardingContact: FC = () => {
    // global state
    const format = useFormat();
    // parent state
    const { curData, onChange } = useContext(ProfileContext);
    /**
     * render
     */
    return (
        <View>
            <AddressInput
                cy={'user-address'}
                title={format(profileMessages.address)}
                address={curData.address}
                setAddress={(address) => onChange({ address })}
            />
            <CTextInput
                value={curData.mail}
                onChangeText={(mail) => onChange({ mail })}
                label={format(profileMessages.mail)}
                autoExtend
            />
            <View>
                <CText message={profileMessages.phone} />
                <PhoneNumberInput
                    value={curData.phone}
                    onChange={(phone) => onChange({ phone })}
                />
            </View>
        </View>
    );
};
