import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CImage, CText, Spinner } from '../../../components';
import { MContract } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import { contractMessages } from '../contract.messages';
import {
    EApplicationType,
    ECollections,
    EContractStatus,
    EUserType,
} from '../../../enums';
import { isSuperUser } from '../../../utilities/auth';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { useNavigate } from '../../../utilities/routing';
import { ContractRow } from './components/ContractRow';
import { useDialog } from '../../../utilities/dialog';
import { day, testMail } from '../../../utilities/functions';
import { isLocalhost } from '../../../utilities/constants';
import { useLock } from '../../../utilities/hooks';
import { jobMessages } from '../../Job/job.messages';
import { DCCMode } from '../../Dashboard/components/Contracts';
/**
 * lists contracts
 * @returns
 */
export const ContractList: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const dialog = useDialog();
    const navigate = useNavigate();
    const { lock } = useLock();
    const {
        getDataIndex,
        userData,
        userWorkplaces,
        userAgencies,
        callFunction,
    } = useFireBase();
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [loadingContracts, setLoadingContracts] = useState(true);
    const [order, setOrder] = useState<'createdOn' | 'from'>('createdOn');
    const [mode, setMode] = useState<DCCMode>(DCCMode.all);
    /**
     * memoized contract for active mode
     */
    const activeContracts = useMemo(
        () =>
            contracts.filter((c) => c.from <= Date.now() && c.to > Date.now()),
        [contracts],
    );
    /**
     * memoized contract for completed mode
     */
    const completedContracts = useMemo(
        () => contracts.filter((c) => c.to < Date.now()),
        [contracts],
    );
    /**
     * memoized contract for upcoming mode
     */
    const upcomingContracts = useMemo(
        () => contracts.filter((c) => c.from >= Date.now() + day * 7),
        [contracts],
    );
    /**
     * memoized contract for upcoming mode
     */
    const soonUpcomingContracts = useMemo(
        () =>
            contracts.filter(
                (c) => c.from > Date.now() && c.from < Date.now() + day * 7,
            ),
        [contracts],
    );
    /**
     * memoized contracts filtered for current mode
     */
    const filteredContracts = useMemo(() => {
        switch (mode) {
            case DCCMode.active:
                return activeContracts;
            case DCCMode.completed:
                return completedContracts;
            case DCCMode.upcoming:
                return upcomingContracts;
            case DCCMode.upcomingSoon:
                return soonUpcomingContracts;
            default:
                return contracts;
        }
    }, [
        activeContracts,
        completedContracts,
        upcomingContracts,
        contracts,
        mode,
    ]);
    /**
     * filtered contracts sorted by from (start date)
     */
    const sortedContracts = useMemo(
        () =>
            filteredContracts.sort((a, b) =>
                order === 'createdOn'
                    ? b.createdOn - a.createdOn
                    : order === 'from'
                    ? b.from - a.from
                    : b.status.localeCompare(a.status),
            ),
        [filteredContracts],
    );
    /**
     * callback to create a contract with an invite for unmanaged
     */
    const createBasicContract = useCallback(async () => {
        let mail = '';
        let title = '';
        if (
            await dialog({
                icon: 'info',
                title: contractMessages.createNewContractTitle,
                message: contractMessages.createNewContractText,
                textInputs: [
                    {
                        id: 'mail',
                        title: contractMessages.mailOfContractPartner,
                    },
                    { id: 'title', title: contractMessages.title },
                ],
                buttons: [
                    {
                        text: actionMessages.create,
                        onPress: (input) => {
                            mail = input?.find(
                                ({ id }) => id === 'mail',
                            )?.value;
                            title = input?.find(
                                ({ id }) => id === 'title',
                            )?.value;
                        },
                        disabled: (input) => {
                            const v = input?.find(
                                ({ id }) => id === 'mail',
                            )?.value;
                            const t = input?.find(
                                ({ id }) => id === 'title',
                            )?.value;

                            return !v || !t || !testMail(v);
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            })
        ) {
            const resourceId =
                userAgencies[0]?.documentId || userWorkplaces[0]?.documentId;
            const unlock = lock();
            await callFunction('createBasicContract', {
                mail,
                title,
                resourceId,
            });
            unlock();
            dialog({
                icon: 'success',
                title: format(contractMessages.mailSend, { mail }),
                message: format(contractMessages.mailSendText, { mail }),
            });
        }
    }, [userWorkplaces, userAgencies]);
    /**
     * effect to load contracts
     */
    useEffect(() => {
        const basefilter = [] as IFilter[];
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                basefilter.push({
                    field: 'workplaceUsers',
                    value: userData.documentId,
                    operator: 'array-contains',
                });
            } else if (userData.type === EUserType.agency) {
                if (!userAgencies.length) {
                    return;
                }
                basefilter.push({
                    field: 'agencyId',
                    value: userAgencies.map((w) => w.documentId),
                    operator: 'in',
                });
            } else if (
                userData.type === EUserType.talent &&
                userData.talentId
            ) {
                basefilter.push({
                    field: 'profileId',
                    value: userData.talentId,
                });
            } else {
                basefilter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                });
            }
        }
        const promises = [];
        promises.push(
            getDataIndex(ECollections.contracts, {
                filter: [
                    ...basefilter,
                    {
                        field: 'status',
                        operator: 'in',
                        value: [EContractStatus.signed],
                    },
                ],
            }),
        );
        promises.push(
            getDataIndex(ECollections.contracts, {
                filter: [
                    { value: EApplicationType.default, field: 'type' },
                    {
                        field: 'participants',
                        operator: 'array-contains',
                        value: userData.documentId,
                    },
                    {
                        field: 'status',
                        operator: 'in',
                        value: [EContractStatus.signed],
                    },
                ],
            }),
        );
        Promise.all(promises).then((res) => {
            const nextContracts = (res as MContract[][]).reduce(
                (acc, n) => [...acc, ...n.map((v) => new MContract(v))],
                [],
            );
            setContracts(nextContracts);
            setLoadingContracts(false);
        });
    }, [userWorkplaces, userAgencies, userData]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View
                style={[style.card, style.horizontalSplit, style.centeredItems]}
            >
                <View>
                    <CText headline>{format(generalMessages.contracts)}</CText>
                    <View style={style.horizontal}>
                        {![EUserType.basic, EUserType.talent].includes(
                            userData.type,
                        ) && (
                            <CButton
                                title={generalMessages.negotiations}
                                onPress={() => {
                                    navigate('/negotiations');
                                }}
                                small
                                minor
                                style={style.smallMargin}
                            />
                        )}
                        <CButton
                            title={generalMessages.contracts}
                            onPress={() => {
                                // nothingToDo
                            }}
                            small
                            style={style.smallMargin}
                        />
                        <View style={style.verticalPaddedThinSeparator} />
                        <CButton
                            title={contractMessages.history}
                            onPress={() => {
                                navigate('/contracthistory');
                            }}
                            small
                            minor
                            style={style.smallMargin}
                        />
                    </View>
                </View>
                {isLocalhost() && (
                    <CButton
                        onPress={createBasicContract}
                        title={contractMessages.createNewContract}
                        icon="plus"
                    />
                )}
            </View>
            <View style={[style.card, style.horizontal]}>
                <CButton
                    title={
                        format(generalMessages.all) +
                        (contracts.length ? ` (${contracts.length})` : '')
                    }
                    onPress={() => {
                        setMode(DCCMode.all);
                    }}
                    small
                    minor={mode !== DCCMode.all}
                    style={style.smallMargin}
                />
                <CButton
                    title={`${format(
                        userData.type === EUserType.agency
                            ? contractMessages.deployed
                            : contractMessages.active,
                    )} (${activeContracts.length})`}
                    onPress={() => {
                        setMode(DCCMode.active);
                    }}
                    small
                    minor={mode !== DCCMode.active}
                    style={style.smallMargin}
                    nativeId="active-contracts-button"
                />
                <CButton
                    title={`${format(contractMessages.aboutToStart)} (${
                        soonUpcomingContracts.length
                    })`}
                    onPress={() => {
                        setMode(DCCMode.upcomingSoon);
                    }}
                    small
                    minor={mode !== DCCMode.upcomingSoon}
                    style={style.smallMargin}
                    nativeId="soonUpcoming-contracts-button"
                />
                <CButton
                    title={`${format(contractMessages.upcoming)} (${
                        upcomingContracts.length
                    })`}
                    onPress={() => {
                        setMode(DCCMode.upcoming);
                    }}
                    small
                    minor={mode !== DCCMode.upcoming}
                    style={style.smallMargin}
                    nativeId="upcoming-contracts-button"
                />
                <CButton
                    title={`${format(contractMessages.toFinish)} (${
                        completedContracts.length
                    })`}
                    onPress={() => setMode(DCCMode.completed)}
                    small
                    minor={mode !== DCCMode.completed}
                    style={style.smallMargin}
                    nativeId="toFinish-contracts-button"
                />
            </View>
            <View style={[style.card, style.horizontal, style.centeredItems]}>
                <CText message={generalMessages.orderBy} />
                <CButton
                    title={generalMessages.createdOn}
                    onPress={() => {
                        setOrder('createdOn');
                    }}
                    minor={order !== 'createdOn'}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={jobMessages.fromTo}
                    onPress={() => {
                        setOrder('from');
                    }}
                    minor={order !== 'from'}
                    small
                    style={style.smallMargin}
                />
            </View>
            {!loadingContracts ? (
                <>
                    {sortedContracts.map((contract) => (
                        <ContractRow
                            key={contract.documentId}
                            negotiation={contract}
                            onPress={() =>
                                navigate('/contract/' + contract.documentId)
                            }
                        />
                    ))}
                    {!contracts.length && (
                        <View style={style.card}>
                            <CImage image="contracts" />
                            <CText centered headline>
                                {format(contractMessages.noContracts)}
                            </CText>
                        </View>
                    )}
                </>
            ) : (
                <Spinner />
            )}
        </ScrollProvider>
    );
};
