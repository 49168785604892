import React, { FC, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CIcon, CText, TouchableView } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { useStyle, useTheme } from '../../../utilities/styles';
import { jobMessages } from '../../Job/job.messages';
import { MContract, MJob } from '../../../models';
import { contractMessages } from '../contract.messages';
import { RatesTable } from '../../../components/Wage/RatesTable';
import { ContractExtensionButton } from '../View/components/ContractExtensionButton';
import { ContractCancelButton } from '../View/components/ContractCancelButton';
import { EContractStatus } from '../../../enums';
import {
    convertNumberToCurrencyString,
    getWeekNumber,
    week,
} from '../../../utilities/functions';
import { wageMessages } from '../../../components/Wage/wage.messages';
import { isLocalhost } from '../../../utilities/constants';
import { isSuperUser, isEmployer } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useSecureNavigate } from '../../../utilities/routing';
import { generalMessages } from '../../../utilities/messages';

export const ContractOverview: FC<{
    contract: MContract;
    job?: MJob;
    setReload?: (next: number) => void;
    openReview?: () => void;
}> = ({ contract, job, setReload, openReview }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const { userData } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();
    const [showWageTable, setShowWageTable] = useState(false);
    /**
     * memoized hours per week
     */
    const hpw = useMemo(() => {
        return contract.days.reduce((acc, d) => {
            return acc + (d.to - d.from);
        }, 0);
    }, [contract]);
    /**
     * memoized count of active contract days
     */
    const dayCount = useMemo(() => {
        return contract.activeDates.length;
    }, [contract]);
    /**
     * memoized week count
     * ! will return dogshit if contract duration is over one year
     */
    const weekCount = useMemo(() => {
        const from = new Date(contract.from);
        const to = new Date(contract.to);
        let pivot = from;
        let count = 1;
        while (getWeekNumber(pivot) !== getWeekNumber(to)) {
            count++;
            pivot = new Date(pivot.getTime() + week);
        }
        return count;
    }, [contract]);
    /**
     * memoized expected hours in contract
     */
    const estHours = useMemo(() => {
        return contract.activeDates
            .map((d) => {
                const ds = d.split('.');
                const date = new Date(+ds[2], +ds[1] - 1, +ds[0]);
                const dayRef = contract.days.find(
                    (v) => v.day === date.getDay(),
                );
                const dayHours = dayRef
                    ? (dayRef.to * 60 +
                          dayRef.toMinute -
                          (dayRef.from * 60 + dayRef.fromMinute)) /
                      60
                    : 0;
                return dayHours;
            })
            .reduce((acc, v) => acc + v, 0);
    }, [contract]);
    /**
     * memoized estimated regular pay
     */
    const estpay = useMemo(() => {
        return estHours * (contract.wage.regularWage + contract.wage.wage);
    }, [contract, estHours]);
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="contract-details">
            <View style={style.horizontal}>
                <View style={[style.horizontal, { flex: 1.5 }]}>
                    <View
                        style={[
                            { width: 80, paddingRight: 5 },
                            style.centeredItems,
                        ]}
                    >
                        <CIcon icon={'contractInfos'} size={70} />
                    </View>
                    <View>
                        <CText
                            secondaryHeadline
                            message={contractMessages.contractDetailsSection}
                            style={style.verticalHeavyPadded}
                        />
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon icon={'workplace'} />
                            <CText
                                secondaryHeadline
                                message={contract.workplace?.name}
                            />
                        </View>
                        <View key={'fromto'}>
                            {!contract.tos.length && (
                                <>
                                    <CText style={style.leftPadded}>
                                        {`${format(
                                            jobMessages.fromTo,
                                        )} ${new Date(
                                            contract.from,
                                        ).toLocaleDateString(
                                            'de',
                                        )} - ${new Date(
                                            contract.to,
                                        ).toLocaleDateString('de')}`}
                                    </CText>
                                </>
                            )}
                            {!!contract.tos.length && (
                                <>
                                    <CText style={style.leftPadded}>
                                        {`${format(
                                            jobMessages.fromTo,
                                        )} ${new Date(
                                            contract.from,
                                        ).toLocaleDateString(
                                            'de',
                                        )} - ${new Date(
                                            contract.tos[0].to,
                                        ).toLocaleDateString('de')}`}
                                    </CText>
                                    {contract.tos.map((t, i) => {
                                        if (i)
                                            return (
                                                <View
                                                    key={i}
                                                    style={style.leftPadded}
                                                >
                                                    <CText
                                                        message={`${format(
                                                            contractMessages.extension,
                                                        )} ${i}: ${new Date(
                                                            t.to,
                                                        ).toLocaleDateString(
                                                            'de',
                                                        )}`}
                                                    />
                                                </View>
                                            );
                                    })}
                                </>
                            )}
                        </View>
                        {hpw > 0 && (
                            <CText style={style.leftPadded}>
                                {`${hpw} ${format(
                                    contractMessages.hoursPerWeekUnit,
                                )}`}
                            </CText>
                        )}
                        <CText style={style.leftPadded}>
                            {`${weekCount} ${format(
                                contractMessages.weeks,
                            )} | ${dayCount} ${format(
                                contractMessages.activeDays,
                            )}`}
                        </CText>
                    </View>
                </View>
                <View style={style.flex1}>
                    {job && (isSuperUser(userData) || isEmployer(userData)) && (
                        <>
                            <View style={style.verticalHeavyPadded} />
                            <CButton
                                title={contractMessages.viewJob}
                                onPress={() =>
                                    secureNavigate(
                                        `/japplication/view/${job.documentId}`,
                                    )
                                }
                                icon="eye"
                                minor
                                style={style.smallMargin}
                            />
                        </>
                    )}
                    {contract.status !== EContractStatus.completed && (
                        <>
                            <ContractExtensionButton
                                contract={contract}
                                issueReload={() =>
                                    setReload && setReload(Date.now())
                                }
                            />
                            <ContractCancelButton contract={contract} />
                        </>
                    )}

                    {openReview &&
                        (isLocalhost() ||
                            contract.status === EContractStatus.completed) && (
                            <CButton
                                title={contractMessages.provideReview}
                                onPress={openReview}
                                icon={'star'}
                                minor
                                style={style.smallMargin}
                            />
                        )}
                </View>
            </View>
            <View style={style.horizontal}>
                <View style={{ width: 80 }}>
                    {/* THIS IS A PLACEHOLDER HOLDING PLACE */}
                </View>
                <View style={style.flex1}>
                    <View style={style.verticalHeavyPadded} />
                    <CText secondaryHeadline message={jobMessages.rate} />
                    <TouchableView
                        style={[
                            style.horizontalSplit,
                            style.centeredItems,
                            style.embeddedCard,
                            style.accentBorder,
                            style.smallMargin,
                            { marginVertical: 10 },
                        ]}
                        onPress={() => setShowWageTable(!showWageTable)}
                    >
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon
                                tint={theme.accentColor}
                                icon={
                                    showWageTable
                                        ? 'chevronDown'
                                        : 'chevronRight'
                                }
                            />
                            <CText
                                style={style.accent}
                                message={wageMessages.regularWage}
                            />
                        </View>
                        <CText
                            style={style.accent}
                            message={convertNumberToCurrencyString(
                                contract.wage.wage + contract.wage.regularWage,
                            )}
                        />
                    </TouchableView>
                    {showWageTable && (
                        <RatesTable
                            curData={contract.wage}
                            excludeRegular
                            messageIfEmpty={
                                contractMessages.noOtherRatesDefined
                            }
                        />
                    )}
                    <View style={style.verticalHeavyPadded} />
                    <View style={[style.horizontalSplit, style.verticalPadded]}>
                        <CText message={contractMessages.estimatedHours} />
                        <View style={style.horizontal}>
                            <CText message={`${estHours} `} />
                            <CText message={generalMessages.hours} />
                        </View>
                    </View>
                    <View style={style.horizontalSplit}>
                        <CText
                            secondaryHeadline
                            message={contractMessages.estimatedPay}
                        />
                        <CText
                            secondaryHeadline
                            message={convertNumberToCurrencyString(estpay)}
                        />
                    </View>
                </View>
            </View>
            {/* space because space... */}
            <View style={style.verticalPadded} />
        </View>
    );
};
