import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useStyle } from '../../../utilities/styles';
import { View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import {
    AddressInput,
    CCheckBox,
    CText,
    CTextInput,
    OutOfContext,
} from '../../../components';
import { onboardingMessages } from '../Onboarding.messages';
import { ProfileContext } from '../../Profile/Profile.context';
import { profileMessages } from '../../Profile/Profile.messages';
import { MUserData } from '../../../models';

export const WorkplaceInformation: FC<{
    workplace: string;
    setWorkplace: (wp: string) => void;
}> = ({ workplace, setWorkplace }) => {
    const format = useFormat();
    const style = useStyle();
    const {
        curData,
        curUserData,
        onChangeUserData,
        onChange,
        onValidityChange,
    } = useContext(ProfileContext);
    /**
     * validity function for workplace
     */
    const workplaceEmpty = useMemo(() => {
        if (!workplace) {
            return format(onboardingMessages.noHospitalName);
        }
    }, [workplace]);
    /**
     * validity function for adress
     */
    const addressInvalid = useMemo(() => {
        if (!curData.address.lat || !curData.address.lng) {
            return format(onboardingMessages.invalidAdress);
        }
    }, [curData]);
    /**
     * set validity
     */
    useEffect(() => {
        onValidityChange &&
            onValidityChange(workplaceEmpty || addressInvalid || '');
    }, [workplaceEmpty, addressInvalid]);
    /**
     * check if context is correct
     */
    if (!curUserData || !onChangeUserData) {
        return <OutOfContext component="OnboardingWorkplaceInformation" />;
    }

    return (
        <>
            <CTextInput
                cy={'favored-workplace-name'}
                value={workplace}
                onChangeText={setWorkplace}
                // placeholder={format(profileMessages.workplacePlaceholder)}
                label={format(onboardingMessages.workplaceName)}
                autoExtend
                customCheckInvalidity={() => workplaceEmpty}
            />
            <AddressInput
                cy={'workplace-address'}
                address={curData.address}
                setAddress={(address) => {
                    const next: Partial<MUserData> = { address };
                    if (!curUserData?.billingAddressDiffers) {
                        next.billingAddress = address;
                    }
                    onChangeUserData({ ...next });
                }}
                title={format(profileMessages.address)}
            />
            {
                <View style={style.verticalPadded}>
                    <CCheckBox
                        checked={curUserData.billingAddressDiffers}
                        onCheckedChanged={(c) =>
                            onChangeUserData({ billingAddressDiffers: c })
                        }
                        title={format(profileMessages.billingAddressDiffers)}
                    />
                </View>
            }
            {curUserData.billingAddressDiffers && (
                <AddressInput
                    title={format(profileMessages.billingAddress)}
                    address={curUserData.billingAddress}
                    setAddress={(billingAddress) =>
                        onChangeUserData({ billingAddress })
                    }
                />
            )}
        </>
    );
};
