import React, { FC, useEffect, useMemo, useState } from 'react';
import { CIcon, CText } from '../../../../components';
import { contractMessages } from '../../contract.messages';
import {
    MAgency,
    MChat,
    MContract,
    MProfessionalInfo,
} from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { useFormat } from '../../../../utilities/intl';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { isAgencyUser, isEmployer } from '../../../../utilities/auth';

export const ContractChatTitle: FC<{ chat: MChat; contract: MContract }> = ({
    chat,
    contract,
}) => {
    const { userData, getDataById } = useFireBase();
    const format = useFormat();
    const { theme } = useTheme();
    const style = useStyle();

    const [primaryUser, setPrimaryUser] = useState<MProfessionalInfo>();
    const [agency, setAgency] = useState<MAgency>();

    const primaryUserName = useMemo(() => {
        if (!primaryUser) return '';
        return `${primaryUser.firstName ? `${primaryUser.firstName} ` : ''}${
            primaryUser.lastName
        }`;
    }, [primaryUser]);

    const otherParticipants = useMemo(
        () => chat.participants.filter((uid) => uid !== userData.documentId),
        [chat],
    );

    useEffect(() => {
        if (!otherParticipants.length) return;
        getDataById(ECollections.publicUsers, otherParticipants[0]).then((u) =>
            setPrimaryUser(new MProfessionalInfo(u)),
        );
    }, [otherParticipants]);

    useEffect(() => {
        if (!contract.agencyId) return;
        getDataById(ECollections.publicAgencies, contract.agencyId).then((a) =>
            setAgency(new MAgency(a)),
        );
    }, [contract]);

    return (
        <View>
            <CText
                secondaryHeadline
                message={
                    !primaryUserName
                        ? contractMessages.chat
                        : otherParticipants.length === 1
                        ? format(contractMessages.chatWith, {
                              user: primaryUserName,
                          })
                        : format(contractMessages.chatWithAndMore, {
                              user: primaryUserName,
                              x: otherParticipants.length,
                          })
                }
            />
            {!isEmployer(userData) && contract.workplace?.name && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <CText
                        message={`${format(contractMessages.workplaceRep)} `}
                    />
                    <CText message={contract.workplace.name} />
                </View>
            )}
            {!isAgencyUser(userData) && agency?.name && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <CText message={`${format(contractMessages.agencyRep)} `} />
                    <CText message={agency.name} />
                </View>
            )}
        </View>
    );
};
