import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { MContract, MContractFile } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections, EUserType } from '../../../../enums';
import {
    CButton,
    CImage,
    CText,
    FilePicker,
    Spinner,
} from '../../../../components';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { ContractFile } from './ContractFile';
import {
    isAgencyUser,
    isEmployer,
    isSuperUser,
} from '../../../../utilities/auth';
import { useDialog } from '../../../../utilities/dialog';
import { contractMessages } from '../../contract.messages';
import { useLock } from '../../../../utilities/hooks/useLock';
import { usePreContractFile } from '../functions/usePreContractFile';
import { actionMessages } from '../../../../utilities/messages';
import { requestCourierDialog } from './functions/requestCourierDialog';
import { useLocation, useNavigate } from '../../../../utilities/routing';
import { useShowCourierDialog } from './functions/useShowCourierDialog';
import { Envelope } from './Envelope';
import { usePostContractFile } from '../functions/usePostContractFile';
import { useSignatureEffect } from './functions/useSignatureEffect';
import { useCollectionIndex } from '../../../../utilities/firebase/store/useIndex';
/**
 * all documents related to contract
 * - selfloads files
 * - enveloping functionality
 * - upload functionality
 * - individual signature requests are managed by ContractFile component
 * @returns
 */
export const ContractFiles: FC<{
    contract: MContract;
    maxHeight?: number;
    split?: boolean;
}> = ({ contract, maxHeight, split }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const dialog = useDialog();
    const { lock } = useLock();
    const { userData, userAgencies, userWorkplaces, callFunction } =
        useFireBase();
    const navigate = useNavigate();
    const location = useLocation();
    // local state
    // !remove reload as it is not needed with useCollectionIndex
    const [reload, setReload] = useState(Date.now());
    const [headerHeight, setHeaderHeight] = useState(0);
    const [signatureAvailable, setSignatureAvailable] = useState(false);
    const [loadingSA, setLoadingSA] = useState(true);
    /**
     * memoized file collection
     */
    const fileCollection = useMemo(
        () =>
            `${ECollections.contracts}/${contract.documentId}/${ECollections.files}`,
        [contract],
    );
    /**
     * memoized options to filter file collection for
     */
    const fileParamOptions = useMemo(() => {
        /**
         * filters to get files by
         */
        const fors = ['null'];
        if (isSuperUser(userData)) {
            fors.pop();
        } else if (isEmployer(userData)) {
            fors.push('yer');
        } else {
            fors.push('yee');
        }
        return {
            filter: [
                {
                    field: 'for',
                    operator: 'in' as const,
                    value: fors,
                },
            ],
            suspend: !contract.documentId,
        };
    }, [userData, contract]);
    /**
     * file docs unparsed live
     */
    const fileDocs = useCollectionIndex(fileCollection, fileParamOptions);
    /**
     * parsed files
     */
    const files = useMemo(
        () => fileDocs.map((f) => new MContractFile(f)),
        [fileDocs],
    );
    /**
     * list of author ids
     */
    const authors = useMemo(() => {
        const next: string[] = [];
        for (const file of files) {
            if (!next.includes(file.author)) {
                next.push(file.author);
            }
        }
        return next.sort();
    }, [files]);
    /**
     * files grouped by authors
     */
    const filesGroupedByAuthor = useMemo(() => {
        const next: { author: string; files: MContractFile[] }[] = [];
        for (const author of authors) {
            next.push({
                author,
                files: files
                    .filter((f) => f.author === author)
                    .sort((a, b) => b.createdOn - a.createdOn),
            });
        }
        return next.reduce((acc, f) => {
            acc.push(...f.files);
            return acc;
        }, [] as MContractFile[]);
    }, [files, authors]);
    /**
     * files that are available for signature and not in a signing process already
     */
    const filesAvailableForEnvelope = useMemo(
        () =>
            files
                .filter((f) => f.author === userData.documentId)
                .filter((f) => !f.signatureRequest && !f.envelopeId)
                .filter(
                    (f) =>
                        f.path.split('.')[f.path.split('.').length - 1] ===
                        'pdf',
                ),
        [files, userData],
    );
    /**
     * envelopes
     */
    const envelopes = useMemo(() => {
        type returnType = {
            envelopeId: string;
            envelopeOn: number;
            files: MContractFile[];
            type: string;
        }[];
        const namirialEnvelopeFiles = files.filter((f) => f.envelopeId);
        const namirialEnvelopes = namirialEnvelopeFiles.reduce((acc, f) => {
            const pivot = acc.find((e) => e.envelopeId === f.envelopeId);
            if (pivot) {
                pivot.files.push(f);
            } else {
                acc.push({
                    envelopeId: f.envelopeId,
                    envelopeOn: f.envelopeOn,
                    files: [f],
                    type: isNaN(+f.envelopeId) ? 'namirial' : 'unqualified',
                });
            }

            return acc;
        }, [] as returnType);
        const courierEnvelopeFiles = files.filter((f) => f.signatureRequest);
        const courierEnvelopes = courierEnvelopeFiles.reduce((acc, f) => {
            const exists = acc.find((e) =>
                e.files.find((ef) => ef.documentId === f.documentId),
            );
            if (!exists && f.signatureRequest) {
                acc.push({
                    envelopeId: `${f.signatureRequest.createdOn || acc.length}`,
                    envelopeOn: f.envelopeOn,
                    files: courierEnvelopeFiles.filter((sf) =>
                        f.signatureRequest?.files.includes(sf.documentId),
                    ),
                    type: 'courier',
                });
            }

            return acc;
        }, [] as returnType);

        return [...namirialEnvelopes, ...courierEnvelopes].sort(
            (a, b) => b.envelopeOn - a.envelopeOn,
        );
    }, [files]);
    const { unSignedEnvelopes, signedEnvelopes } = useMemo(() => {
        const unSignedEnvelopes = [];
        const signedEnvelopes = [];
        for (const e of envelopes) {
            if (!e.files[0].envelopeSigned) {
                unSignedEnvelopes.push(e);
            } else {
                signedEnvelopes.push(e);
            }
        }
        return { unSignedEnvelopes, signedEnvelopes };
    }, [envelopes]);
    /**
     * set file infos pre upload
     */
    const preFile = usePreContractFile(contract.documentId, setReload);
    /**
     * handle file upload
     */
    const handleFile = usePostContractFile(contract, setReload);
    /**
     * callback to show details of signature availability
     */
    const showSignatureNotAvailable = useCallback(async () => {
        await dialog({
            icon: 'warning',
            title: contractMessages.digitalSignatureUnavailable,
            message: contractMessages.digitalSignatureUnavailableText,
            buttons: [
                { text: actionMessages.ok, color: theme.warningColor },
                {
                    text: contractMessages.checkYourStatus,
                    onPress: () => navigate('/profile/digicert'),
                },
            ],
        });
    }, [theme]);
    /**
     * handle envelope request
     */
    const handleEnvelopeRequest = useCallback(async () => {
        const envelopeFiles: string[] = [];
        if (
            !(await dialog({
                icon: 'signature',
                title: contractMessages.selectFilesForEnvelope,
                message: contractMessages.selectFilesForEnvelopeText,
                multiRadioInput: {
                    id: 'envelopeFiles',
                    title: contractMessages.fileList,
                    values: filesAvailableForEnvelope.map((file) => {
                        return {
                            value: file.documentId,
                            id: file.documentId,
                            label:
                                // format(filenameMessages[file.type]) +
                                // ' ' +
                                file.name,
                        };
                    }),
                },
                buttons: [
                    {
                        text: actionMessages.ok,
                        onPress: (inputs) => {
                            const efi = inputs?.find(
                                (v) => v.id === 'envelopeFiles',
                            );
                            if (efi) {
                                envelopeFiles.push(...(efi.value as string[]));
                            }
                        },
                        disabled: (inputs) => {
                            const efi = inputs?.find(
                                (v) => v.id === 'envelopeFiles',
                            );
                            return (
                                !efi?.value || !(efi?.value as string[]).length
                            );
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            }))
        )
            return;
        if (
            !(await dialog({
                icon: 'envelope',
                title: contractMessages.confirmEnvelope,
                message: contractMessages.confirmEnvelopeText,
                subMessages: envelopeFiles.map((efi) => {
                    const ref = filesAvailableForEnvelope.find(
                        (f) => f.documentId === efi,
                    );

                    return ref?.name || '';
                }),
                buttons: [{ text: actionMessages.confirm }],
                cancelButton: { text: actionMessages.cancel },
            }))
        )
            return;
        let mode = 'courier';
        if (
            !(await dialog({
                icon: 'signature',
                title: contractMessages.selectSignatureType,
                message: contractMessages.selectSignatureTypeText,
                buttons: [
                    {
                        text: contractMessages.signUsingNamirial,
                        onPress: () => (mode = 'namirial'),
                        disabled: () => !signatureAvailable,
                    },
                    {
                        text: contractMessages.signUnqualified,
                        onPress: () => (mode = 'unqualified'),
                    },
                    {
                        text: contractMessages.signUsingCourier,
                        onPress: () => (mode = 'courier'),
                    },
                ],
                verticalButtons: true,
                cancelButton: {
                    text: actionMessages.cancel,
                },
            }))
        ) {
            return;
        }

        if (mode === 'namirial' || mode === 'unqualified') {
            if (
                mode !== 'namirial' ||
                (await dialog({
                    icon: 'namirial',
                    title: contractMessages.signWithNamirial,
                    message: contractMessages.signWithNamirialText,
                    buttons: [{ text: actionMessages.ok }],
                    cancelButton: { text: actionMessages.cancel },
                }))
            ) {
                navigate(
                    `/requestSigning?contractId=${contract.documentId}&fid=${envelopeFiles}&prev=${location.pathname}&type=${mode}`,
                    { replace: true },
                );
            }
        } else {
            const resource = isEmployer(userData)
                ? userWorkplaces.find(
                      (wp) => wp.documentId === contract.workplaceId,
                  )
                : isAgencyUser(userData)
                ? userAgencies[0]
                : undefined;
            const defaultAddress = resource
                ? resource.address
                : userData.address;
            const defaultRecipient = resource?.name || '';
            /**
             * dialog to set address informations
             */
            const result = await requestCourierDialog(
                dialog,
                defaultRecipient,
                defaultAddress,
            );
            if (result) {
                const unlock = lock();
                await callFunction('requestCourierForFile', {
                    ...result,
                    contractId: contract.documentId,
                    fileDocIds: envelopeFiles.join(','),
                });
                unlock();
                setReload(Date.now());
            }
        }
    }, [
        filesAvailableForEnvelope,
        userData,
        location,
        contract,
        signatureAvailable,
    ]);
    /**
     * callback to issue a reload
     */
    const issueReload = useCallback(() => setReload(Date.now()), []);
    /**
     * callback to show courier dialog
     */
    const showCourierDialog = useShowCourierDialog(issueReload);
    /**
     * effect to trigger courier popup for not author
     */
    useEffect(() => {
        const filesWithOpenCourier = files.filter(
            (f) =>
                f.signatureRequest &&
                f.signatureRequest.uid === userData.documentId,
        );
        if (filesWithOpenCourier.length) {
            showCourierDialog(filesWithOpenCourier[0], contract);
        }
    }, [files, contract, userData, showCourierDialog]);
    /**
     * effect to check on signature availability on contract load
     */
    useEffect(() => {
        if (contract && contract.documentId) {
            setLoadingSA(true);
            callFunction('checkSignatureAvailability', {
                contractId: contract.documentId,
            }).then((v) => {
                setSignatureAvailable(!!v);
                setLoadingSA(false);
            });
        }
    }, [contract]);

    useSignatureEffect(issueReload, contract);
    /**
     * return spinner if no contract
     */
    if (!contract) {
        return <Spinner />;
    }

    /**
     * render
     */
    return (
        <View
            style={[
                style.card,
                {
                    height: maxHeight
                        ? 320 < maxHeight
                            ? maxHeight - 20
                            : 300
                        : undefined,
                    minHeight: 300,
                },
            ]}
        >
            <View
                style={[style.horizontalSplit, style.centeredItems]}
                onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
            >
                <CText headline message={contractMessages.fileList} />
                {userData.type !== EUserType.talent && (
                    <View style={style.horizontal}>
                        {loadingSA && <Spinner small />}
                        {!signatureAvailable && !loadingSA && (
                            <CButton
                                icon="alert"
                                onPress={showSignatureNotAvailable}
                                iconColor={theme.warningColor}
                                minor
                                transparent
                                style={{
                                    marginHorizontal: 0,
                                    padding: 5,
                                    width: 'auto',
                                }}
                            />
                        )}
                        {!!filesAvailableForEnvelope.length && !loadingSA && (
                            <CButton
                                onPress={handleEnvelopeRequest}
                                icon="signature"
                                mouseOverTitle={
                                    contractMessages.requestSignature
                                }
                                minor
                                transparent
                                style={{
                                    marginHorizontal: 0,
                                    padding: 5,
                                    width: 'auto',
                                }}
                            />
                        )}
                        <FilePicker onFile={handleFile} preFile={preFile} />
                    </View>
                )}
            </View>
            {!files.length && (
                <View>
                    <CImage image="documents" maxWidth={200} />
                    <CText
                        message={contractMessages.noFiles}
                        centered
                        secondaryHeadline
                    />
                </View>
            )}
            <View
                style={[
                    style.horizontal,
                    {
                        height: maxHeight
                            ? (320 < maxHeight ? maxHeight - 20 : 300) -
                              headerHeight -
                              20
                            : undefined,
                    },
                ]}
            >
                {split && (
                    <ScrollView style={{ flex: 1 }}>
                        {filesGroupedByAuthor
                            .filter(
                                (file) =>
                                    !file.envelopeId && !file.signatureRequest,
                            )
                            .map((file) => {
                                return (
                                    <ContractFile
                                        key={file.documentId}
                                        file={file}
                                        contract={contract}
                                        issueReload={() =>
                                            setReload(Date.now())
                                        }
                                        signatureAvailable={signatureAvailable}
                                    />
                                );
                            })}
                    </ScrollView>
                )}
                {split && <View style={style.horizontalPadded} />}
                <ScrollView style={{ flex: 1 }}>
                    {!!unSignedEnvelopes.length && (
                        <CText
                            message={contractMessages.unSignedEnvelopes}
                            style={style.verticalPadded}
                        />
                    )}
                    {unSignedEnvelopes.map((envelope) => (
                        <Envelope
                            key={envelope.envelopeId}
                            envelope={envelope}
                            contract={contract}
                            issueReload={() => setReload(Date.now())}
                        />
                    ))}
                    {!!signedEnvelopes.length && (
                        <CText
                            message={contractMessages.signedEnvelopes}
                            style={style.verticalPadded}
                        />
                    )}
                    {signedEnvelopes.map((envelope) => (
                        <Envelope
                            key={envelope.envelopeId}
                            envelope={envelope}
                            contract={contract}
                            issueReload={() => setReload(Date.now())}
                        />
                    ))}
                    {!split && (
                        <>
                            {filesGroupedByAuthor
                                .filter(
                                    (file) =>
                                        !file.envelopeId &&
                                        !file.signatureRequest,
                                )
                                .map((file) => {
                                    return (
                                        <ContractFile
                                            key={file.documentId}
                                            file={file}
                                            contract={contract}
                                            issueReload={() =>
                                                setReload(Date.now())
                                            }
                                            signatureAvailable={
                                                signatureAvailable
                                            }
                                        />
                                    );
                                })}
                            <View style={style.horizontalSpaced}>
                                <FilePicker
                                    onFile={handleFile}
                                    preFile={preFile}
                                />
                            </View>
                        </>
                    )}
                </ScrollView>
            </View>
        </View>
    );
};
