import { useCallback } from 'react';
import { MContract } from '../../../../models';
import { useDialog } from '../../../../utilities/dialog';
import { useFireBase } from '../../../../utilities/firebase';
import { generalMessages } from '../../../../utilities/messages';
import { useLock } from '../../../../utilities/hooks';
import { useFileUpload } from '../../../../utilities/hooks/useFileUpload';

export const usePostContractFile = (
    contract: MContract | undefined,
    setReload: (next: number) => void,
) => {
    const { callFunction, userData } = useFireBase();
    const { lock } = useLock();
    const dialog = useDialog();
    const fileUpload = useFileUpload();

    const postContractFile = useCallback(
        async (
            newFn: string,
            file: Uint8Array,
            preFileResult: { nextType: string; fakeSigned?: boolean },
        ): Promise<string | void> => {
            if (!contract) return;
            /**
             * lock view for upload process
             */
            const unlock = lock();
            try {
                const baseFile = await fileUpload(
                    `contracts/${contract.documentId}/${userData.documentId}`,
                    newFn,
                    file,
                );

                /**
                 * call set contract to let firebase cloud function move the file to an appropriate location
                 * and add it to the contract
                 * reload contract afterwards
                 */
                const fid = await callFunction('setContractFile', {
                    filename: baseFile.name,
                    path: baseFile.path,
                    filetype: preFileResult.nextType,
                    contractId: contract.documentId,
                    fakeSigned: preFileResult.fakeSigned,
                });

                unlock();
                setReload(Date.now());
                return fid;
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [contract, fileUpload],
    );

    return postContractFile;
};
