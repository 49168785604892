import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import {
    AddressInput,
    CButton,
    CCard,
    CCheckBox,
    CDatePicker,
    CImage,
    CPicker,
    CText,
    CTextInput,
    DocumentPreviewRow,
    Spinner,
    TouchableView,
} from '../../../components';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { RichTextEditor, RichTextView } from '../../../components/RichText';
import { EAgencyFiles, ECollections, ERegion } from '../../../enums';
import { MAgency, MAgencyFile } from '../../../models';
import { isAgencyUser, isSuperUser } from '../../../utilities/auth';
import {
    ScrollContext,
    ScrollProvider,
} from '../../../utilities/contexts/Scroll';
import {
    useAdminDialog,
    useUnsavedChangesDialog,
} from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import {
    useParams,
    useSearchParams,
    useSecureNavigate,
} from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { agencyMessages } from '../agency.messages';
import { AgencyDocuments } from './components/AgencyDocuments';
import { sortObjectKeys } from '../../../utilities/functions/sortObjectKeys';
import Clipboard from '@react-native-clipboard/clipboard';
import { AgencyUsers } from './components/AgencyUsers';

export const UnwrappedAgencyEdit: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const [searchParams] = useSearchParams();
    const { y, setY } = useContext(ScrollContext);
    const format = useFormat();
    const { id } = useParams<{ id: string }>();
    const { post, put, getDataById, userData, reloadUserData, getDataIndex } =
        useFireBase();
    const adminDialog = useAdminDialog();
    // local state
    const [curValidity, setCurValidity] = useState('');
    const [subValidity, setCurSubValidity] = useState('');
    const [agency, setAgency] = useState(new MAgency());
    const [prevAgency, setPrevAgency] = useState<MAgency>();
    const [editingNote, setEditingNote] = useState(false);
    const [note, setNote] = useState('');
    const [prevNote, setPrevNote] = useState('');
    const [loading, setLoading] = useState(true);
    const isCreating = useMemo(() => id === 'new', [id]);
    const [mrl, setMRL] = useState<MAgencyFile>();
    /**
     * memoized check for unstaged changes
     */
    const unsavedChanges = useMemo(() => {
        if (!agency) {
            return false;
        }
        /**
         * prev with to be sorted address
         */
        const prev = prevAgency || ({ address: {}, billingAddress: {} } as any);
        prev.address = sortObjectKeys(prev.address);
        prev.billingAddress = sortObjectKeys(prev.billingAddress);
        /**
         * current with to be sorted address
         */
        const current = agency || ({} as any);
        current.address = sortObjectKeys(current.address);
        current.billingAddress = sortObjectKeys(current.billingAddress);
        /**
         * compare sorted object for differences
         */
        const strcmp = JSON.stringify(prev) !== JSON.stringify(agency);
        /**
         * return conditions for unsaved changes
         */
        return !prevAgency || strcmp || prevNote !== note;
    }, [prevAgency, agency, note, prevNote]);
    /**
     * handle changes
     */
    const onChange = useCallback((next: Partial<MAgency>) => {
        if (next.address && !next.billingAddressDiffers) {
            next.billingAddress = next.address;
        }
        setAgency((prev) => {
            return new MAgency({ ...prev, ...next });
        });
    }, []);
    /**
     * handle saving of agency
     */
    const handleSave = useCallback(
        async (skiptNavigate?: boolean) => {
            if (isSuperUser(userData) && !(await adminDialog())) return;
            if (isCreating) {
                await post(ECollections.agencies, {
                    ...agency,
                });
            } else if (id) {
                await put(ECollections.agencies, id, agency);
            } else {
                throw 'no id for this agency';
            }
            if (isAgencyUser(userData)) {
                reloadUserData();
            }
            if (!skiptNavigate) {
                secureNavigate('/agency', { force: true });
            }
        },
        [agency, userData],
    );
    const unsavedChangesDialog = useUnsavedChangesDialog(handleSave);
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        return locking;
    }, [unsavedChanges, unsavedChangesDialog]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * validity functions
     */
    const ibanValidity = useCallback((IBAN: string) => {
        function smellsLikeIban(str: string) {
            return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
                str,
            );
        }
        const ibanStripped = IBAN.replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
            .toUpperCase(); //calculation expects upper-case
        if (IBAN && !smellsLikeIban(ibanStripped)) {
            return format(agencyMessages.ibanInvalidError);
        }
    }, []);
    const bicValidity = useCallback((BIC: string) => {
        if (BIC && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(BIC)) {
            return format(agencyMessages.bicInvalidError);
        }
    }, []);
    /**
     * handle save of note
     */
    const handleNoteSave = useCallback(async () => {
        if (!agency) {
            return console.error('unexpected access to this function');
        }

        await put(ECollections.agencyNotes, agency.documentId, { note });

        setPrevNote(note);
    }, [agency, note]);
    /**
     * effect to load prev data
     */
    useEffect(() => {
        if (id && id !== 'new') {
            if (isSuperUser(userData)) {
                getDataById(ECollections.agencyNotes, id).then((result) => {
                    if (result && result.note) {
                        setNote(result.note);
                        setPrevNote(result.note);
                    }
                });
            }
            getDataById(ECollections.agencies, id).then((result) => {
                const nextAgency = new MAgency(result);
                setAgency(nextAgency);
                setPrevAgency(nextAgency);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [id, userData]);
    /**
     * effect to load most recent license file to display @ license input
     */
    useEffect(() => {
        if (!agency.documentId) return;
        getDataIndex(
            `${ECollections.agencies}/${agency.documentId}/${ECollections.files}`,
            { filter: [{ field: 'type', value: EAgencyFiles.license }] },
        ).then((res) => {
            const licenseFiles = (res as MAgencyFile[]).map(
                (f) => new MAgencyFile(f),
            );
            setMRL(licenseFiles.sort((a, b) => b.createdOn - a.createdOn)[0]);
        });
    }, [agency]);
    /**
     * loading indicator while loading
     */
    if (loading) {
        return <Spinner />;
    }
    return (
        <>
            <View style={style.headlineCard}>
                <View style={[style.horizontalSplit, { width: '100%' }]}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={() => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <CText style={style.leftHeavyPadded} bold headline>
                            {isCreating
                                ? format(agencyMessages.creating)
                                : format(agencyMessages.editing, {
                                      agency: prevAgency?.name || '...',
                                  })}
                        </CText>
                    </View>
                    {!!agency.documentId && (
                        <View style={{ alignItems: 'flex-end' }}>
                            <CText message={agencyMessages.agencyId} />
                            <TouchableView
                                onPress={() =>
                                    Clipboard.setString(agency.documentId)
                                }
                            >
                                <CText selectable message={agency.documentId} />
                            </TouchableView>
                        </View>
                    )}
                </View>
            </View>
            <CCard>
                {!!agency.documentId && (
                    <View style={style.verticalPadded}>
                        <CText>{format(agencyMessages.picture)}</CText>
                        <View style={[style.horizontalSplit]}>
                            <View
                                style={[
                                    style.horizontalSplit,
                                    style.centeredItems,
                                ]}
                            >
                                <ProfilePicture
                                    data={agency}
                                    large
                                    onChange={(picture) =>
                                        onChange({ picture })
                                    }
                                />
                            </View>
                            <View />
                        </View>
                    </View>
                )}
                {isSuperUser(userData) && (
                    <View
                        style={[
                            style.horizontal,
                            style.horizontalPadded,
                            style.centeredItems,
                        ]}
                    >
                        <View style={style.horizontalPadded}>
                            <CImage image={agency.region} fixedHeight={20} />
                        </View>
                        <CPicker
                            values={Object.values(ERegion).map((v) => ({
                                value: v,
                                label: v,
                            }))}
                            onChange={(v) => {
                                onChange({ region: v });
                            }}
                            value={agency.region}
                        />
                    </View>
                )}
                <CTextInput
                    cy={'agency-name'}
                    label={format(agencyMessages.name)}
                    value={agency.name}
                    onChangeText={(name) =>
                        onChange({
                            name,
                            bankDetails: {
                                ...agency.bankDetails,
                                accountHolderName: name,
                            },
                        })
                    }
                    autoExtend
                    disabled={!isSuperUser(userData)}
                />
                {isAgencyUser(userData) && (
                    <>
                        <CText
                            style={
                                !agency.licenseUntil && {
                                    color: theme.errorColor,
                                }
                            }
                        >
                            {agency.licenseUntil
                                ? `${format(
                                      agencyMessages.licensedUntil,
                                  )}: ${new Date(
                                      agency.licenseUntil,
                                  ).toLocaleDateString('de')}`
                                : format(agencyMessages.unlicensed)}
                        </CText>
                    </>
                )}
                <CTextInput
                    cy={'tax-number'}
                    value={agency.taxNumber}
                    onChangeText={(taxNumber) => onChange({ taxNumber })}
                    // placeholder={format(profileMessages.workplacePlaceholder)}
                    label={format(agencyMessages.taxNumber)}
                    autoExtend
                    // customCheckInvalidity={() => taxNumberEmpty}
                />
                <CTextInput
                    cy={'register-number'}
                    value={agency.registerNumber}
                    onChangeText={(registerNumber) =>
                        onChange({ registerNumber })
                    }
                    // placeholder={format(profileMessages.workplacePlaceholder)}
                    label={format(agencyMessages.registerNumber)}
                    autoExtend
                    // customCheckInvalidity={() => registerNumberEmpty}
                />
                {/* <CTextInput
                    cy={'agency-website'}
                    label={format(agencyMessages.website)}
                    value={agency.website}
                    onChangeText={(website) =>
                        onChange({
                            website,
                        })
                    }
                    autoExtend
                />
                <CTextInput
                    cy={'agency-phone'}
                    label={format(agencyMessages.phone)}
                    value={agency.phone}
                    onChangeText={(phone) =>
                        onChange({
                            phone,
                        })
                    }
                    autoExtend
                /> */}
                {/* <CTextInput
                    cy={'agency-mail'}
                    label={format(agencyMessages.mail)}
                    value={agency.mail}
                    onChangeText={(mail) =>
                        onChange({
                            mail,
                        })
                    }
                    autoExtend
                /> */}
                {agency.region !== ERegion.za && (
                    <>
                        <CTextInput
                            value={agency.bankDetails.IBAN}
                            onChangeText={(IBAN) =>
                                onChange({
                                    bankDetails: {
                                        ...agency.bankDetails,
                                        IBAN,
                                    },
                                })
                            }
                            placeholder={format(agencyMessages.ibanPlaceholder)}
                            label={format(agencyMessages.iban)}
                            customCheckInvalidity={ibanValidity}
                            autoExtend
                        />
                        <CTextInput
                            value={agency.bankDetails.BIC}
                            onChangeText={(BIC) =>
                                onChange({
                                    bankDetails: {
                                        ...agency.bankDetails,
                                        BIC,
                                    },
                                })
                            }
                            placeholder={format(agencyMessages.bicPlaceholder)}
                            label={format(agencyMessages.bic)}
                            customCheckInvalidity={bicValidity}
                            autoExtend
                        />
                    </>
                )}
            </CCard>
            {isSuperUser(userData) && (
                <CCard style={[{ zIndex: 1 }]} outsideStyle={{ zIndex: 1 }}>
                    <CTextInput
                        cy={'agency-owner'}
                        label={format(agencyMessages.owner)}
                        value={agency.owner}
                        onChangeText={(owner) =>
                            onChange({
                                owner,
                            })
                        }
                        autoExtend
                    />
                    <View
                        style={[
                            { zIndex: 1 },
                            !agency.licenseUntil && style.errorBorder,
                            agency.licenseUntil < Date.now() &&
                                style.errorBorder,
                        ]}
                    >
                        <CDatePicker
                            cy={'agency-licence'}
                            title={format(agencyMessages.licensedUntil)}
                            value={
                                agency.licenseUntil
                                    ? new Date(agency.licenseUntil)
                                    : new Date()
                            }
                            onChange={(d) =>
                                onChange({
                                    licenseUntil: d.getTime(),
                                })
                            }
                        />
                    </View>
                    {mrl && (
                        <DocumentPreviewRow
                            file={mrl}
                            // onDelete={() => {
                            //     const next = Array.from(agency.files);
                            //     next.splice(mrl.index, 1);
                            //     onChange({ files: next });
                            // }}
                        />
                    )}
                </CCard>
            )}
            {isSuperUser(userData) && (
                <CCard>
                    <View style={style.verticalPadded}>
                        <CText>{format(agencyMessages.note)}</CText>
                    </View>
                    {editingNote ? (
                        <RichTextEditor text={note} onChange={setNote} />
                    ) : (
                        <RichTextView html={note} />
                    )}
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        {editingNote ? (
                            <CButton
                                cy={'save-note'}
                                icon={'check'}
                                title={format(agencyMessages.finishEditing)}
                                onPress={() => {
                                    handleNoteSave().then(() => {
                                        setEditingNote(false);
                                    });
                                }}
                            />
                        ) : (
                            <CButton
                                cy={'edit-note'}
                                title={format(agencyMessages.editNote)}
                                onPress={() => {
                                    setEditingNote(true);
                                }}
                            />
                        )}
                    </View>
                </CCard>
            )}
            <CCard>
                <AddressInput
                    cy={'agency-address'}
                    title={format(agencyMessages.address)}
                    address={agency.address}
                    setAddress={(address) => onChange({ address })}
                />
                <View style={style.verticalPadded}>
                    <CCheckBox
                        cy={'agency-abe'}
                        checked={agency.billingAddressDiffers}
                        onCheckedChanged={(c) =>
                            onChange({ billingAddressDiffers: c })
                        }
                        title={format(agencyMessages.billingAddressDiffers)}
                    />
                </View>
                {agency.billingAddressDiffers && (
                    <AddressInput
                        cy={'agency-billingaddress'}
                        title={format(agencyMessages.billingAddress)}
                        address={agency.billingAddress}
                        setAddress={(billingAddress) =>
                            onChange({ billingAddress })
                        }
                    />
                )}
            </CCard>
            <View
                style={[
                    style.card,
                    !!searchParams.get('documents') && style.warningBorder,
                ]}
                onLayout={(e) => {
                    const yOfView = e.nativeEvent.layout.y;
                    if (searchParams.get('documents') && y !== yOfView) {
                        setY(yOfView);
                    }
                }}
            >
                <AgencyDocuments onChange={onChange} agency={agency} />
            </View>
            {!!agency.documentId && (
                <CCard>
                    <AgencyUsers agency={agency} onChange={onChange} />
                </CCard>
            )}
            {!!agency.documentId && (
                <CCard>
                    <CText
                        secondaryHeadline
                        message={agencyMessages.timeTracking}
                    />
                    <CCheckBox
                        checked={agency.enableTimeTracking}
                        onCheckedChanged={(enableTimeTracking) =>
                            onChange({ enableTimeTracking })
                        }
                        title={agencyMessages.enableTimeTracking}
                    />
                    <CTextInput
                        value={`${agency.shiftLength}`}
                        onChangeText={(shiftLength) => {
                            if (isNaN(+shiftLength)) return;
                            onChange({
                                shiftLength: +shiftLength,
                            });
                        }}
                        label={agencyMessages.shiftLength}
                        disabled={!agency.enableTimeTracking}
                        unit={generalMessages.minutes}
                    />
                    <CTextInput
                        value={`${agency.breakLength}`}
                        onChangeText={(breakLength) => {
                            if (isNaN(+breakLength)) return;
                            onChange({
                                breakLength: +breakLength,
                            });
                        }}
                        label={agencyMessages.breakLength}
                        disabled={!agency.enableTimeTracking}
                        unit={generalMessages.minutes}
                    />
                    <View style={style.horizontal}>
                        {agency.enableTimeTracking && (
                            <CButton
                                title={agencyMessages.viewTimes}
                                onPress={() =>
                                    secureNavigate(
                                        '/agency/times/' + agency.documentId,
                                    )
                                }
                                icon="eye"
                            />
                        )}
                    </View>
                </CCard>
            )}
            <View style={[style.verticalPadded, style.centeredItems]}>
                {!!curValidity && (
                    <CText style={style.error}>
                        {format(generalMessages.somethingWrong)}
                    </CText>
                )}
                {unsavedChanges && (
                    <CText style={style.warning}>
                        {format(generalMessages.unsavedChanges)}
                    </CText>
                )}
                <CButton
                    cy={'save'}
                    disabled={!!curValidity}
                    title={format(actionMessages.save)}
                    onPress={handleSave}
                />
            </View>
        </>
    );
};

export const AgencyEdit: FC = () => {
    const style = useStyle();
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <UnwrappedAgencyEdit />
        </ScrollProvider>
    );
};
