import { defineMessages } from 'react-intl';

const scope = 'con.profile.';
const notificationScope = scope + 'notification.';
const errScope = 'con.profile.error.';
const placeholder = 'con.profile.placeholder.';

export const profileMessages = defineMessages({
    profilePicture: {
        id: scope + 'profilePicture',
        defaultMessage: 'Profile picture',
    },
    address: {
        id: scope + 'address',
        defaultMessage: 'Address',
    },
    billingAddress: {
        id: scope + 'billingAddress',
        defaultMessage: 'Billing address',
    },
    billingAddressDiffers: {
        id: scope + 'billingAddressDiffers',
        defaultMessage: 'Billing address is different from address',
    },
    selectTitle: {
        id: scope + 'selectTitle',
        defaultMessage: 'Select title',
    },
    gender: {
        id: scope + 'gender',
        defaultMessage: 'Select gender',
    },
    gender2: {
        id: scope + 'gender2',
        defaultMessage: 'Select salutation',
    },
    genderMissing: {
        id: scope + 'genderMissing',
        defaultMessage: 'Please select a gender',
    },
    gender2Missing: {
        id: scope + 'gender2Missing',
        defaultMessage: 'Please select a salutation',
    },
    firstName: {
        id: scope + 'firstName',
        defaultMessage: 'First name',
    },
    firstNamePlaceholder: {
        id: placeholder + 'firstName',
        defaultMessage: 'First name e.g. Max',
    },
    firstNameMissingError: {
        id: errScope + 'missingFirstName',
        defaultMessage: 'First name ist mandatory',
    },
    lastName: {
        id: scope + 'lastName',
        defaultMessage: 'Last name',
    },
    lastNamePlaceholder: {
        id: placeholder + 'lastName',
        defaultMessage: 'Last name e.g. Musterman',
    },
    lastNameMissingError: {
        id: errScope + 'missingLastName',
        defaultMessage: 'Last name ist mandatory',
    },
    placeOfBirth: {
        id: scope + 'placeOfBirth',
        defaultMessage: 'Location of birth',
    },
    dob: {
        id: scope + 'dob',
        defaultMessage: 'Date of birth',
    },
    dobMissing: {
        id: scope + 'dobMissing',
        defaultMessage: 'Please provide a date of birth',
    },
    dobToClose: {
        id: scope + 'dobToClose',
        defaultMessage:
            'You have to be at least 18 years of age to use this service',
    },
    phone: {
        id: scope + 'phone',
        defaultMessage: 'Phone',
    },
    phonePlaceholder: {
        id: placeholder + 'phone',
        defaultMessage: 'Phone number e.g. 0123 1234567',
    },
    phoneMissingError: {
        id: errScope + 'phoneMissingError',
        defaultMessage: 'Please provide a phonenumber',
    },
    phoneMalformatedError: {
        id: errScope + 'phoneMalformatedError',
        defaultMessage: 'Phonenumber seems to be invalid',
    },
    nationality: {
        id: scope + 'nationality',
        defaultMessage: 'Nationality',
    },
    nationalityPlaceholder: {
        id: placeholder + 'nationality',
        defaultMessage: 'Please select your nationality',
    },
    nationalityMissingError: {
        id: errScope + 'nationalityMissingError',
        defaultMessage: 'Please provide a nationality',
    },
    titlePlaceholder: {
        id: placeholder + 'titlePlaceholder',
        defaultMessage: 'Please select a title',
    },
    genderPlaceholder: {
        id: placeholder + 'genderPlaceholder',
        defaultMessage: 'Please select a gender',
    },
    education: {
        id: scope + 'education',
        defaultMessage: 'Education',
    },
    eduPlaceholder: {
        id: placeholder + 'eduPlaceholder',
        defaultMessage: 'Please select a education',
    },
    specialization: {
        id: scope + 'specialization',
        defaultMessage: 'Specialization',
    },
    position: {
        id: scope + 'position',
        defaultMessage: 'Position',
    },
    selectPrefEmployment: {
        id: scope + 'selectPrefEmployment',
        defaultMessage: 'Select your preferred employment',
    },
    prefEmploymentPlaceholder: {
        id: placeholder + 'prefEmploymentPlaceholder',
        defaultMessage: 'Please select your preferred employment',
    },
    addExp: {
        id: scope + 'addExp',
        defaultMessage: 'Additional experience',
    },
    addExpPlaceholder: {
        id: placeholder + 'addExp',
        defaultMessage: 'Additional experience ',
    },
    yoe: {
        id: scope + 'yoe',
        defaultMessage: 'Years of experience',
    },
    yoePlaceholder: {
        id: placeholder + 'yoe',
        defaultMessage: 'Years of experience ',
    },
    accountHolder: {
        id: scope + 'accountHolder',
        defaultMessage: 'Account holder name',
    },
    accountHolderPlaceholder: {
        id: placeholder + 'accountHolder',
        defaultMessage: 'Account holder name e.g. Max Mustermann',
    },
    iban: {
        id: scope + 'iban',
        defaultMessage: 'IBAN',
    },
    ibanPlaceholder: {
        id: placeholder + 'iban',
        defaultMessage: 'IBAN e.g. DE02120300000000202051',
    },
    ibanInvalidError: {
        id: errScope + 'ibanInvalidError',
        defaultMessage: 'IBAN seems to be incorrectly formatted',
    },
    bic: {
        id: scope + 'bic',
        defaultMessage: 'BIC',
    },
    bicPlaceholder: {
        id: placeholder + 'bic',
        defaultMessage: 'BIC e.g. BYLADEM1001',
    },
    bicInvalidError: {
        id: errScope + 'bicInvalidError',
        defaultMessage: 'BIC seems to be incorrectly formatted',
    },
    hisInsuranceMembership: {
        id: scope + 'hisInsuranceMembership',
        defaultMessage: 'Health insurance membership',
    },
    hisInsuranceMembershipPlaceholder: {
        id: placeholder + 'hisInsuranceMembership',
        defaultMessage: 'Health insurance membership',
    },
    taxNumber: {
        id: scope + 'taxNumber',
        defaultMessage: 'Tax number',
    },
    registerNumber: {
        id: scope + 'registerNumber',
        defaultMessage: 'Register number',
    },
    taxNumberPlaceholder: {
        id: placeholder + 'taxNumber',
        defaultMessage: 'Tax number',
    },
    registerNumberPlaceholder: {
        id: placeholder + 'registerNumber',
        defaultMessage: 'Register number',
    },
    taxClass: {
        id: scope + 'taxClass',
        defaultMessage: 'Tax class',
    },
    taxClassPlaceholder: {
        id: placeholder + 'taxClassPlaceholder',
        defaultMessage: 'Please select your tax class',
    },
    socialSecurityNumber: {
        id: scope + 'socialSecurityNumber',
        defaultMessage: 'Social security number',
    },
    socialSecurityNumberPlaceholder: {
        id: placeholder + 'socialSecurityNumber',
        defaultMessage: 'Social security number',
    },
    childAllowance: {
        id: scope + 'childAllowance',
        defaultMessage: 'Child allowance',
    },
    childAllowancePlaceholder: {
        id: placeholder + 'childAllowance',
        defaultMessage: 'Child allowance',
    },
    denomination: {
        id: scope + 'denomination',
        defaultMessage: 'Select denomination',
    },
    denominationPlaceholder: {
        id: placeholder + 'denominationPlaceholder',
        defaultMessage: 'Please select a denomination or religion',
    },
    bankDataApproval: {
        id: scope + 'bankDataApproval',
        defaultMessage:
            'I agree that {platform} may pass on my data to contractual partners in order to enable the parties to negotiate a contract.',
    },
    permanent: {
        id: scope + 'permanentPosition',
        defaultMessage: 'Permanent position (fulltime or parttime)',
    },
    partTime: {
        id: scope + 'substitute',
        defaultMessage: 'Substitute worker',
    },
    willingToTravel: {
        id: scope + 'willingToTravel',
        defaultMessage: 'Willing to travel for a job',
    },
    notWillingToTravel: {
        id: scope + 'notWillingToTravel',
        defaultMessage: 'Not willing to travel for a job',
    },
    neutralToTravel: {
        id: scope + 'neutralToTravel',
        defaultMessage: 'Occasionally willing to travel for a job',
    },
    shortTerm: {
        id: scope + 'shortTerm',
        defaultMessage: 'Short-term employee leasing',
    },
    basic: {
        id: scope + 'basic',
        defaultMessage: 'Basic information about yourself',
    },
    personal: {
        id: scope + 'personal',
        defaultMessage: 'Personal information about yourself',
    },
    interest: {
        id: scope + 'interest',
        defaultMessage: 'Preferred method of employment',
    },
    addressInfo: {
        id: scope + 'addressInfo',
        defaultMessage: 'Address Information',
    },
    addressExplanationUser: {
        id: scope + 'addressExplanationUser',
        defaultMessage:
            'Please provide your address information. The information you provide will be used to optimize the job matching process, by ensuring we only suggest job offers to you, that are within in your proximity',
    },
    bankInfo: {
        id: scope + 'bankInfo',
        defaultMessage: 'Banking information',
    },
    paymentInterval: {
        id: scope + 'paymentInterval',
        defaultMessage: 'Payment interval',
    },
    documents: {
        id: scope + 'documents',
        defaultMessage: 'Personal documents',
    },
    accountType: {
        id: scope + 'accountType',
        defaultMessage: 'Account type',
    },
    languagesHead: {
        id: scope + 'languagesHead',
        defaultMessage: 'Information about the languages you speak',
    },
    addLanguage: {
        id: scope + 'addLanguage',
        defaultMessage: 'Add language',
    },
    languagePlaceholder: {
        id: scope + 'languagePlaceholder',
        defaultMessage: 'Please select a language',
    },
    languageLevelPlaceholder: {
        id: scope + 'languageLevelPlaceholder',
        defaultMessage: 'Please select a level',
    },
    germanMissing: {
        id: errScope + 'germanMissing',
        defaultMessage: 'German language and level is required',
    },
    englishMissing: {
        id: errScope + 'englishMissing',
        defaultMessage: 'English language and level is required',
    },
    germanLevelMissing: {
        id: errScope + 'germanLevelMissing',
        defaultMessage: 'You have not provided a german language level',
    },
    englishLevelMissing: {
        id: errScope + 'englishLevelMissing',
        defaultMessage: 'You have not provided an English language level',
    },
    langValueIncomplete: {
        id: errScope + 'langValueIncomplete',
        defaultMessage: 'A language entry is not filled in completely',
    },
    noCrossGenderWork: {
        id: errScope + 'noCrossGenderWork',
        defaultMessage: 'Religion or similar requires same sex co-workers',
    },
    changingType: {
        id: scope + 'changingType',
        defaultMessage: 'You are about to change your account type',
    },
    clearingTypeSpecificData: {
        id: scope + 'clearingTypeSpecificData',
        defaultMessage:
            'If you continue you will clear all type specific data, and if you save these changes this will be permanent',
    },
    expectedToc: {
        id: scope + 'toc',
        defaultMessage: 'Expected year of graduation / approbation',
    },
    tocPlaceholder: {
        id: placeholder + 'tocPlaceholder',
        defaultMessage: 'Please select a year',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'Mail',
    },
    viewAgb: {
        id: scope + 'viewAgb',
        defaultMessage: 'View terms and conditions',
    },
    readAGB: {
        id: scope + 'readAGB',
        defaultMessage: 'I have read and agree to the terms and conditions',
    },
    marketingMail: {
        id: scope + 'marketingMail',
        defaultMessage: 'I agree to occasional marketing and update emails',
    },
    acceptAGB: {
        id: scope + 'acceptAGB',
        defaultMessage:
            'You have to confirm that you have read and agree to the terms and conditions',
    },
    agreeDataShare: {
        id: scope + 'agreeDataShare',
        defaultMessage:
            'You have to confirm that we are allowed to share your data anonymously',
    },
    readAGBFirst: {
        id: scope + 'readAGBFirst',
        defaultMessage:
            'You have to read the terms and conditions before you can accept them',
    },
    noNameEdit: {
        id: scope + 'noNameEdit',
        defaultMessage:
            'You are not allowed to change your name at will. Contact us to perform this action',
    },
    resume: {
        id: scope + 'resume',
        defaultMessage: 'Professional history',
    },
    from: {
        id: scope + 'from',
        defaultMessage: 'From',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'To',
    },
    employmentTitle: {
        id: scope + 'employmentTitle',
        defaultMessage: 'Title',
    },
    employmentTitlePlaceholder: {
        id: scope + 'employmentTitlePlaceholder',
        defaultMessage: 'E.g.: Senior Physician',
    },
    employmentType: {
        id: scope + 'employmentType',
        defaultMessage: 'Employment type',
    },
    employmentTypePlaceholder: {
        id: scope + 'employmentTypePlaceholder',
        defaultMessage: 'Please select a type',
    },
    companyName: {
        id: scope + 'companyName',
        defaultMessage: 'Company name',
    },
    companyNamePlaceholder: {
        id: scope + 'companyNamePlaceholder',
        defaultMessage: 'E.g.: St Thomas Hospital',
    },
    location: {
        id: scope + 'location',
        defaultMessage: 'Location',
    },
    locationPlaceholder: {
        id: scope + 'locationPlaceholder',
        defaultMessage: 'E.g.: London, United Kingdom',
    },
    addEmployment: {
        id: scope + 'addEmployment',
        defaultMessage: 'Add employment',
    },
    addEducation: {
        id: scope + 'addEducation',
        defaultMessage: 'Add education',
    },
    degree: {
        id: scope + 'degree',
        defaultMessage: 'Degree',
    },
    degreePlaceholder: {
        id: scope + 'degreePlaceholder',
        defaultMessage: "E.g.: Master 's degree",
    },
    institution: {
        id: scope + 'institution',
        defaultMessage: 'Institution',
    },
    institutionPlaceholder: {
        id: scope + 'institutionPlaceholder',
        defaultMessage: 'E.g.: Harvard University',
    },
    field: {
        id: scope + 'field',
        defaultMessage: 'Field',
    },
    fieldPlaceholder: {
        id: scope + 'fieldPlaceholder',
        defaultMessage: 'E.g.: Neurology',
    },
    employmentHistory: {
        id: scope + 'employmentHistory',
        defaultMessage: 'Employment history',
    },
    educationHistory: {
        id: scope + 'educationHistory',
        defaultMessage: 'Education history',
    },
    finishEditing: {
        id: scope + 'finishEditing',
        defaultMessage: 'Finish editing',
    },
    fillInPreviousEmployments: {
        id: scope + 'fillInPreviousEmployments',
        defaultMessage:
            'Add recent employments to enhance your resume. Try to use the language native to the country you want to work in.',
    },
    fillInEducations: {
        id: scope + 'fillInEducations',
        defaultMessage:
            'Add your education to enhance your resume. Try to use the language native to the country you want to work in.',
    },
    accountActions: {
        id: scope + 'accountActions',
        defaultMessage: 'Account actions',
    },
    accountActionWarning: {
        id: scope + 'accountActionWarning',
        defaultMessage:
            'The account actions modify your account. Use them carefully.',
    },
    deleteAccount: {
        id: scope + 'deleteAccount',
        defaultMessage: 'Delete account',
    },
    issuePasswordReset: {
        id: scope + 'issuePasswordReset',
        defaultMessage: 'Issue password reset',
    },
    passwordResetHintTitle: {
        id: scope + 'passwordResetHintTitle',
        defaultMessage: 'You are about to reset your password',
    },
    passwordResetHintText: {
        id: scope + 'passwordResetHintText',
        defaultMessage:
            'This will send you a link to the e-mail associated with your account. Click the link to set a new password.',
    },
    cancelDeletion: {
        id: scope + 'cancelDeletion',
        defaultMessage: 'Cancel deletion',
    },
    remainingTimeUntilDeletion: {
        id: scope + 'remainingTimeUntilDeletion',
        defaultMessage: 'Remaining time until deletion:',
    },
    deleteAccountWarning: {
        id: scope + 'deleteAccountWarning',
        defaultMessage: 'You are about to delete your Account',
    },
    deleteAccountWarningText: {
        id: scope + 'deleteAccountWarningText',
        defaultMessage:
            'This action will be completed at the first of the next month, 1 month from now',
    },
    showPreview: {
        id: scope + 'showPreview',
        defaultMessage: 'Show preview',
    },
    sendReminder: {
        id: scope + 'sendReminder',
        defaultMessage: 'Send reminder',
    },
    notifications: {
        id: scope + 'notifications',
        defaultMessage: 'Notifications',
    },
    unreadMessages: {
        id: notificationScope + 'unreadMessages',
        defaultMessage: 'Unread messages',
    },
    newJobs: {
        id: notificationScope + 'newJobs',
        defaultMessage: 'New jobs',
    },
    marketingMails: {
        id: notificationScope + 'marketingMails',
        defaultMessage: 'Marketing e-mails',
    },
    profession: {
        id: scope + 'profession',
        defaultMessage: 'Profession',
    },
    professionPlaceholder: {
        id: scope + 'professionPlaceholder',
        defaultMessage: 'Select a profession',
    },
    editInYourProfile: {
        id: scope + 'editInYourProfile',
        defaultMessage: 'Edit this value in your profile',
    },
    documentToUpload: {
        id: scope + 'documentToUpload',
        defaultMessage: 'Document to upload',
    },
    availabilities: {
        id: scope + 'availabilities',
        defaultMessage: 'Availabilities',
    },
    availableFromDescription: {
        id: scope + 'availableFromDescription',
        defaultMessage:
            'You can set available from to indicate a date where you will become available for jobs. Until this point you will not be notified of new jobs that match your profile.',
    },
    availableFrom: {
        id: scope + 'availableFrom',
        defaultMessage: 'Available from:',
    },
    explicitAvailabilitiesDescription: {
        id: scope + 'explicitAvailabilitiesDescription',
        defaultMessage:
            'You can set availabilities in the calendar to indicate time periods where you are available and actively looking for work. This information will be used by our agents to look for jobs matching your profile around that time.',
    },
    explicitAvailabilities: {
        id: scope + 'explicitAvailabilities',
        defaultMessage: 'Explicit Availabilities',
    },
    noExplicitAvailabilities: {
        id: scope + 'noExplicitAvailabilities',
        defaultMessage: 'There are no availabilities.',
    },
    openCalendar: {
        id: scope + 'openCalendar',
        defaultMessage: 'Open calendar to configure availabilities',
    },
    week: {
        id: scope + 'week',
        defaultMessage: 'Week',
    },
    year: {
        id: scope + 'year',
        defaultMessage: 'Year',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
    days: {
        id: scope + 'days',
        defaultMessage: 'Days',
    },
    times: {
        id: scope + 'times',
        defaultMessage: 'Times',
    },
    repeats: {
        id: scope + 'repeats',
        defaultMessage: 'Repeats',
    },
    maxTravelDistance: {
        id: scope + 'maxTravelDistance',
        defaultMessage: 'Your maximum travel distance',
    },
    maxTravelDistancePlaceholder: {
        id: scope + 'maxTravelDistancePlaceholder',
        defaultMessage: 'Your maximum travel distance in km',
    },
    agency: {
        id: scope + 'agency',
        defaultMessage: 'Agency name',
    },
    noEducation: {
        id: scope + 'noEducation',
        defaultMessage: 'You must select an education',
    },
    workplaces: {
        id: scope + 'workplaces',
        defaultMessage: 'Workplaces',
    },
    agencies: {
        id: scope + 'agencies',
        defaultMessage: 'Agencies',
    },
    goToWorkplaces: {
        id: scope + 'goToWorkplaces',
        defaultMessage: 'Go to workplaces',
    },
    goToAgencies: {
        id: scope + 'goToAgencies',
        defaultMessage: 'Go to agencies',
    },
    setup2FA: {
        id: scope + 'setup2FA',
        defaultMessage: 'Setup two-factor authentication',
    },
    verificationCode: {
        id: scope + 'verificationCode',
        defaultMessage: 'Verification code',
    },
    requestVerificationCode: {
        id: scope + 'requestVerificationCode',
        defaultMessage: 'Request verification code',
    },
    confirmVerificationCode: {
        id: scope + 'confirmVerificationCode',
        defaultMessage: 'Confirm verification code',
    },
    supportId: {
        id: scope + 'supportId',
        defaultMessage: 'Your support ID:',
    },
    youHaveAvalidAndActiveIdDoc: {
        id: scope + 'youHaveAvalidAndActiveIdDoc',
        defaultMessage:
            'You have an reviewed, valid and not expired identification document. Together with multi factor authentication will this enable you to use digial signatures.',
    },
    identitySection: {
        id: scope + 'identitySection',
        defaultMessage: 'Multi-factor authentication and signing',
    },
    professionalHistory: {
        id: scope + 'professionalHistory',
        defaultMessage: 'Professional History',
    },
    restrictiveFields: {
        id: scope + 'restrictiveFields',
        defaultMessage: 'Fields of employment to display in webapp.',
    },
    restrictiveFieldsPlaceholder: {
        id: scope + 'restrictiveFieldsPlaceholder',
        defaultMessage:
            'Select fields to reduce information overflow and streamline your experience',
    },
    agencyId: {
        id: scope + 'agencyId',
        defaultMessage: 'Your agency ID:',
    },
    salutationMale: {
        id: scope + 'salutationMale',
        defaultMessage: 'Mr.',
    },
    salutationFemale: {
        id: scope + 'salutationFemale',
        defaultMessage: 'Mrs.',
    },
    employType: {
        id: scope + 'employType',
        defaultMessage: 'Preffered Employment Type',
    },
    confirmDeleteFile: {
        id: scope + 'confirmDeleteFile',
        defaultMessage: 'Are you sure you want to delete this file?',
    },
    confirmDeleteFileMessage: {
        id: scope + 'confirmDeleteFileMessage',
        defaultMessage:
            'This action will remove it from this workplace and no user will be able to access this original reference in the future',
    },
    driversLicense: {
        id: scope + 'driversLicense',
        defaultMessage: 'Drivers license',
    },
    noDriversLicense: {
        id: scope + 'noDriversLicense',
        defaultMessage: 'No drivers license',
    },
    hasVehicle: {
        id: scope + 'hasVehicle',
        defaultMessage: 'Own vehicle',
    },
    availableHours: {
        id: scope + 'availableHours',
        defaultMessage: 'Available work hours per week',
    },
    yearlyAvailableVacation: {
        id: scope + 'yearlyAvailableVacation',
        defaultMessage: 'Yearly vacation days',
    },
    digicert: {
        id: scope + 'digicert',
        defaultMessage: 'Digital certification',
    },
    mfa: {
        id: scope + 'mfa',
        defaultMessage: 'Multifactor authentification',
    },
    mfaHint: {
        id: scope + 'mfaHint',
        defaultMessage:
            'Your phone number will be contacted each time you log in and when you want to apply a qualified electronic signature (qes). Please make sure only you receive SMS for that number.',
    },
    mfaCertified: {
        id: scope + 'mfaCertified',
        defaultMessage: 'Your multifactor authentification is active!',
    },
    qes: {
        id: scope + 'qes',
        defaultMessage: 'Qualified electronic signatures',
    },
    qesHint: {
        id: scope + 'qesHint',
        defaultMessage:
            'To sign documents on {platform} digital and qualified, you require available qes signatures. With one signature, you can sign one "envelope". An envelope may contain an unlimited amount of documents. They will be bundled together, and signed digitally and qualified. This makes the signature equal to a physical signature.',
    },
    availableQes: {
        id: scope + 'availableQes',
        defaultMessage: 'Available QES Signatures',
    },
    orderQes: {
        id: scope + 'orderQes',
        defaultMessage: 'Order more qes',
    },
    sick: {
        id: scope + 'sick',
        defaultMessage: 'Sick',
    },
});
