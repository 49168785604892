import React, { FC } from 'react';
import { doctorTitleMessages } from '../../utilities/messages';
import { Radio } from './Radio';
import { useFormat } from '../../utilities/intl';
import { EDoctorTitle } from '../../enums';

export const DoctorRadio: FC<{
    title?: EDoctorTitle;
    onChange: (next: EDoctorTitle) => void;
}> = ({ title, onChange }) => {
    const format = useFormat();
    return (
        <Radio
            onChange={onChange}
            value={title}
            values={[
                {
                    label: format(doctorTitleMessages.noTitle),
                    value: undefined,
                },
                {
                    label: format(doctorTitleMessages.doctor),
                    value: EDoctorTitle.doctor,
                },
                {
                    label: format(doctorTitleMessages.professor),
                    value: EDoctorTitle.professor,
                },
                {
                    label: format(doctorTitleMessages.professordoctor),
                    value: EDoctorTitle.professordoctor,
                },
                // ...Object.keys(doctorTitleMessages).map((v) => {
                //     const parsedV =
                //         v as keyof typeof doctorTitleMessages;
                //     return {
                //         label: format(
                //             doctorTitleMessages[v as EDoctorTitle],
                //         ),
                //         value:
                //             parsedV !== 'noTitle' ? v : undefined,
                //     };
                // }),
            ]}
            horizontal
            cy={'title-radio'}
        />
    );
};
