import { defineMessages } from 'react-intl';

const scope = 'con.timesheet.';
const leaveScope = scope + 'leaveType.';
const typeScope = scope + 'shiftType.';

export const timesheetMessages = defineMessages({
    from: {
        id: scope + 'from',
        defaultMessage: 'From',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'To',
    },
    breaks: {
        id: scope + 'breaks',
        defaultMessage: 'Breaks',
    },
    date: {
        id: scope + 'date',
        defaultMessage: 'Date',
    },
    addShift: {
        id: scope + 'addShift',
        defaultMessage: 'Add shift',
    },
    saveAndComplete: {
        id: scope + 'saveAndComplete',
        defaultMessage: 'Save and complete',
    },
    confirmComplete: {
        id: scope + 'confirmComplete',
        defaultMessage: 'Please confirm completion',
    },
    confirmCompleteText: {
        id: scope + 'confirmCompleteText',
        defaultMessage: 'You confirm, that all times are correct.',
    },
    timesheet: {
        id: scope + 'timesheet',
        defaultMessage: 'Timesheet',
    },
    timesheetList: {
        id: scope + 'timesheetList',
        defaultMessage: 'Timesheets',
    },
    noBreaks: {
        id: scope + 'noBreaks',
        defaultMessage: 'No breaks for this period',
    },
    addBreak: {
        id: scope + 'addBreak',
        defaultMessage: 'Add break',
    },
    createTimesheetDocument: {
        id: scope + 'createTimesheetDocument',
        defaultMessage: 'Create pdf',
    },
    saveWithCustomDocument: {
        id: scope + 'saveWithCustomDocument',
        defaultMessage: 'Save and upload timesheet file',
    },
    secondarySigneeMail: {
        id: scope + 'secondarySigneeMail',
        defaultMessage: 'Secondary signee mail',
    },
    secondarySigneeMailTitle: {
        id: scope + 'secondarySigneeMailTitle',
        defaultMessage: 'Input a secondary signee mail',
    },
    secondarySigneeMailText: {
        id: scope + 'secondarySigneeMailText',
        defaultMessage:
            'Input a secondary signee mail, to expose the signature of this document to the validating authority.',
    },
    sendMail: {
        id: scope + 'sendMail',
        defaultMessage: 'Send mail',
    },
    showQrCode: {
        id: scope + 'showQrCode',
        defaultMessage: 'Show qr code',
    },
    skipSecondary: {
        id: scope + 'skipSecondary',
        defaultMessage: 'Skip',
    },
    expose: {
        id: scope + 'expose',
        defaultMessage: 'Expose timesheet for employee',
    },
    enterEmployeeMailDesc: {
        id: scope + 'enterEmployeeMailDesc',
        defaultMessage:
            'The employee will receive a mail containing the link to edit and sign the timesheet',
    },
    enterEmployeeMail: {
        id: scope + 'enterEmployeeMail',
        defaultMessage: 'Enter mail address of employee',
    },
    deleteRow: {
        id: scope + 'deleteRow',
        defaultMessage: 'Delete row',
    },
    interval: {
        id: scope + 'interval',
        defaultMessage: 'Period',
    },
    intervalMonthly: {
        id: scope + 'intervalMonthly',
        defaultMessage: 'Monthly',
    },
    intervalWeekly: {
        id: scope + 'intervalWeekly',
        defaultMessage: 'Weekly',
    },
    intervalUndefined: {
        id: scope + 'intervalUndefined',
        defaultMessage: 'Unspecified',
    },
    createSignedDocument: {
        id: scope + 'createSignedDocument',
        defaultMessage: 'Save and sign',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
    reateNewTimesheet: {
        id: scope + 'reateNewTimesheet',
        defaultMessage: 'Create new timesheet',
    },
    noTimesheets: {
        id: scope + 'noTimesheets',
        defaultMessage: 'Currently there is no timesheet for this contract.',
    },
    exposeSuccess: {
        id: scope + 'exposeSuccess',
        defaultMessage: 'Timesheet exposed to talent',
    },
    exposeSuccessMessage: {
        id: scope + 'exposeSuccessMessage',
        defaultMessage:
            'The timesheet was shared with your talent. They will receive a mail at {mail} containing a link to confirm the values or modify them. They will sign the timesheet afterwards. If there is a contact person on location other than the contract manager to sign for the other party they can send a follow up signature request to any other mail or share a link with a qr code.',
    },
    timesheetFilledOn: {
        id: scope + 'timesheetFilledOn',
        defaultMessage: 'Filled on:',
    },
    exposedToTalent: {
        id: scope + 'exposedToTalent',
        defaultMessage: 'Exposed to talent mail:',
    },
    exposedOn: {
        id: scope + 'exposedOn',
        defaultMessage: 'Exposed on:',
    },
    secondaryMail: {
        id: scope + 'secondaryMail',
        defaultMessage: 'Secondary signature shared to:',
    },
    leaveReason: {
        id: scope + 'leaveReason',
        defaultMessage: 'Leave reason',
    },
    leaveReasonPlaceholder: {
        id: scope + 'leaveReasonPlaceholder',
        defaultMessage: 'In case of absence select a reason',
    },
    didLeave: {
        id: scope + 'didLeave',
        defaultMessage: 'Sick shift',
    },
    none: {
        id: leaveScope + 'none',
        defaultMessage: 'None',
    },
    sick: {
        id: leaveScope + 'sick',
        defaultMessage: 'Sick',
    },
    vacation: {
        id: leaveScope + 'vacation',
        defaultMessage: 'Vacation',
    },
    holidayLeave: {
        id: leaveScope + 'holidayLeave',
        defaultMessage: 'Holiday',
    },
    free: {
        id: leaveScope + 'free',
        defaultMessage: 'Free shift',
    },
    unexcused: {
        id: leaveScope + 'unexcused',
        defaultMessage: 'Unexcused',
    },
    compensation: {
        id: leaveScope + 'compensation',
        defaultMessage: 'Free time compensation',
    },
    otherContract: {
        id: leaveScope + 'otherContract',
        defaultMessage: 'Other contract',
    },
    other: {
        id: leaveScope + 'other',
        defaultMessage: 'Other',
    },
    regular: {
        id: typeScope + 'regular',
        defaultMessage: 'Regular',
    },
    night: {
        id: typeScope + 'night',
        defaultMessage: 'Nightshift',
    },
    holiday: {
        id: typeScope + 'holiday',
        defaultMessage: 'Holiday',
    },
    shiftType: {
        id: scope + 'shiftType',
        defaultMessage: 'Shift type',
    },
    netSum: {
        id: scope + 'netSum',
        defaultMessage: 'Net sum',
    },
    sickNetSum: {
        id: scope + 'sickNetSum',
        defaultMessage: 'Net sum absent',
    },
    deleteTimesheet: {
        id: scope + 'deleteTimesheet',
        defaultMessage: 'Delete timesheet',
    },
    deleteTimesheetMessage: {
        id: scope + 'deleteTimesheetMessage',
        defaultMessage:
            'Please confirm the deletion of this timesheet. It will render all generated exposition urls invalid!',
    },
    deleteTimesheetSigned: {
        id: scope + 'deleteTimesheetSigned',
        defaultMessage:
            'The timesheet was signed by you talent. Are you shure you want to delete it?',
    },
    deleteTimesheetMessageSigned: {
        id: scope + 'deleteTimesheetMessageSigned',
        defaultMessage:
            'This will render all generated exposition urls invalid and remove the timesheet file and envelope from your contract!',
    },
    sickDays: {
        id: scope + 'sickDays',
        defaultMessage: 'Sick days',
    },
    vacationDays: {
        id: scope + 'vacationDays',
        defaultMessage: 'Vacation days',
    },
    saveRow: {
        id: scope + 'saveRow',
        defaultMessage: 'Save row',
    },
    noFile: {
        id: scope + 'noFile',
        defaultMessage: 'No file selected',
    },
    openTimesheets: {
        id: scope + 'openTimesheets',
        defaultMessage: 'Open timesheets',
    },
    signedTimesheets: {
        id: scope + 'signedTimesheets',
        defaultMessage: 'Signed timesheets',
    },
    warnCustomUpload: {
        id: scope + 'warnCustomUpload',
        defaultMessage: 'Attention!!',
    },
    warnCustomUploadMessage: {
        id: scope + 'warnCustomUploadMessage',
        defaultMessage:
            'Confirm that all hours and absences are registered correctly\n\nIf the hours on the uploaded document differ all future calculations will become severely flawed.',
    },
    customUpload: {
        id: scope + 'customUpload',
        defaultMessage: 'Everything is correct. Upload file',
    },
    abortCustomUpload: {
        id: scope + 'abortCustomUpload',
        defaultMessage: 'Edit values',
    },
});
