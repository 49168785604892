import { useContext, useEffect } from 'react';
import { ProfileContext } from '../../../../../Profile/Profile.context';
import { MUserFile } from '../../../../../../models';
import { EEmployeeFiles } from '../../../../../../enums';
/**
 * effect to set default file requests on talent with no files yet
 */
export const useDefaultFileRequests = () => {
    const { prevTalentData, onChangeTalentData } = useContext(ProfileContext);
    /**
     * effect to set default file requests
     */
    useEffect(() => {
        if (!onChangeTalentData || !prevTalentData) return;
        const defaultRequests = [
            new MUserFile({ type: EEmployeeFiles.resume, request: true }),
        ];

        const unresolvedRequests = defaultRequests.filter(
            (r) => !prevTalentData.files.find((f) => f.type === r.type),
        );
        onChangeTalentData({
            files: [...prevTalentData.files, ...unresolvedRequests],
        });
    }, [prevTalentData, onChangeTalentData]);
};
