import React, { FC, useContext } from 'react';
import { CText } from '../../../../components';
import { profProfileMessages } from '../../ProfProfile.messages';
import { Rates } from '../../../../components/Wage/Rates';
import { ProfileContext } from '../../../Profile/Profile.context';
import { useStyle } from '../../../../utilities/styles';
import { View } from 'react-native';

export const ProfessionalWage: FC = () => {
    const style = useStyle();
    const { curTalentData, curValidity, onChangeTalentData } =
        useContext(ProfileContext);
    return (
        <View>
            <CText secondaryHeadline message={profProfileMessages.rate} />
            <CText message={profProfileMessages.rateHint} />
            {curTalentData && onChangeTalentData && (
                <Rates
                    curData={curTalentData.wage}
                    onChange={(wage) => onChangeTalentData({ wage })}
                />
            )}
            {!!curValidity.wage && (
                <CText style={style.error} message={curValidity.wage} />
            )}
        </View>
    );
};
