import React, { FC, useCallback, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCard,
    CCheckBox,
    CPicker,
    CText,
} from '../../../../components';
import { contractMessages } from '../../contract.messages';
import { MContract } from '../../../../models';
import { ETimesheetType } from '../../../../enums/ETImesheetType';
import { useStyle } from '../../../../utilities/styles';
import { useFireBase } from '../../../../utilities/firebase';
import { useLock } from '../../../../utilities/hooks';
import { isPeasant, isSuperUser } from '../../../../utilities/auth';
import { TimesheetList } from './TimesheetList';
import { useSecureNavigate } from '../../../../utilities/routing';
import { timesheetMessages } from '../../../Timesheet/timesheet.messages';

export const TimesheetSettings: FC<{
    contract: MContract;
    issueReload: () => void;
    maxHeight?: number;
}> = ({ contract, issueReload, maxHeight }) => {
    const style = useStyle();
    const { lock } = useLock();
    const { callFunction, userData } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const [headerHeight, setHeaderHeight] = useState(0);
    /**
     * callback to handle timesheet type changes && alert changes
     */
    const onChange = useCallback(
        async (timesheetType: ETimesheetType, alerts?: boolean) => {
            const unlock = await lock();
            try {
                await callFunction('updateContractSettings', {
                    contractId: contract.documentId,
                    timesheetType,
                    timesheetAlerts: alerts,
                });
                issueReload();
                unlock();
            } catch (e) {
                unlock();
            }
        },
        [contract, lock, issueReload],
    );
    /**
     * render
     */
    return (
        <View style={{ maxHeight: maxHeight ? maxHeight : undefined }}>
            <CCard
                onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
            >
                {isPeasant(userData) || isSuperUser(userData) ? (
                    <CPicker
                        title={contractMessages.timesheetInterval}
                        values={[
                            {
                                value: ETimesheetType.montly,
                                label: contractMessages.timesheetIntervalMonthly,
                            },
                            {
                                value: ETimesheetType.weekly,
                                label: contractMessages.timesheetIntervalWeekly,
                            },
                            {
                                value: ETimesheetType.undefined,
                                label: contractMessages.timesheetIntervalUndefined,
                            },
                        ]}
                        value={contract.timesheetType}
                        onChange={(next) => {
                            onChange(next, contract.timesheetAlerts);
                        }}
                    />
                ) : (
                    [ETimesheetType.montly, ETimesheetType.weekly].includes(
                        contract.timesheetType,
                    ) && (
                        <View>
                            <CText
                                message={contractMessages.timesheetInterval}
                            />
                            {contract.timesheetType ===
                                ETimesheetType.montly && (
                                <CText
                                    message={
                                        contractMessages.timesheetIntervalMonthly
                                    }
                                />
                            )}
                            {contract.timesheetType ===
                                ETimesheetType.weekly && (
                                <CText
                                    message={
                                        contractMessages.timesheetIntervalWeekly
                                    }
                                />
                            )}
                        </View>
                    )
                )}
                {(isPeasant(userData) || isSuperUser(userData)) && (
                    <CCheckBox
                        title={contractMessages.timesheetAlerts}
                        checked={contract.timesheetAlerts}
                        onCheckedChanged={(c) =>
                            onChange(contract.timesheetType, c)
                        }
                    />
                )}
                <CButton
                    cy={'new-timesheet'}
                    onPress={async () => {
                        secureNavigate(
                            `/contract/${contract.documentId}/newTimesheet`,
                        );
                    }}
                    title={timesheetMessages.reateNewTimesheet}
                    icon={'plus'}
                />
            </CCard>
            <View
                style={[
                    style.verticalHeavyPadded,
                    {
                        maxHeight: maxHeight ? maxHeight - headerHeight : 0,
                    },
                ]}
            >
                <TimesheetList
                    contract={contract}
                    maxHeight={maxHeight && maxHeight - headerHeight}
                />
            </View>
        </View>
    );
};
