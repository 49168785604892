import React, { FC, useContext, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { CPicker, CText, OutOfContext } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { onboardingMessages } from '../Onboarding.messages';
import { ProfileContext } from '../../Profile/Profile.context';
import { useEnvironment } from '../../../utilities/contexts';

export const HowDidYouFindOut: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const {
        onChange,
        curData,
        curUserData,
        onChangeUserData,
        onValidityChange,
    } = useContext(ProfileContext);
    const { environment } = useEnvironment();

    const validity = useMemo(() => {
        if (curUserData?.howDidYouFindOut) {
            return '';
        } else {
            return format(generalMessages.pleaseSelect);
        }
    }, [curData]);

    useEffect(() => onValidityChange && onValidityChange(validity), [validity]);
    /**
     * check if context is correct
     */
    if (!curUserData || !onChangeUserData) {
        return <OutOfContext component="HowDidYouFindOut" />;
    }
    /**
     * render
     */
    return (
        <View style={style.verticalPadded}>
            <CPicker
                cy={'howDidYouFindOut'}
                placeholder={format(generalMessages.pleaseSelect)}
                title={format(onboardingMessages.howDidYouFindOut, {
                    platform: environment,
                })}
                onChange={(e) => onChangeUserData({ howDidYouFindOut: e })}
                values={[
                    {
                        value: 'google',
                        label: format(onboardingMessages.google),
                    },
                    {
                        value: 'linkedin',
                        label: format(onboardingMessages.linkedin),
                    },
                    {
                        value: 'indeed',
                        label: format(onboardingMessages.indeed),
                    },
                    {
                        value: 'friendsAndFamily',
                        label: format(onboardingMessages.friendsAndFamily),
                    },
                ]}
                value={curUserData?.howDidYouFindOut}
                validity={validity}
            />
        </View>
    );
};
