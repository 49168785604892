import React, { FC, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CIcon, CText } from '../../../../components';
import { MEmployment } from '../../../../models';
import { useFormat } from '../../../../utilities/intl';
import { employmentTypeMessages } from '../../../../utilities/messages/employmentType.messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { EmploymentEdit } from './EmploymentEdit';
import { monthMessages } from '../../../../utilities/messages';
import { keyof } from '../../../../utilities/functions';

export interface IEmploymentProps {
    onChange: (next: Partial<MEmployment>) => void;
    employment: MEmployment;
    zIndex: number;
    removeSelf: () => void;
    fresh: boolean;
}

export const EmploymentView: FC<IEmploymentProps> = ({
    employment,
    onChange,
    zIndex,
    removeSelf,
    fresh,
}) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const [edit, setEdit] = useState(fresh || false);

    const months = useMemo(() => {
        const monthKeys = keyof(monthMessages).filter(
            (m) => !m.includes('Short'),
        );

        return monthKeys.map((k) => monthMessages[k]);
    }, []);

    if (edit) {
        return (
            <EmploymentEdit
                employment={employment}
                onChange={onChange}
                endEdit={() => setEdit(false)}
                zIndex={zIndex}
                removeSelf={removeSelf}
                fresh={fresh}
            />
        );
    }
    return (
        <View style={[style.horizontalSplit, style.verticalPadded]}>
            <View style={[style.horizontal, style.centeredItems]}>
                <View
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: theme.accentColor,
                    }}
                />
                <View style={style.leftPadded}>
                    <CText headline>{employment.title}</CText>
                    {employment.type && (
                        <CText>
                            {format(employmentTypeMessages[employment.type])}
                        </CText>
                    )}
                    <CText>
                        {employment.company} - {employment.location}
                    </CText>
                    <View style={[style.horizontal]}>
                        <CIcon icon="calendar_outline" size={16} />
                        <CText style={style.leftPadded}>
                            {`${format(
                                months[new Date(employment.from).getMonth()],
                            )} ${new Date(
                                employment.from,
                            ).getFullYear()} - ${format(
                                months[new Date(employment.to).getMonth()],
                            )} ${new Date(employment.to).getFullYear()}`}
                        </CText>
                    </View>
                </View>
            </View>
            <View style={[style.horizontal, { marginVertical: 'auto' }]}>
                <CButton
                    transparent
                    icon={'close'}
                    iconColor={theme.errorColor}
                    onPress={removeSelf}
                />
                <CButton
                    transparent
                    icon={'cog'}
                    onPress={() => {
                        setEdit(true);
                    }}
                />
            </View>
        </View>
    );
};
