import React, { FC, useContext, useMemo, useState } from 'react';
import { FileInput, Spinner, TabButtons } from '../../../../components';
import {
    EProfession,
    EDoctorFiles,
    EGeneralFiles,
    ECollections,
    EEmployeeFiles,
    ESocialWorkerFiles,
    EFile,
    EPrivateTalentFiles,
} from '../../../../enums';
import { ProfileContext } from '../../../Profile/Profile.context';
import { MAgency, MUserFile } from '../../../../models';
import { View } from 'react-native';
import { profProfileMessages } from '../../ProfProfile.messages';
/**
 * view to display documents and upload
 * @param param0
 * @returns
 */
export const ProfessionalDocuments: FC<{
    agency: MAgency;
}> = ({ agency }) => {
    const { curData, onChange, onChangeTalentData } =
        useContext(ProfileContext);
    const [mode, setMode] = useState('public');
    /**
     * memoized list of accessible documents
     */
    const docs = useMemo(() => {
        const generalFiles = Object.values(EGeneralFiles);
        const employeeFiles = Object.values(EEmployeeFiles);
        const next: EFile[] = [...generalFiles, ...employeeFiles];
        if (curData.profession === EProfession.doctor) {
            next.push(...Object.values(EDoctorFiles));
        } else if (curData.profession === EProfession.nurse) {
            // * No nurse files
        } else if (curData.profession === EProfession.socialWorker) {
            next.push(...Object.values(ESocialWorkerFiles));
        }
        if (mode === 'private') {
            next.push(...Object.values(EPrivateTalentFiles));
        }
        return next;
    }, [curData, mode]);
    /**
     * return spinner to prevent invalid usage
     */
    if (!curData.documentId) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <View>
            <TabButtons
                values={[
                    {
                        value: 'public',
                        label: profProfileMessages.publicFiles,
                        icon: 'public',
                    },
                    {
                        value: 'private',
                        label: profProfileMessages.privateFiles,
                        icon: 'private',
                    },
                ]}
                value={mode}
                onChange={setMode}
            />
            {mode === 'public' ? (
                <FileInput
                    collection={`${ECollections.profProfiles}/${curData.documentId}/${ECollections.files}`}
                    directory={`${ECollections.agencies}/${agency.documentId}/profiles/${curData.documentId}`}
                    fileTypes={docs as EFile[]}
                    defaultType={EEmployeeFiles.resume}
                    legacyFiles={curData.files}
                    clearLegacyFiles={() => onChange({ files: [] })}
                    onFilelistChange={
                        onChangeTalentData &&
                        ((files) =>
                            onChangeTalentData({ files: files as MUserFile[] }))
                    }
                />
            ) : (
                <FileInput
                    collection={`${ECollections.profProfiles}/${curData.documentId}/${ECollections.privateFiles}`}
                    directory={`${ECollections.agencies}/${agency.documentId}/profiles/${curData.documentId}`}
                    fileTypes={docs as EFile[]}
                    defaultType={EEmployeeFiles.resume}
                    onFilelistChange={
                        onChangeTalentData &&
                        ((files) =>
                            onChangeTalentData({
                                privateFiles: files as MUserFile[],
                            }))
                    }
                    private
                />
            )}
        </View>
    );
};
