import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCard, CImage, CText, Spinner } from '../../../components';
import { MAgency, MProfessionalProfile } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import {
    IProfessionalUsersFilter,
    ProfessionalUsersFilterBar,
} from './components/ProfessionalUserFilterBar';
import { ECollections } from '../../../enums';
import { IFilter } from '../../../utilities/firebase/store';
import { ProfessionalUserRow } from './components/ProfessionalUserRow';
import { actionMessages } from '../../../utilities/messages';
import { userMessages } from '../../User/user.messages';
import { useNavigate } from 'react-router-native';
import { isSuperUser } from '../../../utilities/auth';
import { profProfileMessages } from '../ProfProfile.messages';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { ProfessionalProfileInviteSection } from './components/ProfessionalProfileInviteSection';
import { ETalentState } from '../../../enums/ETalentState';
/**
 * ProfessionalUsersList (MProfessionalInformation unrelated to specific user (created by agency))
 * @returns
 */
export const ProfProfileList: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { getDataIndex, userData, getLength, userAgencies } = useFireBase();
    // local state
    const [agencies, setAgencies] = useState<MAgency[]>([]);
    const [professionalUsers, setUsers] = useState<MProfessionalProfile[]>([]);
    const [totalLength, setTotalLength] = useState(0);
    const [currentUsersLength, setCurrUsersLength] = useState(0);
    const [filterValues, setFilter] = useState<IProfessionalUsersFilter>({});
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [endReached, setEndReached] = useState(false);
    /**
     * effect to load agencies
     */
    useEffect(() => {
        setAgencies(userAgencies);
    }, [userAgencies]);
    /**
     * load number of talents
     * TODO: accurately load length for current user
     */
    useEffect(() => {
        if (isSuperUser(userData)) {
            getLength('professionalUsers').then((res) => {
                setTotalLength(res as number);
            });
        } else if (agencies.length) {
            getDataIndex(ECollections.profProfiles, {
                getLength: true,
                filter: [
                    {
                        field: 'agencyId',
                        value: agencies.map((a) => a.documentId),
                        operator: 'in',
                    },
                    {
                        field: 'temp',
                        value: false,
                    },
                ],
            }).then((res) => {
                setTotalLength(res as number);
            });
        }
    }, [userData, agencies]);
    /**
     * load users
     */
    const loadMore = useCallback(
        async (offset?: MProfessionalProfile) => {
            const {
                education,
                customStringFilter,
                field,
                profession,
                state,
                internalRating,
            } = filterValues;
            const filter: IFilter[] = [
                {
                    field: 'temp',
                    value: false,
                },
                { field: 'disabled', value: false },
            ];
            let partialMatch: { field: string; value: string } | undefined =
                undefined;
            const orderBy: string | undefined = undefined;
            let asc = false;
            /**
             * assign equality filters
             */
            if (education) {
                filter.push({
                    field: 'educations',
                    value: education,
                    operator: 'array-contains',
                });
            } else if (profession) {
                filter.push({
                    field: 'profession',
                    value: profession,
                });
            } else if (field) {
                filter.push({
                    field: 'field',
                    value: field,
                });
            }
            if (!isSuperUser(userData)) {
                filter.push({
                    field: 'agencyId',
                    value: agencies.map((a) => a.documentId),
                    operator: 'in',
                });
            }
            if (
                state === ETalentState.activeContract ||
                state === ETalentState.terminated ||
                state === ETalentState.willTerminate
            ) {
                filter.push({ field: 'state', value: state });
            } else if (state === ETalentState.onboarding) {
                filter.push({
                    field: 'state',
                    operator: 'in',
                    value: [
                        ETalentState.invite,
                        ETalentState.onboarding,
                        ETalentState.application,
                    ],
                });
            }
            if (internalRating !== undefined) {
                filter.push({ field: 'internalRating', value: internalRating });
            }
            /**
             * set inequality filters (they can not run parallel)
             */
            if (
                customStringFilter &&
                customStringFilter.field &&
                customStringFilter.value
            ) {
                const { field, value } = customStringFilter;
                partialMatch = { field, value };
                asc = true;
            }
            /**
             * reduce filter in case partialMatching is active
             */
            const reducedFilter = filter.filter(
                (v) => !partialMatch || v.field === 'agencyId',
            );
            /**
             * get length if no offset
             * default to est total length if no filter
             */
            if (!offset && (filter.length || partialMatch)) {
                getDataIndex(ECollections.profProfiles, {
                    filter: reducedFilter,
                    partialMatch,
                    getLength: true,
                }).then((res) => {
                    setCurrUsersLength(res as number);
                });
            } else if (!offset) {
                setCurrUsersLength(totalLength);
            }
            /**
             * query
             */
            getDataIndex(ECollections.profProfiles, {
                filter: reducedFilter,
                limit: 10,
                orderBy,
                asc,
                startDocumentId: offset ? offset.documentId : undefined,
                partialMatch,
            }).then((result) => {
                const next = (result as MProfessionalProfile[]).map(
                    (r) => new MProfessionalProfile(r),
                );
                if (!offset) {
                    setUsers(next);
                    setLoading(false);
                    setEndReached(false);
                } else {
                    setUsers((current) => {
                        setLoadingMore(false);
                        return [...current, ...next];
                    });
                    if (next.length === 0) {
                        setEndReached(true);
                    }
                }
            });
        },
        [userData, filterValues, agencies, totalLength],
    );
    /**
     * change index
     */
    const handleChange = useCallback(
        (next: MProfessionalProfile) => {
            setUsers((prev) => {
                const index = prev.findIndex(
                    (u) => u.documentId === next.documentId,
                );
                prev[index] = next;
                return Array.from(prev);
            });
        },
        [setUsers],
    );
    /**
     * effect to trigger reload
     */
    useEffect(() => {
        setLoading(true);
        loadMore();
    }, [loadMore]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard>
                <View style={style.horizontalSplit}>
                    <CText
                        message={generalMessages.professionalProfiles}
                        style={style.verticalPadded}
                        bold
                        headline
                    />
                    <CButton
                        cy={'add-professional-user'}
                        onPress={() => navigate('/profUser/new')}
                        icon={'plus'}
                    />
                </View>
                <CText
                    message={profProfileMessages.profProfileExplanation}
                    style={style.verticalPadded}
                />
                <View style={[style.horizontalSpaced, style.verticalPadded]}>
                    <View style={{ width: '50%' }}>
                        <CButton
                            cy={'add-professional-user'}
                            onPress={() => navigate('/profUser/new')}
                            icon={'plus'}
                            title={profProfileMessages.createNewProfile}
                        />
                    </View>
                </View>
                {!isSuperUser(userData) && <ProfessionalProfileInviteSection />}
                {!isSuperUser(userData) && (
                    <CText
                        style={style.verticalPadded}
                        message={profProfileMessages.csvHint}
                    />
                )}
            </CCard>
            <CCard>
                <View style={{ paddingBottom: 10 }}>
                    <CText
                        message={profProfileMessages.yourProfProfiles}
                        style={style.verticalPadded}
                        bold
                        headline
                    />
                </View>
                <ProfessionalUsersFilterBar onChange={setFilter} />
            </CCard>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {!!totalLength && (
                        <View style={style.horizontalSpaced}>
                            <CText>
                                {format(userMessages.searchMatchesXofY, {
                                    y: totalLength,
                                    x: currentUsersLength,
                                })}
                            </CText>
                        </View>
                    )}
                    {professionalUsers.map((profile) => (
                        <ProfessionalUserRow
                            key={profile.documentId}
                            professionalUser={profile}
                            handleChange={handleChange}
                        />
                    ))}
                    {!professionalUsers.length && (
                        <CCard>
                            <CImage image={'profProfiles'} />
                            <View style={style.horizontalSpaced}>
                                <CText
                                    message={profProfileMessages.noProfiles}
                                    headline
                                    centered
                                />
                            </View>
                            <View style={style.horizontalSpaced}>
                                <CButton
                                    onPress={() => navigate('/profUser/new')}
                                    icon={'plus'}
                                    title={profProfileMessages.createNewProfile}
                                />
                            </View>
                        </CCard>
                    )}
                    {loadingMore ? (
                        <Spinner />
                    ) : (
                        !!professionalUsers.length &&
                        professionalUsers.length % 10 === 0 &&
                        !endReached && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(
                                            professionalUsers[
                                                professionalUsers.length - 1
                                            ],
                                        );
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                </>
            )}
        </ScrollProvider>
    );
};
