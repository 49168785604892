import React, { FC, useState } from 'react';
import { Linking, View } from 'react-native';
import { MFile } from '../../models';
import { useFireBase } from '../../utilities/firebase';
import { filenameMessages, generalMessages } from '../../utilities/messages';
import { useStyle, useTheme } from '../../utilities/styles';
import { DocumentPreview } from '../DocumentPreview';
import { CText, CButton, CDatePicker, CIcon, CCheckBox } from '../elements';
import { getFileColor } from '../../utilities/constants';
import { TouchableView } from '../TouchableView';
import { fileInputMessages } from '../Files/fileInput.messages';
/**
 * document display in user agency or workplace profile
 * @param param0
 * @returns
 */
export const DocumentPreviewRow: FC<{
    file: MFile;
    onDelete?: () => void;
    onChangeValidUntil?: (next: number) => void;
    onChange?: (change: Partial<MFile>) => void;
    autoLoadAvailable?: boolean;
}> = ({ file, onDelete, onChangeValidUntil, autoLoadAvailable, onChange }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const { getFileDownloadUrl } = useFireBase();
    const [open, setOpen] = useState(false);

    return (
        <View
            style={[
                { borderRadius: 5 },
                Date.now() > file.validUntil && style.errorBorder,
            ]}
        >
            <View style={[style.horizontalWrap, style.horizontalSplit]}>
                <View>
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            { flexGrow: 1 },
                        ]}
                        onPress={() => setOpen(!open)}
                    >
                        <CIcon icon={open ? 'chevronDown' : 'chevronRight'} />
                        <CText
                            message={filenameMessages[file.type]}
                            style={[
                                {
                                    color: getFileColor(file.type),
                                    borderColor: getFileColor(file.type),
                                    borderWidth: 1,
                                    borderRadius: 3,
                                    padding: 2,
                                    fontSize: 12,
                                },
                                style.horizontalPadded,
                            ]}
                        />
                        <CText numberOfLines={1} bold>
                            {file.name}
                        </CText>
                    </TouchableView>
                    {autoLoadAvailable && onChange && (
                        <CCheckBox
                            checked={file.autoload}
                            onCheckedChanged={(check) =>
                                onChange({ autoload: check })
                            }
                            title={generalMessages.autoload}
                        />
                    )}
                </View>
                <View style={[style.horizontal, style.centeredItems]}>
                    {!file.validForever && (
                        <CDatePicker
                            value={new Date(file.validUntil)}
                            onChange={(v) => {
                                if (onChangeValidUntil) {
                                    onChangeValidUntil(v.getTime());
                                }
                            }}
                            title={generalMessages.validUntil}
                            horizontal
                        />
                    )}
                    {onChange && (
                        <CCheckBox
                            checked={file.validForever}
                            onCheckedChanged={(check) =>
                                onChange({ validForever: check })
                            }
                            title={fileInputMessages.eternal}
                        />
                    )}
                    <CButton
                        icon="download"
                        onPress={() => {
                            getFileDownloadUrl(file.path).then((u) => {
                                Linking.openURL(u);
                            });
                        }}
                        minor
                    />
                    {onDelete && (
                        <CButton
                            icon={'close'}
                            iconColor={theme.errorColor}
                            onPress={onDelete}
                            minor
                        />
                    )}
                </View>
            </View>
            {open && <DocumentPreview filename={file.path} forceOpen />}
        </View>
    );
};
