import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
    CCheckBox,
    CFieldStringInput,
    CPicker,
    CText,
} from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { userMessages } from '../../../User/user.messages';
import {
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
} from '../../../../components/Pickers';
import { EField, EProfession } from '../../../../enums';
import { profProfileMessages } from '../../ProfProfile.messages';
import { useStyle } from '../../../../utilities/styles';
import { ETalentState } from '../../../../enums/ETalentState';

export interface IProfessionalUsersFilter {
    field?: EField;
    profession?: EProfession;
    education?: string;
    customStringFilter?: { field: string; value: string };
    state?: ETalentState;
    internalRating?: number;
}

interface IProfessionalUsersFilterBarProps {
    onChange: (filter: IProfessionalUsersFilter) => void;
}

export const ProfessionalUsersFilterBar: FC<
    IProfessionalUsersFilterBarProps
> = ({ onChange }) => {
    const format = useFormat();
    const style = useStyle();
    const [internalRating, setInternalRating] = useState<number>();
    const [state, setState] = useState<ETalentState>();
    const [field, setField] = useState<EField>();
    const [profession, setProfession] = useState<EProfession>();
    const [education, setEducation] = useState<string>();
    // const [position, setPosition] = useState<string>();
    const [customStringFilter, setCustomStringFilter] =
        useState<{ field: string; value: string }>();
    /**
     * set custom string filter after debounce
     */
    const handleNextCustomStringFilter = useCallback(
        (next: { value: string; field: string }) =>
            setCustomStringFilter((prev) => {
                if (next.value) {
                    // setMode([]);
                    // clearFilters();
                    return next;
                } else if (prev?.value) {
                    return { field: next.field, value: '' };
                } else {
                    return prev;
                }
            }),
        [],
    );
    /**
     * effect to write back state
     */
    useEffect(() => {
        onChange({
            education,
            customStringFilter,
            // position,
            field,
            profession,
            state,
            internalRating,
        });
    }, [
        education,
        customStringFilter,
        field,
        profession,
        state,
        internalRating,
    ]);
    /**
     * render
     */
    return (
        <View>
            <CText secondaryHeadline message={profProfileMessages.filterBy} />
            <FieldPicker
                onChange={(next) => {
                    setProfession(undefined);
                    setEducation(undefined);
                    // setPosition(undefined);
                    setField(next);
                }}
                value={field}
                allowEmpty
            />
            {field && (
                <ProfessionPicker
                    field={field}
                    onChange={(next) => {
                        setEducation(undefined);
                        // setPosition(undefined);
                        setProfession(next);
                    }}
                    value={profession}
                />
            )}
            {/* {profession && (
                <PositionPicker
                    profession={profession}
                    value={position || ''}
                    onChange={(v) => {
                        setPosition(v);
                    }}
                />
            )} */}
            {profession && (
                <EducationPicker
                    field={field}
                    profession={profession}
                    value={education}
                    onChange={(v) => {
                        setEducation(v as string);
                    }}
                />
            )}
            <View style={[style.horizontalSplit]}>
                <View style={style.flex1}>
                    <CPicker
                        values={Array.from(Array(10)).map((_, i) => ({
                            value: i + 1,
                            label: `${i}`,
                        }))}
                        onChange={(v) =>
                            setInternalRating(v ? v - 1 : undefined)
                        }
                        value={
                            internalRating !== undefined
                                ? internalRating + 1
                                : internalRating
                        }
                        allowEmpty
                        placeholder={profProfileMessages.selectInternalRating}
                    />
                </View>
                {/* <CCheckBox
                    checked={hasContract || false}
                    onCheckedChanged={setHasContract}
                    title={profProfileMessages.hasContract}
                /> */}
                <View style={style.flex1}>
                    <CPicker
                        values={[
                            {
                                value: ETalentState.onboarding,
                                label: profProfileMessages.otherStates,
                            },
                            {
                                value: ETalentState.activeContract,
                                label: profProfileMessages.hasContractLabel,
                            },
                            {
                                value: ETalentState.terminated,
                                label: profProfileMessages.terminated,
                            },
                            {
                                value: ETalentState.willTerminate,
                                label: profProfileMessages.willTerminate,
                            },
                        ]}
                        value={state}
                        onChange={(v) => setState(v)}
                        placeholder={profProfileMessages.selectEState}
                        allowEmpty
                    />
                </View>
            </View>
            <CFieldStringInput
                value={customStringFilter}
                fields={['lastName', 'firstName'].map((f) => {
                    return {
                        label: format(
                            userMessages[f as keyof typeof userMessages],
                        ),
                        field: f,
                    };
                })}
                onChange={handleNextCustomStringFilter}
                cy={'prof-user-text-filter'}
            />
        </View>
    );
};
