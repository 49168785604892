import React, { FC } from 'react';
import { View } from 'react-native';
import { IDayAvailability } from '../../../models';
import { dayMessages } from '../../../utilities/messages';
import { CIcon, CText } from '../../../components';
import { useStyle } from '../../../utilities/styles';
import { keyof, timeString } from '../../../utilities/functions';

export const WorkHoursDisplay: FC<{ days: IDayAvailability[] }> = ({
    days,
}) => {
    const style = useStyle();
    return (
        <View style={style.horizontalSpaced}>
            {keyof(dayMessages)
                .filter((v) => !v.includes('Short'))
                .map((key, i) => {
                    const ref = days.find((d) => d.day - 1 === i);

                    return (
                        <View
                            key={key}
                            style={[style.flex1, style.centeredItems]}
                        >
                            <CText
                                secondaryHeadline
                                message={dayMessages[key]}
                            />
                            {ref ? (
                                <>
                                    <CText>{`${timeString(
                                        ref.from,
                                        0,
                                    )}`}</CText>{' '}
                                    <CText>{`${timeString(ref.to, 0)}`}</CText>
                                </>
                            ) : (
                                <View style={style.verticalPadded}>
                                    <CIcon icon="close" />
                                </View>
                            )}
                        </View>
                    );
                })}
        </View>
    );
};
