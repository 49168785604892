import React, { FC, useMemo } from 'react';
import { IRatesProps } from './RatesView';
import { CTable, CText } from '..';
import {
    convertNumberToCurrencyString,
    keyof,
    makeDisplayWage,
} from '../../utilities/functions';
import { wageMessages } from './wage.messages';
import { useFormat } from '../../utilities/intl';
import { useEnvironment } from '../../utilities/contexts';
import { useStyle, useTheme } from '../../utilities/styles';
import { View } from 'react-native';

export const RatesTable: FC<IRatesProps> = ({
    curData,
    excludeRegular,
    messageIfEmpty,
}) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const { region } = useEnvironment();
    /**
     * wages to display
     */
    const displayWageRegular = useMemo(
        () => makeDisplayWage(curData),
        [curData],
    );
    /**
     * check if there is any night wage rates
     */
    const hasNightWage = useMemo(
        () => !!(curData.useNightWage && curData.nightWage.wage),
        [curData],
    );
    /**
     * night wages to display
     */
    const displayWageNight = useMemo(
        () => makeDisplayWage(curData.nightWage),
        [curData.nightWage],
    );
    /**
     * check if there is any sunday wage rates
     */
    const hasSundayWage = useMemo(
        () => !!(curData.useSundayWage && curData.sundayWage.wage),
        [curData],
    );
    /**
     * sunday wages to display
     */
    const displayWageSunday = useMemo(
        () => makeDisplayWage(curData.sundayWage),
        [curData.sundayWage],
    );
    /**
     * check if there is any holiday wage rates
     */
    const hasHolidayWage = useMemo(
        () => !!(curData.useHolidayWage && curData.holidayWage.wage),
        [curData],
    );
    /**
     * holiday wages to display
     */
    const displayWageHoliday = useMemo(
        () => makeDisplayWage(curData.holidayWage),
        [curData.holidayWage],
    );
    /**
     * memoized table row data
     */
    const rows = useMemo(() => {
        return [
            ...keyof(displayWageRegular)
                .filter((key) => !(excludeRegular && key === 'regularWage'))
                .map(
                    (k) =>
                        displayWageRegular[k] && {
                            key: format(wageMessages[k]),
                            value: convertNumberToCurrencyString(
                                displayWageRegular[k],
                                region,
                            ),
                        },
                ),
            hasNightWage && {
                key: format(wageMessages.nightWage),
                value: '',
                subsection: true,
            },
            ...(hasNightWage
                ? keyof(displayWageNight).map(
                      (k) =>
                          displayWageNight[k] && {
                              key: format(wageMessages[k]),
                              value: convertNumberToCurrencyString(
                                  displayWageNight[k],
                                  region,
                              ),
                          },
                  )
                : []),
            hasSundayWage && {
                key: format(wageMessages.sundayWage),
                value: '',
                subsection: true,
            },
            ...(hasSundayWage
                ? keyof(displayWageSunday).map(
                      (k) =>
                          displayWageSunday[k] && {
                              key: format(wageMessages[k]),
                              value: convertNumberToCurrencyString(
                                  displayWageSunday[k],
                                  region,
                              ),
                          },
                  )
                : []),
            hasHolidayWage && {
                key: format(wageMessages.holidayWage),
                value: '',
                subsection: true,
            },
            ...(hasHolidayWage
                ? keyof(displayWageHoliday).map(
                      (k) =>
                          displayWageHoliday[k] && {
                              key: format(wageMessages[k]),
                              value: convertNumberToCurrencyString(
                                  displayWageHoliday[k],
                                  region,
                              ),
                          },
                  )
                : []),
        ];
    }, [
        displayWageRegular,
        hasSundayWage,
        hasNightWage,
        hasHolidayWage,
        region,
    ]);
    /**
     * return message if empty if present and empty
     */
    if (!rows.filter((v) => !!v).length && messageIfEmpty) {
        return (
            <View style={style.horizontalSpaced}>
                <CText message={messageIfEmpty} />
            </View>
        );
    }
    /**
     * render
     */
    return (
        <CTable
            headers={[{ key: 'key', primary: true }, { key: 'value' }]}
            hideHeaders
            rows={rows.map((entry) => {
                if (entry) {
                    return {
                        values: [
                            {
                                key: 'key',
                                bold: true,
                                color:
                                    (!entry.value && theme.accentColor) ||
                                    undefined,
                                value: entry.key,
                                subsection: !!(entry as { subsection: boolean })
                                    .subsection,
                            },
                            {
                                key: 'value',
                                value: entry.value,
                            },
                        ],
                    };
                }
            })}
        />
    );
};
