import React, { FC, useCallback } from 'react';
import { Linking, View } from 'react-native';
import { CButton, CText, CLabel } from '../../../../components';
import {
    actionMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { profProfileMessages } from '../../ProfProfile.messages';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useSecureNavigate } from '../../../../utilities/routing';
import { MChat, MProfessionalProfile } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { useDialog } from '../../../../utilities/dialog';
import { ETalentState } from '../../../../enums/ETalentState';

export const TalentHeadlineCard: FC<{
    profInfos: MProfessionalProfile;
    prevProfileName: string;
    chat?: MChat;
    onChange: (next: Partial<MProfessionalProfile>) => void;
    handleReinvite: () => void;
    handleTermination: (forceNow?: boolean) => void;
    handleTerminationRevert: () => void;
}> = ({
    profInfos,
    prevProfileName,
    chat,
    handleReinvite,
    handleTermination,
    handleTerminationRevert,
    onChange,
}) => {
    const { secureNavigate } = useSecureNavigate();
    const { callFunction } = useFireBase();
    const { theme } = useTheme();
    const format = useFormat();
    const dialog = useDialog();
    const style = useStyle();
    /**
     * dialog to change employment status of talent
     */
    const employmentChangeDialog = useCallback(async () => {
        const isTerminated =
            profInfos.terminated && profInfos.terminateOn < Date.now();
        const willTerminate = profInfos.terminateOn > Date.now();
        const isEmployed = profInfos.hasContract;
        await dialog({
            icon: 'question',
            title: profProfileMessages.changeEmploymentStatusTitle,
            message: isTerminated
                ? profProfileMessages.isTerminated
                : willTerminate
                ? format(profProfileMessages.isToBeTerminated, {
                      date: new Date(profInfos.terminateOn).toLocaleDateString(
                          'de',
                      ),
                  })
                : isEmployed
                ? profProfileMessages.isEmployed
                : profProfileMessages.selectANewStatus,
            buttons: [
                !(isTerminated || willTerminate)
                    ? {
                          text: profProfileMessages.terminate,
                          color: theme.errorColor,
                          onPress: () => {
                              handleTermination();
                          },
                      }
                    : undefined,
                !(isTerminated || willTerminate) && !isEmployed
                    ? {
                          text: profProfileMessages.setHasContract,
                          color: theme.successColor,
                          onPress: () =>
                              onChange({
                                  hasContract: true,
                                  terminated: false,
                                  terminateOn: 0,
                                  state: ETalentState.activeContract,
                              }),
                      }
                    : undefined,
                willTerminate
                    ? {
                          text: profProfileMessages.terminateNow,
                          color: theme.errorColor,
                          onPress: () => {
                              handleTermination(true);
                          },
                      }
                    : undefined,
                isTerminated || willTerminate
                    ? {
                          text: profProfileMessages.revertTerminate,
                          onPress: () => {
                              handleTerminationRevert();
                          },
                      }
                    : undefined,
            ],
            cancelButton: { text: actionMessages.cancel },
            verticalButtons: true,
        });
    }, [profInfos, handleTermination, handleTerminationRevert]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.headlineCard,
                style.centeredItems,
                style.horizontalSplit,
            ]}
        >
            <View style={style.horizontal}>
                <CButton
                    cy={'back'}
                    onPress={async () => {
                        secureNavigate(-1);
                    }}
                    icon={'chevronLeft'}
                    small
                />
                <CText
                    message={`${format(
                        generalMessages.professionalProfile,
                    )} - ${prevProfileName}`}
                    bold
                    headline
                    style={style.horizontalHeavyPadded}
                />
            </View>
            <View style={[style.horizontal, style.centeredItems]}>
                {profInfos.hasContract && (
                    <CLabel
                        checked={true}
                        title={profProfileMessages.hasContractLabel}
                        color={theme.successColor}
                        smaller
                    />
                )}
                {profInfos.terminated ? (
                    new Date(profInfos.terminateOn) < new Date() ? (
                        <>
                            <CLabel
                                checked={true}
                                title={profProfileMessages.terminated}
                                color={theme.errorColor}
                                smaller
                            />
                            {/* <CButton
                                onPress={handleTerminationRevert}
                                transparent
                                small
                                title={profProfileMessages.revertTerminate}
                            /> */}
                        </>
                    ) : (
                        <>
                            <CLabel
                                checked={true}
                                title={format(
                                    profProfileMessages.terminationPlanned,
                                    {
                                        date: new Date(
                                            profInfos.terminateOn,
                                        ).toLocaleDateString('de'),
                                    },
                                )}
                                color={theme.errorColor}
                                smaller
                            />
                            {/* <CButton
                                onPress={() => handleTermination(true)}
                                transparent
                                small
                                title={profProfileMessages.terminateNow}
                            /> */}
                        </>
                    )
                ) : (
                    <></>
                    // <CButton
                    //     onPress={handleTermination}
                    //     transparent
                    //     small
                    //     title={profProfileMessages.terminate}
                    // />
                )}
                <CButton
                    title={profProfileMessages.changeEmploymentStatus}
                    onPress={employmentChangeDialog}
                    transparent
                    small
                />
                {!!chat && (
                    <CButton
                        onPress={() =>
                            secureNavigate('/chat/' + chat.documentId)
                        }
                        transparent
                        small
                        icon={'chat'}
                    />
                )}
                {!!(profInfos.uid && !chat) && (
                    <CButton
                        onPress={() => {
                            callFunction('openChatWithTalent', {
                                talentId: profInfos.documentId,
                                talentUid: profInfos.uid,
                            }).then((chatId) => {
                                secureNavigate('/chat/' + chatId);
                            });
                        }}
                        transparent
                        small
                        icon={'chat'}
                    />
                )}
                {!!(
                    profInfos.mail &&
                    profInfos.firstName &&
                    profInfos.lastName &&
                    !profInfos.uid
                ) &&
                    !profInfos.open && (
                        <CButton
                            onPress={handleReinvite}
                            transparent
                            small
                            icon={'plane'}
                            mouseOverTitle={profProfileMessages.reSendForm}
                            fontStyles={{ color: theme.accentColor }}
                        />
                    )}
                {!!profInfos.phone && (
                    <CButton
                        onPress={() => {
                            const strippedNumber = profInfos.phone.replace(
                                /\D/g,
                                '',
                            );
                            Linking.openURL(
                                encodeURI('https://wa.me/' + strippedNumber),
                            );
                        }}
                        icon={'whatsapp'}
                        iconColor="#25D366"
                        transparent
                        small
                    />
                )}
            </View>
        </View>
    );
};
