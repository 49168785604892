import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCalendar, CCard, CPicker, CText } from '../../components';
import { ECollections, EJobStatus, EUserType } from '../../enums';
import { EContractStatus } from '../../enums/EContractStatus';
import {
    MAvailability,
    MContract,
    MJob,
    MProfessionalProfile,
    MUserData,
} from '../../models';
import { isEmployer, isPeasant, isSuperUser } from '../../utilities/auth';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFireBase } from '../../utilities/firebase';
import { IFilter } from '../../utilities/firebase/store';
import { convertWeekAndYearToDate, keyof } from '../../utilities/functions';
import { useFormat } from '../../utilities/intl';
import { generalMessages } from '../../utilities/messages/general.messages';
import { monthMessages } from '../../utilities/messages/month.messages';
import { useSearchParams, useSecureNavigate } from '../../utilities/routing';
import { useStyle } from '../../utilities/styles';
import { ContractSideCard } from '../Contract/components/ContractSideCard';
import { jobMessages } from '../Job/job.messages';
import { JobSideCard } from '../JobApplications/View/components/JobSideCard';
import { calendarMessages } from './Calendar.messages';
import { ICalendarProps } from './ICalendarProps';
import { DayHours } from './components/DayHours';
import { TodaysContracts } from './components/TodaysContracts';
import { TodaysJobs } from './components/TodaysJobs';
import { EditAvailability2 } from './components/EditAvailability.v2';
import { educationMessages } from '../../utilities/messages';
import { dayMessages } from '../../utilities/messages/day.messages';
import { useDialog } from '../../utilities/dialog';
import { useCollectionIndex } from '../../utilities/firebase/store/useIndex';
/**
 * day messages to map getDay value to
 */
const days = keyof(dayMessages)
    .filter((v) => !v.includes('Short'))
    .map((key) => dayMessages[key]);
/**
 * web calendar
 * @param param0
 * @returns
 */
const UnwrappedCalendar: FC<ICalendarProps> = ({ embedded }) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { getDataById, userData, userWorkplaces, userAgencies } =
        useFireBase();
    const [params] = useSearchParams();
    const { secureNavigate } = useSecureNavigate();
    const dialog = useDialog();
    // local state
    const [user, setUser] = useState<MUserData>();
    const [curDate, setCurDate] = useState(new Date());
    const [curMonth, setCurMonth] = useState(new Date().getMonth());
    const [edit, setEdit] = useState(false);
    const [prev, setPrev] = useState<MAvailability>();
    const [selectedHour, setSelectedHour] = useState<number>();
    const [returnToProfile, setReturnToProfile] = useState(false);
    const [range, setRange] = useState<string[]>([]);
    const [selectedJob, setSelectedJob] = useState<MJob>();
    const [selectedContract, setSelectedContract] = useState<MContract>();
    const [manualySelectedProfile, setSelectedProfile] =
        useState<MProfessionalProfile>();
    /**
     * params to filter profiles by (agency or documentId if admin)
     */
    const profileParams = useMemo(() => {
        const urlId = params.get('profileId');

        if (userAgencies.length) {
            // TODO: agencyUsers? like workplaceUsers on jobs etc
            return {
                filter: [
                    {
                        field: 'agencyId',
                        operator: 'in' as const,
                        value: userAgencies.map((a) => a.documentId),
                    },
                ],
            };
        } else if (isSuperUser(userData) && urlId) {
            return {
                filter: [
                    {
                        field: 'documentId',
                        value: urlId,
                    },
                ],
            };
        } else {
            return { suspend: true };
        }
    }, [userAgencies]);
    /**
     * live profile data (overkill but whatever)
     */
    const profileData = useCollectionIndex(
        ECollections.profProfiles,
        profileParams,
    );
    /**
     * memoized parsed profile data
     */
    const profiles = useMemo(
        () => profileData.map((p) => new MProfessionalProfile(p)),
        [profileData],
    );
    /**
     * memoized selected profile to be overwritten from manually selected state
     */
    const selectedProfile = useMemo(() => {
        if (manualySelectedProfile) {
            return manualySelectedProfile;
        }
        const urlId = params.get('profileId');
        const urlSelectedProfile = profiles.find((p) => p.documentId === urlId);
        if (urlId && urlSelectedProfile) {
            if (!params.get('aId')) {
                setReturnToProfile(true);
            }
            return urlSelectedProfile;
        }
    }, [manualySelectedProfile, profiles, params]);
    /**
     * memoized params for contract collection query
     */
    const contractParams = useMemo(() => {
        if (
            !(
                userData.type === EUserType.user ||
                isEmployer(userData) ||
                selectedProfile
            )
        ) {
            return { suspend: true };
        }

        const filter = [] as IFilter[];

        if (userData.type === EUserType.user) {
            filter.push({
                field: 'employeeId',
                value: userData.documentId,
            });
        } else if (selectedProfile) {
            filter.push({
                field: 'profileId',
                value: selectedProfile.documentId,
            });
            filter.push({
                field: 'agencyId',
                value: selectedProfile.agencyId,
            });
        } else if (isEmployer(userData)) {
            filter.push({
                field: 'workplaceUsers',
                value: userData.documentId,
                operator: 'array-contains',
            });
        }
        filter.push({
            field: 'status',
            value: [
                EContractStatus.accepted,
                EContractStatus.review_yee,
                EContractStatus.review_yer,
                EContractStatus.signed,
            ],
            operator: 'in',
        });
        return {
            filter: [...filter],
        };
    }, [userData, userWorkplaces, userAgencies, selectedProfile]);
    /**
     * raw contract data
     */
    const contractData = useCollectionIndex(
        ECollections.contracts,
        contractParams,
    );
    /**
     * memoized parsed contracts and negotiations
     */
    const { contracts, negotiations } = useMemo(() => {
        const next = contractData.map((c) => new MContract(c));
        return next.reduce(
            (acc, c) => {
                if (c.status === EContractStatus.signed) {
                    acc.contracts.push(c);
                } else {
                    acc.negotiations.push(c);
                }
                return acc;
            },
            { contracts: [] as MContract[], negotiations: [] as MContract[] },
        );
    }, [contractData]);
    /**
     * params to filter availabilities by
     */
    const availabilityParams = useMemo(() => {
        if (!selectedProfile && userData.type === EUserType.agency) {
            return { suspend: true };
        }
        const paramId = params.get('uid');
        const filter: IFilter[] = [{ field: 'deleted', value: false }];
        // ! no
        // if (userAgencies.length) {
        //     filter.push({
        //         field: 'agencyId',
        //         value: userAgencies[0].documentId,
        //     });
        // }
        if (selectedProfile) {
            filter.push({
                field: 'profileId',
                value: selectedProfile.documentId,
            });
            if (
                userData.type === EUserType.agency &&
                selectedProfile.agencyId
            ) {
                filter.push({
                    field: 'agencyId',
                    value: selectedProfile.agencyId,
                });
            }
        } else {
            if (paramId && isSuperUser(userData)) {
                filter.push({
                    field: 'uid',
                    value: paramId,
                });
                getDataById(ECollections.users, paramId).then((r) => {
                    const ud = new MUserData(r);
                    setUser(ud);
                    setReturnToProfile(true);
                });
            } else {
                filter.push({ field: 'uid', value: userData.documentId });
            }
        }
        return { filter };
    }, [params, selectedProfile, userData]);
    /**
     * availability data live
     */
    const availabilityData = useCollectionIndex(
        ECollections.availabilities,
        availabilityParams,
    );
    /**
     * memoized parsed availabilities
     */
    const availabilities = useMemo(
        () => availabilityData.map((r) => new MAvailability(r)),
        [availabilityData],
    );
    /**
     * memoized params to get job data for current workplace user
     */
    const jobParams = useMemo(() => {
        if (userData.type !== EUserType.employer) return { suspend: true };
        return {
            filter: [
                {
                    field: 'workplaceUsers',
                    value: userData.documentId,
                    operator: 'array-contains' as const,
                },
                {
                    field: `activeMonthsObject.m${curMonth}`,
                    value: true,
                },
                {
                    field: 'status',
                    operator: 'in' as const,
                    value: [EJobStatus.private, EJobStatus.public],
                },
            ],
        };
    }, [curMonth]);
    /**
     * live job data based on job params
     */
    const jobData = useCollectionIndex(ECollections.jobs, jobParams);
    /**
     * memoized parsed jobs for current workplace user for current month
     */
    const jobs = useMemo(() => jobData.map((j) => new MJob(j)), [jobData]);
    /**
     * callback to navigate to create job
     */
    const createJob = useCallback(() => {
        dialog({
            title: jobMessages.createFromDraftDialog,
            message: jobMessages.createFromDraftDialogText,
            buttons: [
                {
                    text: jobMessages.openDrafts,
                    onPress: () => secureNavigate('/japplications?mode=draft'),
                },
                {
                    text: jobMessages.createNew,
                    onPress: () =>
                        secureNavigate('/job/new?from=' + curDate.getTime()),
                },
            ],
        });
    }, [curDate]);
    /**
     * callback to enable edit of availability
     */
    const enableEdit = useCallback(
        (next: boolean) => {
            if (
                userData.type === EUserType.user ||
                userData.type === EUserType.agency ||
                isSuperUser(userData)
            ) {
                setEdit(next);
            } else {
                createJob();
            }
        },
        [curDate, userData, createJob],
    );
    /**
     * callback to close current sections
     */
    const closeSelection = useCallback(() => {
        setSelectedJob(() => undefined);
        setSelectedContract(() => undefined);
        setRange([]);
    }, []);
    /**
     * handle modal close callback
     */
    const handleModalClose = useCallback(() => {
        setEdit(false);
        setPrev(undefined);
        setSelectedHour(undefined);
    }, [setEdit, setPrev]);
    /**
     * effect to select availability based on param
     */
    useEffect(() => {
        const aId = params.get('aId');
        if (aId && !returnToProfile) {
            const aoi = availabilities.find((a) => a.documentId === aId);
            if (aoi) {
                setEdit(true);
                setPrev(aoi);
                setReturnToProfile(true);
                setCurDate(convertWeekAndYearToDate(aoi.year, aoi.start));
            }
        }
    }, [availabilities, returnToProfile, params]);
    /**
     * render
     */
    return (
        <>
            {!embedded && (
                <View style={[style.card]}>
                    <View style={[style.horizontalWrap, style.horizontalSplit]}>
                        <View style={[style.horizontal, style.centeredItems]}>
                            {returnToProfile && (
                                <CButton
                                    cy={'back'}
                                    onPress={async () => {
                                        secureNavigate(-1);
                                    }}
                                    icon={'chevronLeft'}
                                    small
                                />
                            )}
                            <CText
                                bold
                                headline
                                style={style.horizontalHeavyPadded}
                            >
                                {`${format(generalMessages.calendar)} ${
                                    user
                                        ? `- ${user.firstName} ${user.lastName} `
                                        : ''
                                }- ${format(
                                    days[curDate.getDay()],
                                )} ${curDate.toLocaleDateString('de')}`}
                            </CText>
                        </View>
                        {!edit && (
                            <View style={style.centeredContent}>
                                {isPeasant(userData) ? (
                                    <CButton
                                        icon={'plus'}
                                        title={calendarMessages.addAvailability}
                                        onPress={() => enableEdit(true)}
                                    />
                                ) : (
                                    <CButton
                                        icon={'plus'}
                                        title={jobMessages.createNew}
                                        onPress={createJob}
                                    />
                                )}
                            </View>
                        )}
                    </View>
                </View>
            )}
            {!!profiles.length && params.get('profileId') && (
                <CCard>
                    <CText
                        secondaryHeadline
                        message={generalMessages.professionalProfile}
                    />
                    <CPicker
                        onChange={(value: number) => {
                            setSelectedProfile(profiles[value - 1]);
                        }}
                        values={profiles
                            .filter((pp) => pp.educations.length)
                            .map((pp, i) => {
                                const key = pp
                                    .educations[0] as keyof typeof educationMessages;
                                return {
                                    value: i + 1,
                                    label: `${pp.lastName} ${
                                        educationMessages[key]
                                            ? format(educationMessages[key])
                                            : ''
                                    }`,
                                };
                            })}
                        value={
                            selectedProfile
                                ? profiles.findIndex(
                                      (p) =>
                                          p.documentId ===
                                          selectedProfile.documentId,
                                  ) + 1
                                : 0
                        }
                        placeholder={generalMessages.professionalProfile}
                        horizontal
                    />
                </CCard>
            )}
            <View style={[style.horizontal, { flex: 1, width: '100%' }]}>
                <View style={[style.card, { flex: 1.5 }]}>
                    <View
                        style={[
                            style.centeredItems,
                            style.horizontalSpaced,
                            style.horizontalWrap,
                        ]}
                    >
                        <View style={[{ flex: 1 }, style.centeredItems]}>
                            <CPicker
                                title={calendarMessages.month}
                                value={curDate.getMonth() + 1}
                                values={keyof(monthMessages)
                                    .filter((m) => !m.includes('Short'))
                                    .map((m, i) => ({
                                        label: monthMessages[m],
                                        value: i + 1,
                                    }))}
                                onChange={(next) =>
                                    setCurDate((prev) => {
                                        const nextD = new Date(prev);
                                        nextD.setMonth(next - 1);
                                        if (
                                            prev.getMonth() !== nextD.getMonth()
                                        ) {
                                            setCurMonth(nextD.getMonth());
                                        }
                                        return nextD;
                                    })
                                }
                                horizontal
                            />
                        </View>
                        <CButton
                            small
                            title={generalMessages.selectToday}
                            onPress={() =>
                                setCurDate((prev) => {
                                    if (
                                        prev.getMonth() !==
                                        new Date().getMonth()
                                    ) {
                                        setCurMonth(new Date().getMonth());
                                    }
                                    return new Date();
                                })
                            }
                        />
                        <View style={[{ flex: 1 }, style.centeredItems]}>
                            <CPicker
                                horizontal
                                title={calendarMessages.year}
                                value={curDate.getFullYear()}
                                values={Array.from(
                                    new Set([
                                        ...Array(10).keys(),
                                        curDate.getFullYear() -
                                            (new Date().getFullYear() - 2),
                                    ]),
                                ).map((y, i) => ({
                                    label: `${
                                        y + new Date().getFullYear() - 2
                                    }`,
                                    value: y + new Date().getFullYear() - 2,
                                }))}
                                onChange={(next) =>
                                    setCurDate((prev) => {
                                        const nextD = new Date(prev);
                                        nextD.setFullYear(next);
                                        if (
                                            prev.getMonth() !== nextD.getMonth()
                                        ) {
                                            setCurMonth(nextD.getMonth());
                                        }
                                        return nextD;
                                    })
                                }
                            />
                        </View>
                    </View>
                    <CCalendar
                        setCurDate={(next) =>
                            setCurDate((prev) => {
                                if (prev.getMonth() !== next.getMonth()) {
                                    setCurMonth(next.getMonth());
                                }
                                return next;
                            })
                        }
                        curDate={curDate}
                        availabilities={availabilities}
                        range={range.length ? range : undefined}
                        negotiations={negotiations}
                        contracts={contracts}
                        jobs={jobs}
                    />
                </View>
                {selectedJob && (
                    <JobSideCard
                        job={selectedJob}
                        notInJobApplicationsView
                        onClose={closeSelection}
                    />
                )}
                {selectedContract && (
                    <ContractSideCard
                        contract={selectedContract}
                        onClose={closeSelection}
                    />
                )}
            </View>
            {isEmployer(userData) ? (
                <>
                    <TodaysContracts
                        curDate={curDate}
                        setRange={setRange}
                        selectedContract={selectedContract}
                        setSelectedContract={(next) => {
                            setSelectedContract(next);
                            setSelectedJob(undefined);
                        }}
                        contracts={contracts}
                    />
                    <TodaysJobs
                        curDate={curDate}
                        setRange={setRange}
                        selectedJob={selectedJob}
                        setSelectedJob={(next) => {
                            setSelectedContract(undefined);
                            setSelectedJob(next);
                        }}
                        thisMonthsJobs={jobs}
                    />
                </>
            ) : !edit ? (
                <DayHours
                    curDate={curDate}
                    availabilities={availabilities}
                    selectedJob={selectedJob}
                    setSelectedJob={(next) => {
                        setSelectedContract(undefined);
                        setSelectedJob(next);
                    }}
                    thisMonthsJobs={jobs}
                    selectedContract={selectedContract}
                    setSelectedContract={(next) => {
                        setSelectedContract(next);
                        setSelectedJob(undefined);
                    }}
                    contracts={contracts}
                    negotiations={negotiations}
                    setSelectedHour={setSelectedHour}
                    setEdit={enableEdit}
                    setPrev={setPrev}
                    returnToProfile={returnToProfile}
                />
            ) : (
                <EditAvailability2
                    close={handleModalClose}
                    startDate={curDate}
                    startHour={selectedHour}
                    prev={prev}
                    returnToProfile={returnToProfile}
                    selectedProfile={selectedProfile}
                />
            )}
        </>
    );
};

export const Calendar: FC<ICalendarProps> = ({ embedded }) => {
    const style = useStyle();
    return (
        <ScrollProvider style={!embedded && style.paddedScrollableMainView}>
            <UnwrappedCalendar />
        </ScrollProvider>
    );
};
