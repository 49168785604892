import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Pressable, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { useStyle, useTheme } from '../../../utilities/styles';
import { Message } from '../../../utilities/types';
import { Spinner } from '../../Spinner';
import { CIcon } from '../CIcon';
import { CText } from '../CText';
import { makeCButtonStyles } from './styles';

export interface ICButtonProps {
    cy?: string;
    style?: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
    fontStyles?: StyleProp<TextStyle> | Array<StyleProp<TextStyle>>;
    stylesHovered?: StyleProp<ViewStyle>;
    transparent?: boolean;
    minor?: boolean;
    iconStyles?: StyleProp<ViewStyle>;
    iconColor?: string;
    onPress: () => void;
    onPressDisabled?: () => void;
    title?: Message;
    mouseOverTitle?: Message;
    icon?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    reason?: Message;
    checkbox?: boolean;
    small?: boolean;
    smaller?: boolean;
    smallest?: boolean;
    vertical?: boolean;
    coloredIcon?: boolean;
    disableOnClick?: boolean;
    registerActiveHook?: (hook: (active: boolean) => void) => void;
    onBlur?: () => void;
    danger?: boolean;
    warning?: boolean;
    nativeId?: string;
    unstyledText?: boolean;
}

export const CButton: FC<ICButtonProps> = (props) => {
    // global state
    const { theme } = useTheme();
    const styles = useStyle();
    // local state
    const ref = useRef(null);
    const localStyles = useMemo(() => makeCButtonStyles(theme), [theme]);
    const [clicked, onChangedClicked] = useState<boolean>(false);
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    /**
     * memoized conversion of prop values to icon size and styles
     */
    const { styleArray, iconSize } = useMemo(() => {
        const styleArray = [];
        let iconSize = 24;
        if (props.checkbox) {
            iconSize = 15;
            styleArray.push(localStyles.buttonCheckbox);
        } else if (props.small) {
            iconSize = 20;
            styleArray.push(localStyles.buttonSmall);
        } else if (props.smaller) {
            iconSize = 15;
            styleArray.push(localStyles.buttonSmall);
        } else if (props.smallest) {
            iconSize = 15;
            styleArray.push(localStyles.buttonSmallest);
        } else {
            styleArray.push(localStyles.button);
        }
        return { styleArray, iconSize };
    }, [props]);
    /**
     * effect to register manual onChangeClicked function for parents
     */
    useEffect(() => {
        if (props.registerActiveHook) {
            props.registerActiveHook(onChangedClicked);
        }
    }, [props.registerActiveHook, onChangedClicked]);
    /**
     * render
     */
    return (
        <View
            {...{
                onMouseEnter: () => setMouseOver(true),
                onMouseLeave: () => setMouseOver(false),
            }}
        >
            <Pressable
                {...{ dataSet: { cy: props.cy } }}
                ref={ref}
                style={[
                    styleArray,
                    props.transparent && localStyles.transparentButton,
                    props.minor &&
                        !props.transparent &&
                        localStyles.minorButton,
                    props.danger && localStyles.dangerButton,
                    props.warning && localStyles.warningButton,
                    props.style,
                    props.disabled && styles.disabled,
                ]}
                onBlur={props.onBlur}
                disabled={clicked}
                onPress={async () => {
                    if (props.disabled || clicked) {
                        if (props.onPressDisabled) {
                            props.onPressDisabled();
                        }
                    } else {
                        if (props.disableOnClick) {
                            onChangedClicked(true);
                        }
                        await props.onPress();

                        onChangedClicked(false);
                    }
                }}
                nativeID={props.nativeId}
            >
                {props.icon && !(mouseOver && props.mouseOverTitle) && (
                    <View
                        style={[
                            props.title
                                ? props.vertical
                                    ? localStyles.buttonIconContainerVertical
                                    : localStyles.buttonIconContainer
                                : {},
                        ]}
                    >
                        <CIcon
                            icon={props.icon}
                            fontSize={iconSize}
                            tint={
                                props.iconColor
                                    ? props.iconColor
                                    : props.disabled
                                    ? theme.textTertiaryColor
                                    : (!props.coloredIcon &&
                                          props.transparent) ||
                                      props.minor
                                    ? theme.accentColor
                                    : theme.textAccentColor
                            }
                            colored={props.coloredIcon}
                        />
                    </View>
                )}
                {clicked ? (
                    <Spinner color={theme.textAccentColor} small />
                ) : (
                    (!!props.title ||
                        (!!props.mouseOverTitle && mouseOver)) && (
                        <CText
                            disabled={props.disabled}
                            isButtonText={!props.unstyledText}
                            style={[
                                { textAlign: 'center' },
                                props.transparent &&
                                    localStyles.antiTransparentText,
                                props.minor && localStyles.minorButtonText,
                                props.fontStyles,
                            ]}
                            message={props.title || props.mouseOverTitle}
                        />
                    )
                )}
                {props.children}
            </Pressable>
            {!!props.disabled && !!props.reason && (
                <CText style={styles.warning} message={props.reason} />
            )}
        </View>
    );
};
