import React, { useCallback, useContext } from 'react';
import { CModalContext } from '../contexts';
import { Dialog } from './Dialog';
import { actionMessages } from '../messages';
import { IDialogProps, IDialogButton } from './IDialog';
/**
 * use dialog
 * @returns dialog callback
 */
export const useDialog = () => {
    const { unshiftModal, pushModal, popModal } = useContext(CModalContext);
    /**
     * open dialog callback
     */
    const dialog = useCallback(async (params: IDialogProps) => {
        const cButton = params.cancelButton;
        const filteredButtons: IDialogButton[] =
            (params.buttons?.filter((b) => !!b) as IDialogButton[]) ||
            ([{ text: actionMessages.ok }] as IDialogButton[]);
        return new Promise<boolean>((resolve) => {
            (params.push ? pushModal : unshiftModal)(
                <Dialog
                    {...params}
                    buttons={filteredButtons.map((b) => {
                        return {
                            ...b,
                            onPress: async (inputs) => {
                                let r = true;
                                if (b.onPress) {
                                    const result = await b.onPress(inputs);
                                    if (
                                        result !== undefined &&
                                        result !== null
                                    ) {
                                        r = !!result;
                                    }
                                }
                                if (!b.dontResolve) {
                                    setTimeout(() => {
                                        popModal();
                                        resolve(r);
                                    }, 1);
                                }
                            },
                        };
                    })}
                    cancelButton={
                        cButton
                            ? {
                                  ...cButton,
                                  onPress: () => {
                                      if (cButton.onPress) {
                                          cButton.onPress();
                                      }
                                      setTimeout(() => {
                                          popModal();
                                          resolve(false);
                                      }, 1);
                                  },
                              }
                            : undefined
                    }
                />,
                {
                    onClose: () => {
                        if (cButton && cButton.onPress) {
                            cButton.onPress();
                        } else if (
                            !cButton &&
                            params.buttons &&
                            params.buttons.length === 1 &&
                            filteredButtons[0].onPress
                        ) {
                            filteredButtons[0].onPress();
                        }

                        resolve(false);
                    },
                },
            );
        });
    }, []);

    return dialog;
};
