import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Linking, View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { MApplication } from '../../../models/MApplication';
import {
    CText,
    CButton,
    ProfilePicture,
    CTable,
    StarDisplay,
    TouchableView,
} from '../../../components';
import { ApplicationPicture } from '../../../components/ProfilePicture/ApplicationPicture';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import {
    specializationMessages,
    positionMessages,
    generalMessages,
    educationMessages,
} from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/job.messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { useEnvironment } from '../../../utilities/contexts';
import { useLock } from '../../../utilities/hooks';
import {
    MContract,
    MProfessionalInfo,
    MProfessionalProfile,
    MRequest,
} from '../../../models';
import { ECollections, EUserType } from '../../../enums';
import { isAgencyUser } from '../../../utilities/auth';
import { useSecureNavigate } from '../../../utilities/routing';
import { profProfileMessages } from '../../ProfProfile/ProfProfile.messages';
import { ContractState } from './ContractState';
import { contractMessages } from '../contract.messages';
/**
 * overview of applicant connected to contract
 * @param param0
 * @returns
 */
export const ApplicantOverview: FC<{
    application?: MApplication;
    applicant?: MProfessionalInfo;
    request?: MRequest;
    contract: MContract;
}> = ({ application, applicant, request, contract }) => {
    // global state
    const { getFile, callFunction, getDataById, userData } = useFireBase();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const style = useStyle();
    const { lock } = useLock();
    const { environment } = useEnvironment();
    const { language } = useContext(LanguageContext);
    // local state
    const [talent, setTalent] = useState<MProfessionalProfile>();
    const talentId = useMemo(
        () => application?.profileId || talent?.documentId,
        [application, talent],
    );
    const resource = useMemo(
        () => ({ ...applicant, ...application }),
        [applicant, application],
    );
    /**
     * callback to get resume
     */
    const getResume = useCallback(async () => {
        if (!application && !request) return;
        const unlock = lock();
        try {
            const filename = await callFunction('getResume', {
                applicationId: application?.documentId,
                environment,
                availabilityId: request?.availabilityId,
                lang: language,
            });
            const res = await getFile(filename);
            const blob = new Blob(
                // @ts-ignore
                [res],
                {
                    type: 'application/pdf',
                },
            );

            Linking.openURL(URL.createObjectURL(blob));
        } finally {
            unlock();
        }
    }, [application, request, language, environment]);
    /**
     * effect to load talent for request from pub collection
     */
    useEffect(() => {
        if (request?.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                request.profileId,
            ).then((t) => setTalent(new MProfessionalProfile(t)));
        }
    }, []);
    /**
     * render
     */
    return (
        <View style={[style.card, style.horizontal]}>
            <View style={[style.horizontal, { flex: 1.5 }]}>
                <View style={[style.centeredItems, { width: 80 }]}>
                    {application ? (
                        <ApplicationPicture data={application} size={60} />
                    ) : (
                        talent && (
                            <ProfilePicture
                                data={{ ...talent, type: EUserType.talent }}
                                size={60}
                            />
                        )
                    )}
                </View>
                <View style={style.flex1}>
                    <CText
                        secondaryHeadline
                        message={contractMessages.contractTalentSection}
                        style={style.verticalHeavyPadded}
                    />
                    <View style={style.horizontal}>
                        <ContractState contract={contract} />
                    </View>
                    {applicant && (
                        <CText
                            secondaryHeadline
                            message={
                                (applicant.firstName
                                    ? `${applicant.firstName} `
                                    : '') + applicant.lastName
                            }
                        />
                    )}
                    <CTable
                        headers={[{ key: 'key' }, { key: 'value' }]}
                        hideHeaders
                        noSeperator
                        rows={[
                            // !!resource.firstName && {
                            //     key: format(profileMessages.firstName),
                            //     value: resource.firstName,
                            // },
                            // !!resource.lastName && {
                            //     key: format(profileMessages.lastName),
                            //     value: resource.lastName,
                            // },
                            !!resource.educations?.length && {
                                key: format(profileMessages.education),
                                value: (
                                    application?.educations ||
                                    applicant?.educations ||
                                    []
                                )
                                    .map((edu) =>
                                        format(
                                            educationMessages[
                                                edu as keyof typeof educationMessages
                                            ],
                                        ),
                                    )
                                    .join(', '),
                            },
                            !!resource.specializations?.length && {
                                key: format(profileMessages.specialization),
                                value: (
                                    application?.specializations ||
                                    applicant?.specializations ||
                                    []
                                )
                                    .map((spec) =>
                                        format(
                                            specializationMessages[
                                                spec as keyof typeof specializationMessages
                                            ],
                                        ),
                                    )
                                    .join(', '),
                            },
                            !!resource.yearsOfExperience && {
                                key: format(profileMessages.yoe),
                                value: `${
                                    (application || applicant)
                                        ?.yearsOfExperience
                                } ${format(
                                    (application || applicant)
                                        ?.yearsOfExperience !== 1
                                        ? generalMessages.years
                                        : generalMessages.year,
                                )}`,
                            },
                            !!resource.position && {
                                key: format(jobMessages.employeePosition),
                                value: format(
                                    positionMessages[
                                        (
                                            (application || applicant) as {
                                                position: string;
                                            }
                                        )
                                            .position as keyof typeof positionMessages
                                    ],
                                ),
                            },
                            !!(
                                resource.reviewCount && resource.averageRating
                            ) && {
                                key: format(profProfileMessages.rating),
                                value: (
                                    <TouchableView
                                        style={[
                                            style.horizontal,
                                            style.centeredItems,
                                        ]}
                                    >
                                        <StarDisplay
                                            stars={resource.averageRating}
                                        />
                                        <CText
                                            message={`(${resource.reviewCount})`}
                                        />
                                    </TouchableView>
                                ),
                            },
                        ].map((entry) => {
                            if (entry) {
                                return {
                                    values: [
                                        {
                                            key: 'key',
                                            // bold: true,
                                            value: entry.key,
                                            minWIdth: 130,
                                        },
                                        {
                                            key: 'value',
                                            value: entry.value,
                                            flex: 3,
                                        },
                                    ],
                                };
                            }
                        })}
                    />
                </View>
            </View>

            <View style={[style.flex1]}>
                <CButton
                    style={style.smallMargin}
                    onPress={getResume}
                    icon="download"
                    title={jobMessages.resume}
                />
                {!!talentId && isAgencyUser(userData) && (
                    <CButton
                        style={style.smallMargin}
                        icon={'account_outline'}
                        minor
                        onPress={() => secureNavigate('/profUser/' + talentId)}
                        title={generalMessages.talent}
                    />
                )}
            </View>
        </View>
    );
};
