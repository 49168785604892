export enum EEventTypes {
    newChatMessagesMail = 'newChatMessagesMail',
    newNotificationsMail = 'newNotificationsMail',
    newJobsMail = 'newJobsMail',
    signatureRequestReminderMail = 'signatureRequestReminderMail',
    timesheetFillReminderMail = 'timesheetFillReminderMail',
    deleteAccount = 'deleteAccount',
    completeShift = 'completeShift',
    termination = 'termination',
}
