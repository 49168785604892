import { useCallback, useEffect, useMemo, useState } from 'react';
import { MAgency, MProfessionalProfile } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import { profProfileMessages } from '../ProfProfile.messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { ECollections, ERegion } from '../../../enums';
import { isAgencyUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { keyof } from '../../../utilities/functions';
import { useCollectionIndex } from '../../../utilities/firebase/store/useIndex';
/**
 * hook to determine if a current talent is save worthy
 * @param talent talent to investigate validity status for
 * @returns
 */
export const useTalentValidity = (
    talent?: MProfessionalProfile,
    agency?: MAgency,
) => {
    // global state
    const format = useFormat();
    const { userData } = useFireBase();
    // local state
    const [curValidity, setCurValidity] = useState<Record<string, string>>({});
    const [curRecommends, setCurRecs] = useState<Record<string, string>>({});
    const availabilityParams = useMemo(() => {
        if (!agency || !talent) return { suspend: true };
        return {
            filter: [
                { field: 'profileId', value: talent.documentId },
                { field: 'agencyId', value: agency.documentId },
            ],
            limit: 1,
        };
    }, [agency?.documentId, talent?.documentId]);
    const availabilityData = useCollectionIndex(
        ECollections.availabilities,
        availabilityParams,
    );
    /**
     * memoized iterable validities
     */
    const validities = useMemo(() => {
        const keys = keyof(curValidity);
        return keys
            .filter((k) => !!curValidity[k])
            .map((k) => ({ key: k, value: curValidity[k] }));
    }, [curValidity]);
    /**
     * callback to adjust validity
     */
    const onValidityChange = useCallback(
        (v: string, key: string, minor?: boolean) =>
            (minor ? setCurRecs : setCurValidity)((prev) => {
                if (key) {
                    const next = { ...prev };
                    next[key] = v;
                    return next;
                }
                return { ...prev, basic: v };
            }),
        [],
    );
    /**
     * validityFunctions
     */
    const langsInvalidity = useCallback(
        (langs: { lang: string; level: string }[]) => {
            const region = talent?.region;
            const langKey = region === ERegion.de ? 'german' : 'english';
            const langMessages =
                region === ERegion.de
                    ? profileMessages.germanMissing
                    : profileMessages.englishMissing;
            const langLevelMessages =
                region === ERegion.de
                    ? profileMessages.germanLevelMissing
                    : profileMessages.englishLevelMissing;
            const lang = langs.find((v) => v.lang === langKey);
            if (!lang) {
                return format(langMessages);
            } else if (!lang.level) {
                return format(langLevelMessages);
            } else if (langs.find((v) => !v.level || !v.lang)) {
                return format(profileMessages.langValueIncomplete);
            }
        },
        [talent, format],
    );
    /**
     * check for valid talent education
     */
    useEffect(() => {
        const validity = !talent?.educations.length;
        onValidityChange(
            validity ? format(profileMessages.noEducation) : '',
            'professional',
        );
    }, [talent, format, onValidityChange]);
    /**
     * effect to write back language invalidity for current talent
     */
    useEffect(() => {
        const validity = talent ? langsInvalidity(talent.languages) || '' : '';
        onValidityChange(validity, 'lang');
    }, [talent, langsInvalidity, onValidityChange]);
    /**
     * effect to set validity for agency user edit
     */
    useEffect(() => {
        if (!isAgencyUser(userData)) return;
        const validityChecks = [
            {
                condition: !talent?.employmentType.length,
                message: profProfileMessages.noEmployType,
                key: 'eplmiss',
            },
            {
                condition: !talent?.availableHours,
                message: profProfileMessages.noAvailableHours,
                key: 'avh',
            },
            {
                condition: !talent?.yearlyAvailableVacationDays,
                message: profProfileMessages.noVacationDays,
                key: 'avd',
            },
            {
                condition: !talent?.wage.regularWage,
                message: profProfileMessages.noRateSet,
                key: 'wage',
            },
            {
                condition: !talent?.picture,
                message: profProfileMessages.noPicture,
                key: 'picture',
                minor: true,
            },
            {
                condition: !availabilityData.length,
                message: profProfileMessages.noAvails,
                key: 'avails',
                minor: true,
            },
        ];
        validityChecks.forEach(({ condition, message, key, minor }) => {
            onValidityChange(condition ? format(message) : '', key, minor);
        });
    }, [talent, userData, format, onValidityChange, availabilityData]);
    /**
     * return validities
     */
    return { curValidity, curRecommends, validities };
};
