import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from '../../utilities/routing';
import { ECollections } from '../../enums';
import { useFireBase } from '../../utilities/firebase';
import {
    MAgency,
    MProfessionalInfo,
    MProfessionalProfile,
    MReview,
    MUserData,
    MWorkplace,
} from '../../models';
import { ScrollProvider } from '../../utilities/contexts';
import {
    CButton,
    CCard,
    CImage,
    CText,
    ProfilePicture,
    Spinner,
    StarDisplay,
    TouchableView,
} from '../../components';
import { useStyle } from '../../utilities/styles';
import { ReviewRow } from './components/ReviewRow';
import { View } from 'react-native';
import { reviewMessages } from './review.messages';
import { useFormat } from '../../utilities/intl';

export const Reviews: FC = () => {
    // global state
    const { getDataIndex, getDataById } = useFireBase();
    const { type, id } = useParams();
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    // local state
    const [reviews, setReviews] = useState<MReview[]>([]);
    const [resource, setResource] = useState<
        MUserData | MWorkplace | MAgency | MProfessionalProfile
    >();
    const [agency, setAgency] = useState<MAgency>();
    /**
     * memoized resource name
     */
    const resourceName = useMemo(() => {
        if (!resource) return '';
        const userResource = resource as MProfessionalInfo;
        const nonUserResource = resource as MWorkplace | MAgency;
        if (type === ECollections.profProfiles) {
            const toReturn = `${format(reviewMessages.profile)}`;
            if (userResource.lastName) {
                return `${toReturn}  ${userResource.lastName}`;
            } else {
                return toReturn;
            }
        }
        if (userResource.lastName) {
            const toReturn = `${userResource.lastName}`;
            if (userResource.firstName) {
                return `${userResource.firstName}  ${toReturn}`;
            } else {
                return toReturn;
            }
        } else if (nonUserResource.name) {
            return nonUserResource.name;
        }
    }, [resource, type, format]);
    /**
     * memoized average rating over loaded reviews
     */
    const averageRating = useMemo(
        () =>
            reviews.reduce(
                (acc, r) =>
                    acc +
                    (r.ratings.find((r) => r.id === 'general')?.value || 0),
                0,
            ) / reviews.length,
        [reviews],
    );
    /**
     * effect to load resource
     */
    useEffect(() => {
        if (
            !type ||
            !id ||
            ![
                ECollections.agencies,
                ECollections.workplaces,
                ECollections.profProfiles,
            ].includes(type as ECollections)
        )
            return;
        setAgency(undefined);
        getDataIndex(`${type}/${id}/${ECollections.reviews}`, {
            limit: 10,
            orderBy: 'createdOn',
        }).then((response) => {
            const next = (response as MReview[]).map((r) => new MReview(r));
            setReviews(next);
        });
        const selectPubCollection = () => {
            switch (type) {
                case ECollections.agencies:
                    return ECollections.agencies;
                case ECollections.workplaces:
                    return ECollections.publicWorkplaces;
                case ECollections.profProfiles:
                    return ECollections.publicProfProfiles;
                case ECollections.users:
                    return ECollections.publicUsers;
                default:
                    return ECollections.publicUsers;
            }
        };
        getDataById(selectPubCollection(), id).then((d) => setResource(d));
    }, [id, type]);
    /**
     * effect to load agency for talent
     */
    useEffect(() => {
        if (
            type !== ECollections.profProfiles ||
            !resource ||
            !(resource as MProfessionalProfile).agencyId
        )
            return;

        getDataById(
            ECollections.publicAgencies,
            (resource as MProfessionalProfile).agencyId,
        ).then((a) => setAgency(new MAgency(a)));
    }, [resource, type]);
    /**
     * return spinner during load
     */
    if (!resource) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard style={style.horizontal}>
                <CButton
                    cy={'back'}
                    onPress={() => {
                        navigate(-1);
                    }}
                    icon={'chevronLeft'}
                    small
                />
                <CText
                    style={style.leftHeavyPadded}
                    message={resourceName}
                    headline
                />
            </CCard>
            <View style={style.horizontal}>
                <View style={style.flex1}>
                    <CCard style={[style.horizontal, style.centeredItems]}>
                        <ProfilePicture
                            data={resource}
                            isProfProfile={type === ECollections.profProfiles}
                        />
                        <View style={style.horizontalPadded}>
                            {resource.reviewCount ? (
                                <CText
                                    message={format(
                                        reviewMessages.reviewCount,
                                        {
                                            x: resource.reviewCount,
                                            resource: resourceName,
                                        },
                                    )}
                                />
                            ) : (
                                <CText
                                    message={format(reviewMessages.noReviews, {
                                        resource: resourceName,
                                    })}
                                />
                            )}
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CText
                                    message={reviewMessages.overallAverage}
                                />
                                <StarDisplay stars={resource.averageRating} />
                            </View>
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CText message={reviewMessages.recentAverage} />
                                <StarDisplay stars={averageRating} />
                            </View>
                        </View>
                    </CCard>
                </View>
                {agency && (
                    <CCard>
                        <View style={style.horizontal}>
                            <View>
                                <CText secondaryHeadline>{agency.name}</CText>
                                <TouchableView
                                    style={[
                                        style.horizontal,
                                        style.centeredItems,
                                    ]}
                                    onPress={() =>
                                        navigate(
                                            `/reviews/${ECollections.agencies}/${agency.documentId}`,
                                        )
                                    }
                                >
                                    <StarDisplay stars={agency.averageRating} />
                                    <CText
                                        message={`(${agency.reviewCount})`}
                                    />
                                </TouchableView>
                            </View>
                            <View style={style.leftPadded}>
                                <ProfilePicture data={agency} />
                            </View>
                        </View>
                    </CCard>
                )}
            </View>

            <CCard>
                <CText
                    secondaryHeadline
                    message={reviewMessages.individualReviews}
                />
            </CCard>
            {reviews.map((review) => (
                <ReviewRow review={review} key={review.documentId} />
            ))}
            {!reviews.length && (
                <CCard>
                    <CImage image="referral" />
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText
                            bold
                            centered
                            headline
                            message={format(reviewMessages.noReviews, {
                                resource: resourceName,
                            })}
                        />
                    </View>
                </CCard>
            )}
        </ScrollProvider>
    );
};
