import React, { FC, useMemo } from 'react';
import { useFormat } from '../../utilities/intl';
import {
    doctorEducationMessages,
    educationMessages,
    nursingEducationMessages,
    socialWorkerEducationMessages,
    teacherEducationMessages,
} from '../../utilities/messages';
import { CPicker } from '../elements';
import { MessageDescriptor } from 'react-intl';
import { EField, EProfession } from '../../enums';
import { pickerMessages } from './picker.messages';
import { StyleProp, View, ViewStyle } from 'react-native';
import { generalFieldEducationMessages } from '../../utilities/messages/job.messages/general.messages';
import { keyof } from '../../utilities/functions/keyof';

interface IEducationPickerProps {
    multi?: boolean;
    onChange: (next: string[] | string) => void;
    values?: string[];
    value?: string;
    titleOverride?: string;
    placeholderOverride?: string;
    cy?: string;
    validity?: string | MessageDescriptor | undefined;
    profession: EProfession;
    field?: EField;
    allowAny?: boolean;
    ignoreMultiLimit?: boolean;
    horizontal?: boolean;
    allowEmpty?: boolean;
    style?: StyleProp<ViewStyle>;
}
/**
 * picker for educations
 * will use value: string if not supplied with multi prop
 * @param param0
 * @returns
 */
export const EducationPicker: FC<IEducationPickerProps> = ({
    value,
    values,
    onChange,
    cy,
    titleOverride,
    validity,
    profession,
    multi,
    allowAny,
    field,
    ignoreMultiLimit,
    horizontal,
    allowEmpty,
    style,
}) => {
    const format = useFormat();
    /**
     * select edu messages
     */
    const educations = useMemo(() => {
        switch (profession) {
            case EProfession.doctor:
                return doctorEducationMessages;
            case EProfession.nurse:
                return nursingEducationMessages;
            case EProfession.teacher:
                return teacherEducationMessages;
            case EProfession.socialWorker:
                return socialWorkerEducationMessages;
            case EProfession.undefined:
                if (field === EField.general) {
                    return generalFieldEducationMessages;
                }
                return {};
            default:
                return {};
        }
    }, [profession]);
    /**
     * edu keys
     */
    const educationKeys = useMemo(() => keyof(educations), [educations]);
    /**
     * values as empty array if undefined
     */
    const safeValues = useMemo(() => {
        return values || [];
    }, [values]);
    /**
     * memoized transformed filtered educations for picker options
     */
    const filteredEducations = useMemo(() => {
        const next: { label: string; value: string }[] = educationKeys
            .map((v) => {
                return {
                    label: format(educationMessages[v]),
                    value: v,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        if (allowAny) {
            next.unshift({
                label: format(educationMessages.anyEducation),
                value: 'anyEducation',
            });
        }
        return next;
    }, [educationKeys, allowAny]);
    /**
     * return a empty view if there never where any contents to pick from
     */
    if (!educationKeys.length) {
        return <View />;
    }
    /**
     * render
     */
    return (
        <CPicker
            cy={cy || 'education-picker'}
            title={titleOverride || format(pickerMessages.education)}
            value={multi ? '' : value || ''}
            values={filteredEducations}
            validity={validity}
            onChange={(edu: string) => {
                if (multi) {
                    if (!edu) return;
                    if (!safeValues.includes(edu))
                        onChange([...safeValues, edu].sort());
                } else {
                    onChange(edu);
                }
            }}
            multiValues={(multi && safeValues) || undefined}
            onChangeMulti={
                (multi &&
                    ((next) => {
                        onChange([...next]);
                    })) ||
                undefined
            }
            placeholder={`-${format(pickerMessages.educationPlaceholder)}-`}
            keepOpen={multi}
            multiLimit={
                !ignoreMultiLimit
                    ? profession === EProfession.undefined
                        ? 1
                        : 3
                    : undefined
            }
            horizontal={horizontal}
            allowEmpty={allowEmpty}
            style={style}
        />
    );
};
