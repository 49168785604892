import { createContext } from 'react';
import {
    MProfessionalInfo,
    MProfessionalProfile,
    MUserData,
} from '../../models';

export const ProfileContext = createContext(
    {} as {
        onChange: (next: Partial<MProfessionalInfo>) => void;
        onChangeUserData?: (next: Partial<MUserData>) => void;
        onChangeTalentData?: (next: Partial<MProfessionalProfile>) => void;
        onValidityChange?: (issue: string, key?: string) => void;
        curValidity: Record<string, string>;
        curRecommends: Record<string, string>;
        curData: MProfessionalInfo;
        curUserData?: MUserData;
        curTalentData?: MProfessionalProfile;
        prevData: MProfessionalInfo;
        prevUserData?: MUserData;
        prevTalentData?: MProfessionalProfile;
        onboarding?: boolean;
        save?: () => void;
    },
);
