import React, { FC, useEffect } from 'react';
import { MWorkplace } from '../../models';
import { useFireBase } from '../../utilities/firebase';
import { CPicker } from '../elements';
import { pickerMessages } from './picker.messages';
import { StyleProp, ViewStyle } from 'react-native';
/**
 * props for workplace pciker
 */
interface IUserWorkplacePickerProps {
    onChange?: (next: MWorkplace) => void;
    value?: MWorkplace;
    autoSet?: boolean;
    onChangeMulti?: (next: MWorkplace[]) => void;
    multiValues?: MWorkplace[];
    horizontal?: boolean;
    style?: StyleProp<ViewStyle>;
}
/**
 * Picker to select on of current users workplaces
 * - only usable as employer user
 * - supply value && onChange or multiValues && onChangeMulti together
 */
export const UserWorkplacePicker: FC<IUserWorkplacePickerProps> = ({
    value,
    onChange,
    onChangeMulti,
    multiValues,
    autoSet,
    horizontal,
    style,
}) => {
    // global state
    const { userWorkplaces } = useFireBase();
    /**
     * effect to auto set if only 1 is available
     */
    useEffect(() => {
        if (autoSet && userWorkplaces.length === 1) {
            if (onChange) {
                if (!value?.documentId) onChange(userWorkplaces[0]);
            } else if (onChangeMulti) {
                if (multiValues?.length !== 1) onChangeMulti(userWorkplaces);
            }
        }
    }, [userWorkplaces, onChange, onChangeMulti, autoSet, value, multiValues]);
    /**
     * render
     */
    return (
        <CPicker
            title={pickerMessages.selectWorkplace}
            values={userWorkplaces.map((wp) => ({
                value: wp.documentId,
                label: wp.name,
            }))}
            value={value?.documentId}
            onChange={
                onChange
                    ? (next) => {
                          const woi = userWorkplaces.find(
                              (wp) => wp.documentId === next,
                          );
                          if (woi) onChange(woi);
                      }
                    : undefined
            }
            onChangeMulti={
                onChangeMulti
                    ? (next) => {
                          onChangeMulti(
                              userWorkplaces.filter((wp) =>
                                  next.includes(wp.documentId),
                              ),
                          );
                      }
                    : undefined
            }
            multiValues={
                multiValues ? multiValues.map((wp) => wp.documentId) : undefined
            }
            placeholder={pickerMessages.selectWorkplace}
            horizontal={horizontal}
            style={style}
        />
    );
};
