import { useCallback } from 'react';
import { MContract, MReview } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { isEmployer } from '../../../../utilities/auth';
import { contractMessages } from '../../contract.messages';
import { useDialog } from '../../../../utilities/dialog';
import { actionMessages } from '../../../../utilities/messages';
/**
 * hook for review dialog for contract
 * @param contract
 * @returns
 */
export const useOpenReviewDialog = (contract?: MContract) => {
    const { userData, callFunction } = useFireBase();
    const dialog = useDialog();
    /**
     * callback to handle review values
     * - creates workplace review if user is not workplace user
     * - creates user review if contract is not agency
     * - creates agency & profile review if contract is agency
     */
    const handleReview = useCallback(
        async (inputs?: any[]) => {
            if (!contract || !inputs) return;
            const reviews = [];
            if (!isEmployer(userData)) {
                const workplaceReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.workplaces,
                    targetId: contract.workplaceId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter(
                            (i) =>
                                i.sectionId === 'employer' &&
                                i.id !== 'reviewText',
                        )
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text:
                        inputs.find(
                            (i) =>
                                i.sectionId === 'employer' &&
                                i.id === 'reviewText',
                        )?.value || '',
                });
                reviews.push(workplaceReview);
            } else if (contract.agencyId && contract.profileId) {
                const agencyReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.agencies,
                    targetId: contract.agencyId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter(
                            (i) =>
                                i.sectionId === 'agency' &&
                                i.id !== 'reviewText',
                        )
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text:
                        inputs.find(
                            (i) =>
                                i.sectionId === 'agency' &&
                                i.id === 'reviewText',
                        )?.value || '',
                });
                const profileReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.profProfiles,
                    targetId: contract.profileId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter(
                            (i) =>
                                i.sectionId === 'employee' &&
                                i.id !== 'reviewText',
                        )
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text:
                        inputs.find(
                            (i) =>
                                i.sectionId === 'employee' &&
                                i.id === 'reviewText',
                        )?.value || '',
                });
                reviews.push(agencyReview);
                reviews.push(profileReview);
            } else {
                const userReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.users,
                    targetId: contract.employeeId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter((i) => i.sectionId === 'employee')
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text: '',
                });
                reviews.push(userReview);
            }
            await callFunction('submitReview', { reviews });
        },
        [userData, contract],
    );
    /**
     * review callback to open dialog
     */
    const openReview = useCallback(async () => {
        if (!isEmployer(userData)) {
            return await dialog({
                icon: 'review',
                title: contractMessages.reviewEmployer,
                message: contractMessages.reviewEmployerInfo,
                infoBox: { message: contractMessages.reviewInfo },
                reviewSections: [
                    {
                        id: 'employer',
                        generalTitle: contractMessages.general,
                        values: [
                            {
                                id: 'taskManagement',
                                label: contractMessages.taskManagement,
                            },
                            {
                                id: 'hospitality',
                                label: contractMessages.hospitality,
                            },
                            {
                                id: 'communication',
                                label: contractMessages.communication,
                            },
                        ],
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.confirm,
                        onPress: handleReview,
                        disabled: (i) => !i || !i.length,
                    },
                ],
            });
        } else {
            return await dialog({
                icon: 'review',
                title: contractMessages.reviewEmployee,
                message: contractMessages.reviewEmployeeInfo,
                reviewSections: [
                    ...(contract?.agencyId && contract.profileId
                        ? [
                              {
                                  id: 'agency',
                                  title: contractMessages.agency,
                                  generalTitle: contractMessages.general,
                                  values: [
                                      {
                                          id: 'communication',
                                          label: contractMessages.communication,
                                      },
                                  ],
                              },
                          ]
                        : []),
                    {
                        id: 'employee',
                        title: contractMessages.employee,
                        generalTitle: contractMessages.general,
                        values: [
                            {
                                id: 'punctuality',
                                label: contractMessages.punctuality,
                            },
                            {
                                id: 'reliability',
                                label: contractMessages.reliability,
                            },
                            {
                                id: 'professionality',
                                label: contractMessages.professionality,
                            },
                            {
                                id: 'competence',
                                label: contractMessages.competence,
                            },
                            {
                                id: 'sociability',
                                label: contractMessages.sociability,
                            },
                        ],
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.confirm,
                        onPress: handleReview,
                        disabled: (i) => {
                            console.log(i);
                            return (
                                !i ||
                                !i.length ||
                                !!(
                                    contract?.agencyId &&
                                    contract.profileId &&
                                    i.filter(
                                        (i) =>
                                            i.sectionId === 'employee' &&
                                            i.id !== 'reviewText',
                                    ).length !== 6 &&
                                    i.filter(
                                        (i) =>
                                            i.sectionId === 'agency' &&
                                            i.id !== 'reviewText',
                                    ).length !== 6
                                ) // ! check if all sections are filled
                            );
                        },
                    },
                ],
            });
        }
    }, [userData, contract, handleReview]);

    return openReview;
};
