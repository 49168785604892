import {
    ECountries,
    EDoctorTitle,
    EEmploymentType,
    EField,
    EGender,
    EProfession,
    ERegion,
} from '../enums';
import { year } from '../utilities/functions';
import { MAddress } from './MAddress';
import { MEducation } from './MEducation';
import { MEmployment } from './MEmployment';
import { MUserFile } from './MFile';
/**
 * professional information for job application resume
 */
export class MProfessionalInfo {
    /**
     * document id will be set from firebase on load
     */
    public documentId = '';
    /**
     * first name can be matched from parent userData for users
     */
    public firstName = '';
    /**
     * last name can be matched from parent userData for users
     */
    public lastName = '';
    /**
     * academic title
     */
    // declare title?: EDoctorTitle;
    /**
     * mail
     */
    public mail = '';
    /**
     * phone
     */
    public phone = '';
    /**
     * nationality can be matched from parent userData for users
     */
    public nationality: ECountries = ECountries.germany;
    /**
     * place of birth free form text until competence arrives
     */
    public placeOfBirth = '';
    /**
     * gender can be matched from parent userData for users
     */
    public gender?: EGender;
    /**
     * date of birth can be matched from parent userData for users
     */
    public dateOfBirth = Date.now() - 30 * year;
    /**
     * title can be matched from parent userData for users
     */
    public title?: EDoctorTitle;
    /**
     * determine prefered employmentTypes
     */
    public employmentType: EEmploymentType[] = [];
    /**
     * determine field (e.g. medic, education)
     */
    public field: EField = EField.undefined;
    /**
     * determine type of profession (e.g. doctor, nurse, electrician)
     */
    public profession: EProfession = EProfession.undefined;
    /**
     * education inside profession (e.g. general medicine)
     */
    public educations: string[] = [];
    /**
     * specialization inside profession
     */
    public specializations: string[] = [];
    /**
     * years of experience
     */
    public yearsOfExperience = 0;
    /**
     * position inside profession (e.g. chief engineer)
     */
    public position = '';
    /**
     * estimated promotion date
     */
    public estPromotion = 0;
    /**
     * arab restriction
     */
    public noCrossGenderWork = false;
    /**
     * tongues of person represented
     */
    public languages: { lang: string; level: string }[] = [];
    /**
     * history of educations
     */
    public educationHistory: MEducation[] = [];
    /**
     * history of employments should be expanded automatically
     */
    public employmentHistory: MEmployment[] = [];

    public willingToTravel = false;
    public notWillingToTravel = false;
    public maxTravelDistance = 50;

    public region = ERegion.de;

    public driversLicense = '';
    public hasVehicle = false;
    /**
     * files associated with profInfo
     */
    public files: MUserFile[] = [];
    /**
     * picture
     */
    public picture = '';

    public availableHours = 0;
    public yearlyAvailableVacationDays = 0;

    public editedOn = Date.now();
    public createdOn = Date.now();

    public averageRating = 0;
    public reviewCount = 0;
    /**
     * address of account
     */
    public address = new MAddress();
    /**
     * constructor
     * @param params data to construct ProfessionalInfo from
     */
    constructor(params: Partial<MProfessionalInfo> = {}) {
        Object.assign(this, {
            ...params,
        });

        MProfessionalInfo.fixConstructor(this, params);
    }
    /**
     * static method to fix shorcommings of object assign and patch compatibilies with incomplete paramsets
     * @param that object that just got object assigned
     * @param params params to apply fixes with
     */
    static fixConstructor = (that: any, params: Partial<MProfessionalInfo>) => {
        if (params.address) {
            that.address = new MAddress(params.address);
        }
        /**
         * array to parsed objects
         */
        if (params.educationHistory) {
            that.educationHistory = params.educationHistory.map(
                (e) => new MEducation(e),
            );
        }
        /**
         * array to parsed objects
         */
        if (params.employmentHistory) {
            that.employmentHistory = params.employmentHistory.map(
                (e) => new MEmployment(e),
            );
        }
        /**
         * array to parsed objects with // * legacy fix {name, type} => MUserFile
         */
        if (params.files) {
            that.files = params.files.map((f) => {
                if (f.path) {
                    return new MUserFile(f);
                } else {
                    return new MUserFile({ path: f.name, type: f.type });
                }
            });
        }
        /**
         * * legacy education => [education] fix
         */
        if ((params as any).education && !params.educations) {
            that.educations = [(params as any).education];
        }
        /**
         * * legacy !field => field: medic fix
         */
        if (
            (!params.field || params.field === EField.undefined) &&
            params.profession
        ) {
            if (
                [EProfession.doctor, EProfession.nurse].includes(
                    params.profession,
                )
            ) {
                that.field = EField.medic;
            }
        }
    };
}
