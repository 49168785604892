import React, { FC, ReactNode, useMemo, useState } from 'react';
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native';
import { useStyle, useTheme } from '../../utilities/styles';
import { CCard, CIcon } from '../elements';
import { TouchableView } from '../TouchableView';

interface IExpandableCardProps {
    body?: ReactNode;
    cy?: string;
    head: ReactNode;
    subHead?: ReactNode;
    customExpand?: boolean;
    customOnClick?: boolean | ((next: boolean) => void);
    style?: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
    onLayout?: (event: LayoutChangeEvent) => void;
    onRef?: (view: View) => void;
    preventHover?: boolean;
    zIndex?: number;
    embedded?: boolean;
    noChevron?: boolean;
}

export const ExpandableCard: FC<IExpandableCardProps> = ({
    body,
    head,
    subHead,
    customExpand,
    customOnClick,
    style,
    onLayout,
    onRef,
    embedded,
    cy,
    zIndex,
    preventHover,
    noChevron,
}) => {
    const styles = useStyle();
    const { theme } = useTheme();
    const [expand, setExpand] = useState(customExpand);

    const isExpanded = useMemo(() => {
        return !!(customExpand !== undefined ? customExpand : expand);
    }, [expand, customExpand]);

    return (
        <CCard
            style={[{ zIndex }, style]}
            outsideStyle={{ zIndex }}
            onLayout={onLayout}
            onRef={onRef}
            hoverEffects={!preventHover}
            embedded={JSON.stringify(style) === JSON.stringify({}) || embedded}
        >
            <TouchableView
                cy={cy}
                style={[styles.horizontalSplit, styles.centeredItems]}
                onPress={
                    customOnClick && customOnClick !== true
                        ? () => customOnClick(isExpanded)
                        : body
                        ? () => setExpand(!isExpanded)
                        : undefined
                }
            >
                {!noChevron && (body || customOnClick) && (
                    <View style={styles.horizontalPadded}>
                        <CIcon
                            icon={isExpanded ? 'chevronDown' : 'chevronRight'}
                            tint={theme.textMainColor}
                        />
                    </View>
                )}
                <View style={[styles.horizontalSplit, { flex: 1 }]}>
                    {head}
                </View>
            </TouchableView>
            {subHead && <View style={styles.verticalPadded}>{subHead}</View>}
            {isExpanded && (
                <View style={[styles.verticalPadded, { zIndex }]}>{body}</View>
            )}
        </CCard>
    );
};
