import React, { FC, useEffect, useState } from 'react';
import { Linking, View } from 'react-native';
import { useNavigate, useParams } from '../../../utilities/routing';
import { useFireBase } from '../../../utilities/firebase';
import { useStyle } from '../../../utilities/styles';
import { ECollections } from '../../../enums';
import { MJob } from '../../../models';
import { JobRow } from '../List/components/JobRow';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { CButton, CText, Spinner } from '../../../components';
import { actionMessages } from '../../../utilities/messages';
import { jobMessages } from '../job.messages';
import { useFormat } from '../../../utilities/intl';

const UnwrappedJobView: FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { getDataById, user, isLoggedIn } = useFireBase();
    const style = useStyle();
    // local state
    const [job, setJob] = useState(
        new MJob({
            from: Date.now(),
            createdOn: Date.now(),
            author: user?.uid || '',
        }),
    );
    const [loading, setLoading] = useState(true);

    /**
     * effect to load prev data
     */
    useEffect(() => {
        if (id && id !== 'new') {
            getDataById(ECollections.jobs, id).then((result) => {
                const next = new MJob(result);
                setJob(next);
                setLoading(false);
            });
        } else {
            navigate('/');
        }
    }, [id]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <View
                style={[
                    isLoggedIn ? style.card : style.embeddedCard,
                    style.horizontalSplit,
                ]}
            >
                <CButton
                    cy={'back'}
                    onPress={async () => {
                        !isLoggedIn ? navigate('/jobs') : navigate(-1);
                    }}
                    icon={'chevronLeft'}
                    small
                />
                {!isLoggedIn && (
                    <CButton
                        cy={'register'}
                        title={actionMessages.register}
                        onPress={() => navigate('/?job=' + id)}
                        small
                    />
                )}
            </View>
            <JobRow
                job={job}
                forceOpen
                noButtons={!isLoggedIn}
                embedded={!isLoggedIn}
            />
        </>
    );
};

export const JobView: FC = () => {
    const { isLoggedIn } = useFireBase();
    const { id } = useParams<{ id: string }>();
    const format = useFormat();
    const navigate = useNavigate();
    const style = useStyle();

    if (isLoggedIn) {
        return (
            <View style={style.paddedMainView}>
                <UnwrappedJobView />
            </View>
        );
    } else {
        return (
            <StandaloneWrapper>
                <UnwrappedJobView />
                <View style={[style.centeredContent]}>
                    <CText
                        centered
                        secondaryHeadline
                        message={jobMessages.offerCandidates}
                        style={style.verticalHeavyPadded}
                    />
                    <View style={style.horizontalSpaced}>
                        <CButton
                            cy={'register'}
                            title={jobMessages.registerNow}
                            onPress={() => navigate('/?job=' + id)}
                        />
                    </View>
                    <View style={style.verticalHeavyPadded} />
                    <CText
                        centered
                        secondaryHeadline
                        message={jobMessages.moreQuestions}
                    />
                    <CText
                        centered
                        message={jobMessages.moreQuestionsText}
                        style={style.verticalHeavyPadded}
                    />
                    <View style={style.horizontalSpaced}>
                        <CButton
                            title={jobMessages.arrangeCall}
                            onPress={() => {
                                const url = encodeURI(
                                    'mailto:marco@timeployees.de?subject=' +
                                        format(jobMessages.mailHookSubject) +
                                        '&body=' +
                                        format(jobMessages.mailHookText, {
                                            jobId: id,
                                        }),
                                );
                                Linking.openURL(url);
                            }}
                        />
                    </View>
                    <View style={style.verticalHeavyPadded} />
                </View>
            </StandaloneWrapper>
        );
    }
};
