import React, { FC, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { CText, CButton, CIcon } from '../../../../components';
import { MChat, MUserData } from '../../../../models';
import { useFormat } from '../../../../utilities/intl';
import {
    actionMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { useSecureNavigate } from '../../../../utilities/routing';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { VerifyButton } from '../../components/VerifyButton';
import { userMessages } from '../../user.messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { extractDaysMinutesHours } from '../../../../utilities/functions';
import { ECollections, EUserType } from '../../../../enums';
import { useFireBase } from '../../../../utilities/firebase';

interface IUserViewHeadlineBarProps {
    user: MUserData;
    prevUser: MUserData;
    activeChat?: MChat;
    setDisabled: () => void;
    setExtraAvailable: () => void;
    setExtraMobile: () => void;
    handleSave: (variation?: MUserData | undefined) => Promise<true | void>;
    handleVerifyChange: (next: MUserData) => void;
    handleMissedOnboardingNotification: () => void;
    toggleDeleteIssuedOn: () => void;
}
/**
 * headline bar for user view
 * contains buttons to trigger vital user actions
 *
 * verification, disabling, extra mobile / available toggle, saving, onboarding notifications & delete issue
 *
 * @param param0
 * @returns
 */
export const UserViewHeadlineBar: FC<IUserViewHeadlineBarProps> = ({
    user,
    prevUser,
    activeChat,
    setDisabled,
    setExtraAvailable,
    setExtraMobile,
    handleSave,
    handleVerifyChange,
    handleMissedOnboardingNotification,
    toggleDeleteIssuedOn,
}) => {
    // globale state
    const style = useStyle();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const { theme } = useTheme();
    const { post, userData } = useFireBase();
    /**
     * memoized calculated remaining time to deletion of user
     */
    const remainingTime = useMemo(() => {
        const d = new Date(user.deleteIssuedOn);
        d.setDate(d.getDate() + 30);
        const ms = d.getTime() - Date.now();
        const { days, hours, minutes } = extractDaysMinutesHours(ms);
        return { days, hours, minutes, ms };
    }, [user, Date.now()]);
    /**
     * render
     */
    return (
        <View style={style.card}>
            <View style={style.horizontalSplit}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        headline
                        style={[style.leftHeavyPadded, style.verticalPadded]}
                    >
                        {user.firstName} {user.lastName}
                    </CText>
                    {prevUser.extraAvailable && (
                        <CIcon
                            icon={'clock'}
                            style={[style.horizontalPadded]}
                            tint={theme.accentColor}
                        />
                    )}
                    {prevUser.extraMobile && (
                        <CIcon
                            icon={'car'}
                            style={[style.horizontalPadded]}
                            tint={theme.accentColor}
                        />
                    )}
                </View>
                {Platform.OS === 'web' && (
                    <CButton
                        title={format(actionMessages.save)}
                        onPress={handleSave}
                    />
                )}
            </View>
            <View
                style={[{ justifyContent: 'flex-end' }, style.horizontalWrap]}
            >
                {Platform.OS !== 'web' && (
                    <CButton
                        title={format(actionMessages.save)}
                        onPress={handleSave}
                    />
                )}
                <CButton
                    title={
                        !user.deleteIssuedOn
                            ? format(profileMessages.deleteAccount)
                            : `${format(
                                  profileMessages.remainingTimeUntilDeletion,
                              )} ${remainingTime.days} ${format(
                                  generalMessages.days,
                              )} ${remainingTime.hours} ${format(
                                  generalMessages.hours,
                              )} ${remainingTime.minutes} ${format(
                                  generalMessages.minutes,
                              )}`
                    }
                    onPress={toggleDeleteIssuedOn}
                    style={{
                        backgroundColor: theme.errorColor,
                    }}
                />
                {!user.deleteIssuedOn && (
                    <>
                        <CButton
                            cy={'disable-user'}
                            title={format(
                                prevUser.disabled
                                    ? userMessages.enableUser
                                    : userMessages.disableUser,
                            )}
                            style={{
                                backgroundColor: prevUser.disabled
                                    ? theme.successColor
                                    : theme.errorColor,
                            }}
                            onPress={setDisabled}
                        />
                        {prevUser.type === EUserType.user && (
                            <>
                                <CButton
                                    title={format(
                                        prevUser.extraAvailable
                                            ? userMessages.unSetExtraAvailable
                                            : userMessages.setExtraAvailable,
                                    )}
                                    icon={'clock'}
                                    onPress={setExtraAvailable}
                                />
                                <CButton
                                    title={format(
                                        prevUser.extraMobile
                                            ? userMessages.unSetExtraAvailable
                                            : userMessages.setExtraAvailable,
                                    )}
                                    icon={'car'}
                                    onPress={setExtraMobile}
                                />
                            </>
                        )}
                        {!user.verified && (
                            <CButton
                                title={format(userMessages.onboardingReminder)}
                                onPress={handleMissedOnboardingNotification}
                            />
                        )}
                        <VerifyButton
                            user={user}
                            onChange={handleVerifyChange}
                            activeChat={activeChat}
                        />
                        <CButton
                            icon="chat"
                            onPress={() => {
                                console.log(activeChat);
                                if (!activeChat) {
                                    post(
                                        ECollections.chats,
                                        new MChat({
                                            createdOn: Date.now(),
                                            participants: [
                                                userData.documentId,
                                                user.documentId,
                                            ],
                                        }),
                                    ).then((result) => {
                                        if (result) {
                                            secureNavigate(
                                                `/chat/${result?.id}`,
                                            );
                                        }
                                    });
                                } else {
                                    secureNavigate(
                                        `/chat/${activeChat.documentId}`,
                                    );
                                }
                            }}
                        />
                    </>
                )}
            </View>
        </View>
    );
};
