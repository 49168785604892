export class MAddress {
    public street = '';

    public number = '';

    public city = '';

    public country = '';

    public zipCode = '';
    public doubleDigitZipCode = '';
    public singleDigitZipCode = '';

    public lat?: number;

    public lng?: number;

    constructor(params: Partial<MAddress> = {}) {
        Object.assign(this, params);

        if (params.zipCode && params.zipCode.length >= 2) {
            this.singleDigitZipCode = params.zipCode[0];
            this.doubleDigitZipCode = params.zipCode[0] + params.zipCode[1];
        }
    }
}
