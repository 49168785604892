import React, { FC, useContext, useMemo } from 'react';
import { View } from 'react-native';
import { CCheckBox, CText, CTextInput, Radio } from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profileMessages } from '../../../Profile/Profile.messages';
import { CPicker } from '../../../../components/elements/CPicker/CPicker.web';
import { useStyle } from '../../../../utilities/styles';
import { profProfileMessages } from '../../../ProfProfile/ProfProfile.messages';

export const TalentOnboardingTravel: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    // parent state
    const { curData, onChange } = useContext(ProfileContext);
    /**
     * memoized travel level
     */
    const w = useMemo(() => {
        if (curData.willingToTravel && !curData.notWillingToTravel) {
            return 3;
        } else if (!curData.willingToTravel && !curData.notWillingToTravel) {
            return 2;
        } else if (!curData.willingToTravel && curData.notWillingToTravel) {
            return 1;
        }
    }, [curData]);
    return (
        <View>
            <View style={style.verticalPadded}>
                <CText message={profProfileMessages.travelAvailability} />
                <Radio
                    cy={'travel-radio'}
                    values={[
                        {
                            label: format(profProfileMessages.willingToTravel),
                            value: 3,
                        },
                        {
                            label: format(profProfileMessages.neutralToTravel),
                            value: 2,
                        },
                        {
                            label: format(
                                profProfileMessages.notWillingToTravel,
                            ),
                            value: 1,
                        },
                    ]}
                    value={w}
                    onChange={(next) => {
                        if (next === 3) {
                            onChange({
                                willingToTravel: true,
                                notWillingToTravel: false,
                            });
                        } else if (next === 2) {
                            onChange({
                                willingToTravel: false,
                                notWillingToTravel: false,
                            });
                        } else if (next) {
                            onChange({
                                willingToTravel: false,
                                notWillingToTravel: true,
                            });
                        }
                    }}
                />
            </View>
            <View style={style.verticalPadded}>
                {!curData.willingToTravel && !curData.notWillingToTravel && (
                    <CTextInput
                        value={`${curData.maxTravelDistance}`}
                        onChangeText={(t) => {
                            if (!isNaN(+t)) {
                                onChange({ maxTravelDistance: +t });
                            }
                        }}
                        unit={'km'}
                        label={format(profProfileMessages.maxTravelDistance)}
                        placeholder={format(
                            profProfileMessages.maxTravelDistancePlaceholder,
                        )}
                    />
                )}
            </View>
            <View style={style.verticalPadded}>
                <CPicker
                    title={profileMessages.driversLicense}
                    values={[
                        { value: '', label: profileMessages.noDriversLicense },
                        ...[
                            'A',
                            'A1',
                            'B',
                            'BE',
                            'C1',
                            'C1E',
                            'C',
                            'CE',
                            'D1',
                            'D1E',
                            'D',
                            'DE',
                        ].map((v) => {
                            return { value: v, label: v };
                        }),
                    ]}
                    multiValues={curData.driversLicense.split(',')}
                    onChangeMulti={(v) => {
                        onChange({ driversLicense: v.join(',') });
                    }}
                    keepOpen
                />
                <CCheckBox
                    title={profileMessages.hasVehicle}
                    checked={curData.hasVehicle}
                    onCheckedChanged={(c) => onChange({ hasVehicle: c })}
                />
            </View>
        </View>
    );
};
