import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CText,
    InfoBox,
    OutOfContext,
} from '../../../components';
import { MUserData } from '../../../models';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { extractDaysMinutesHours } from '../../../utilities/functions';
import { profileMessages } from '../Profile.messages';
import { ProfileContext } from '../Profile.context';

export const ProfileSectionAccountActions: FC = () => {
    const { callFunction, user } = useFireBase();
    const style = useStyle();
    const dialog = useDialog();
    const { theme } = useTheme();
    const format = useFormat();
    const {
        curData,
        curUserData,
        onChangeUserData,
        prevData,
        prevUserData,
        save,
    } = useContext(ProfileContext);
    /**
     * local variation of onchange
     */
    const onChange = useCallback(
        (next: Partial<MUserData>) => {
            if (!onChangeUserData || !curUserData) return;
            onChangeUserData({ ...curUserData, ...next });
        },
        [curUserData],
    );
    const remainingTime = useMemo(() => {
        /**
         * date delete was issued on
         */
        const d = new Date(curUserData?.deleteIssuedOn || Date.now());
        d.setDate(d.getDate() + 30);
        const ms = d.getTime() - Date.now();
        const { days, hours, minutes } = extractDaysMinutesHours(ms);
        return { days, hours, minutes, ms };
    }, [curData]);

    useEffect(() => {
        if (
            save &&
            curUserData?.deleteIssuedOn !== prevUserData?.deleteIssuedOn &&
            curData.documentId &&
            prevData.documentId &&
            JSON.stringify(prevData) !== JSON.stringify(new MUserData())
        ) {
            save();
        }
    }, [curData, prevData]);
    /**
     * check if context is correct
     */
    if (!curUserData || !onChangeUserData) {
        return <OutOfContext component="ProfileSectionAccountActions" />;
    }
    /**
     * render
     */
    return (
        <>
            <View style={style.verticalPadded}>
                <InfoBox
                    message={format(profileMessages.accountActionWarning)}
                    type="warning"
                />
            </View>
            {!!curUserData.deleteIssuedOn && (
                <CText>
                    {`${format(profileMessages.remainingTimeUntilDeletion)} ${
                        remainingTime.days
                    } ${format(generalMessages.days)} ${
                        remainingTime.hours
                    } ${format(generalMessages.hours)} ${
                        remainingTime.minutes
                    } ${format(generalMessages.minutes)}`}
                </CText>
            )}
            <View style={[style.horizontal, style.verticalPadded]}>
                <CButton
                    title={
                        curUserData.deleteIssuedOn
                            ? profileMessages.cancelDeletion
                            : profileMessages.deleteAccount
                    }
                    onPress={() => {
                        if (!curUserData.deleteIssuedOn) {
                            dialog({
                                title: format(
                                    profileMessages.deleteAccountWarning,
                                ),
                                message: format(
                                    profileMessages.deleteAccountWarningText,
                                ),

                                buttons: [
                                    {
                                        text: format(actionMessages.delete),
                                        onPress: () => {
                                            onChange({
                                                deleteIssuedOn: Date.now(),
                                            });
                                        },
                                        color: theme.errorColor,
                                    },
                                ],

                                cancelButton: {
                                    text: format(actionMessages.back),
                                },
                                icon: 'warning',
                            });
                        } else {
                            onChange({
                                deleteIssuedOn: 0,
                            });
                        }
                    }}
                    style={
                        curUserData.deleteIssuedOn
                            ? { backgroundColor: theme.warningColor }
                            : {
                                  backgroundColor: theme.errorColor,
                              }
                    }
                />
            </View>
            <View style={[style.horizontal, style.verticalPadded]}>
                <CButton
                    title={format(profileMessages.issuePasswordReset)}
                    onPress={() => {
                        if (user) {
                            dialog({
                                title: format(
                                    profileMessages.passwordResetHintTitle,
                                ),
                                message: format(
                                    profileMessages.passwordResetHintText,
                                ),

                                buttons: [
                                    {
                                        text: format(actionMessages.ok),
                                        onPress: () => {
                                            callFunction('passwordReset', {
                                                mail: user.email,
                                            });
                                        },
                                    },
                                ],
                                cancelButton: {
                                    text: format(actionMessages.back),
                                },
                                icon: 'warning',
                            });
                        }
                    }}
                    warning
                />
            </View>
            <View style={[style.verticalPadded]}>
                <CText headline>{format(profileMessages.notifications)}</CText>
                <CCheckBox
                    title={format(profileMessages.unreadMessages)}
                    checked={curUserData.notifications.unreadMessages}
                    onCheckedChanged={(unreadMessages) => {
                        onChange({
                            notifications: {
                                ...curUserData.notifications,
                                unreadMessages,
                            },
                        });
                    }}
                />
                <CCheckBox
                    title={format(profileMessages.newJobs)}
                    checked={curUserData.notifications.newJobs}
                    onCheckedChanged={(newJobs) => {
                        onChange({
                            notifications: {
                                ...curUserData.notifications,
                                newJobs,
                            },
                        });
                    }}
                />
                <CCheckBox
                    title={format(profileMessages.marketingMails)}
                    checked={curUserData.notifications.marketingMail}
                    onCheckedChanged={(marketingMail) => {
                        onChange({
                            notifications: {
                                ...curUserData.notifications,
                                marketingMail,
                            },
                        });
                    }}
                />
            </View>
        </>
    );
};
