import React, { FC, useContext, useMemo } from 'react';
import { Text } from 'react-native';

import { makeCTextStyles } from './styles';

import { StyleProp, TextStyle } from 'react-native';
import { useTheme } from '../../../utilities/styles';
import { IDefaultProps } from '../../../utilities/IDefaultProps';
import { Message } from '../../../utilities/types';
import { useAutoFormat } from '../../../utilities/intl/useFormat';
import { LanguageContext } from '../../../utilities/intl';

interface ICText extends IDefaultProps {
    style?: StyleProp<TextStyle>;
    message?: Message;
    bold?: boolean;
    isButtonText?: boolean;
    disabled?: boolean;
    maxLength?: number;
    wrap?: boolean;
    numberOfLines?: number;
    centered?: boolean;
    headline?: boolean;
    secondaryHeadline?: boolean;
    tertiaryHeadline?: boolean;
    selectable?: boolean;
    variables?: any;
}

export const CText: FC<ICText> = ({
    message,
    bold,
    style,
    isButtonText,
    children,
    maxLength,
    disabled,
    wrap,
    centered,
    numberOfLines,
    headline,
    secondaryHeadline,
    tertiaryHeadline,
    selectable,
    variables,
    ...passThrough
}) => {
    // globale state
    const { theme } = useTheme();
    const format = useAutoFormat();
    const { language } = useContext(LanguageContext);
    // local state
    const localStyles = useMemo(() => makeCTextStyles(theme), [theme]);
    /**
     * memoized localized, shrunk to maxLength & capitalized text
     */
    const text = useMemo(() => {
        let next = message ? format(message, variables) : '';
        next = next.substring(0, maxLength ? maxLength : next.length);
        return isButtonText ? next.toUpperCase() : next;
    }, [message, maxLength, isButtonText, language]);
    /**
     * render
     */
    return (
        <Text
            style={[
                bold ? localStyles.boldText : localStyles.text,
                isButtonText && localStyles.buttonText,
                disabled && localStyles.disabledText,
                wrap && localStyles.wrap,
                centered && localStyles.centered,
                headline && localStyles.headline,
                secondaryHeadline && localStyles.secondaryHeadline,
                tertiaryHeadline && localStyles.tertiaryHeadline,
                style,
            ]}
            numberOfLines={numberOfLines}
            selectable={selectable}
            {...passThrough}
        >
            {text}
            {children}
        </Text>
    );
};
