import React, { FC, useContext, useEffect } from 'react';
import { View } from 'react-native';
import { ContractContext } from '../../ContractContext';
import { ConfirmNegotiation } from './components/Confirm';
import { ContractCanceled } from '../ContractCanceled';
import { EContractStatus } from '../../../../../../enums';
import { useStyle } from '../../../../../../utilities/styles';
import { InitNegotiation } from './components/Init';
import { useFireBase } from '../../../../../../utilities/firebase';
import { isPeasant } from '../../../../../../utilities/auth';
import { InReview } from '../InReview';
import { ContractOverviewBar } from '../../../../components/ContractOverviewBar';
import { CButton } from '../../../../../../components';
import { ContractTitle } from '../../../../components/ContractTitle';
import {
    useNavigate,
    useSecureNavigate,
} from '../../../../../../utilities/routing';

export const ShortNegotiation: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const { secureNavigate } = useSecureNavigate();
    const { userData } = useFireBase();
    const { contract, handleClose, handleUndo, originalContract } =
        useContext(ContractContext);
    /**
     * effect to leave negotiation if status swapped to signed
     */
    useEffect(() => {
        if ([EContractStatus.signed].includes(contract.status)) {
            navigate(`/contract/${contract.documentId}`, { replace: true });
        }
    }, [contract]);

    return (
        <View style={style.paddedMainView}>
            <View style={style.card}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <View style={style.leftHeavyPadded} />
                    <ContractTitle contract={contract} />
                </View>
            </View>
            {contract.status === EContractStatus.canceled ? (
                <ContractCanceled />
            ) : (
                <View>
                    <ContractOverviewBar contract={contract} />
                    {contract.status.includes('review') ? (
                        isPeasant(userData) ? (
                            <ConfirmNegotiation />
                        ) : (
                            <InReview noUndo />
                        )
                    ) : !isPeasant(userData) ? (
                        <InitNegotiation />
                    ) : (
                        <InReview creating noUndo />
                    )}
                </View>
            )}
        </View>
    );
};
