import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    MAgency,
    MAvailability,
    MProfessionalInfo,
    MProfessionalProfile,
} from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { EApplicationStatus, ECollections, EUserType } from '../../../../enums';
import { useStyle, useTheme } from '../../../../utilities/styles';
import {
    CButton,
    CIcon,
    CText,
    ExpandableCard,
    ProfilePicture,
    StarDisplay,
} from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { educationMessages } from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { profProfileMessages } from '../../../ProfProfile/ProfProfile.messages';
import {
    convertNumberToCurrencyString,
    convertWeekAndYearToDate,
    day,
    week,
} from '../../../../utilities/functions';
import { useEnvironment } from '../../../../utilities/contexts';
import { availabilityMessages } from '../../availability.messages';
import { useNavigate } from 'react-router-native';
import { DayMap } from './DayMap';

export const AvailableUserRow: FC<{
    availability: MAvailability;
    unavailabilities: MAvailability[];
}> = ({ availability, unavailabilities }) => {
    const { getDataById, getDataIndex, userWorkplaces } = useFireBase();
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const navigate = useNavigate();
    const { region } = useEnvironment();

    const [resource, setResource] = useState<MProfessionalInfo>();
    const [agency, setAgency] = useState<MAgency>();
    const [wasRequested, setWasRequested] = useState(false);
    const [requestWasRejected, setWasRejected] = useState(false);

    const [expanded, setExpanded] = useState(false);
    /**
     * overlapping unavailabilities
     */
    // const unactiveDays = useMemo(() => {
    //     const next: string[] = [];
    //     unavailabilities
    //         .filter(
    //             (a) =>
    //                 a.uid === availability.uid ||
    //                 a.profileId === availability.profileId,
    //         )
    //         .filter((a) => {
    //             const overlap = a.activeDates.filter((s) =>
    //                 availability.activeDates.includes(s),
    //             );

    //             next.push(...overlap);

    //             return overlap.length;
    //         });

    //     return Array.from(new Set(next));
    // }, [availability, unavailabilities]);
    /**
     * memoized hourly rate with double digit fix applied
     */
    const hourlyRate = useMemo(() => {
        const talentProfile = resource as MProfessionalProfile;
        if (!talentProfile || !talentProfile.wage) return 0;
        const next = talentProfile.wage.wage + talentProfile.wage.regularWage;
        /**
         * this addition can fuck up so fix it
         */
        return Math.round(next * 100) / 100;
    }, [resource]);
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        const from =
            new Date(availability.exactStartTime) ||
            convertWeekAndYearToDate(availability.year, availability.start);
        const to =
            new Date(availability.exactEndTime) ||
            new Date(
                from.getTime() + (availability.repeatCount + 1) * week - day,
            );

        return { from, to };
    }, [availability]);
    /**
     * display name
     */
    const displayName = useMemo(() => {
        if (resource) {
            if (resource.lastName) {
                if (resource.firstName) {
                    return `${resource.firstName} ${resource.lastName}`;
                }
                return `${resource.lastName}`;
            }
        } else if (agency) {
            return agency.name;
        }
    }, [resource, agency]);
    /**
     * effect to load resource
     */
    useEffect(() => {
        if (availability.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                availability.profileId,
            ).then((r) => setResource(new MProfessionalInfo(r)));
        } else {
            getDataById(ECollections.publicUsers, availability.uid).then((r) =>
                setResource(new MProfessionalInfo({ ...r })),
            );
        }
        if (availability.agencyId) {
            getDataById(
                ECollections.publicAgencies,
                availability.agencyId,
            ).then((r) => setAgency(new MAgency(r)));
        }
    }, [availability]);
    /**
     * effect to load request
     */
    useEffect(() => {
        for (const wp of userWorkplaces) {
            const filter = [{ field: 'workplaceId', value: wp.documentId }];
            if (availability.profileId) {
                filter.push({
                    field: 'profileId',
                    value: availability.profileId,
                });
            } else {
                filter.push({ field: 'userId', value: availability.uid });
            }
            getDataIndex(ECollections.requests, { filter }).then((r1) => {
                if ((r1 as any[]).length) {
                    setWasRequested(true);
                    getDataIndex(ECollections.requests, {
                        filter: [
                            ...filter,
                            {
                                field: 'status',
                                value: EApplicationStatus.rejected,
                            },
                        ],
                    }).then((r2) => {
                        if ((r2 as any[]).length === (r1 as any[]).length) {
                            setWasRejected(true);
                        }
                    });
                }
            });
        }
    }, [userWorkplaces, availability]);

    return (
        <ExpandableCard
            cy={'open-user-' + resource?.lastName}
            customExpand={expanded}
            customOnClick={() => setExpanded(!expanded)}
            noChevron
            head={
                <View
                    style={[
                        style.horizontal,
                        style.flex1,
                        style.verticalPadded,
                    ]}
                >
                    {!!resource && (
                        <View style={style.horizontalHeavyPadded}>
                            <ProfilePicture
                                data={{ ...resource, type: EUserType.talent }}
                            />
                        </View>
                    )}
                    <View style={[style.flex1, style.leftPadded]}>
                        <CText>
                            {format(availabilityMessages.availableFromTo, {
                                fromDate: from.toLocaleDateString('de'),
                                toDate: to.toLocaleDateString('de'),
                            })}
                        </CText>
                        {displayName && (
                            <CText
                                secondaryHeadline
                                style={style.verticalPadded}
                            >
                                {displayName}
                            </CText>
                        )}
                        {!!resource?.educations.length && (
                            <View
                                style={[
                                    style.horizontalWrap,
                                    style.centeredItems,
                                ]}
                            >
                                <CIcon
                                    icon="school"
                                    size={16}
                                    tint={theme.textSecondaryColor}
                                />
                                <CText style={style.leftPadded}>
                                    {`${resource.educations
                                        .map((edu) =>
                                            format(
                                                educationMessages[
                                                    edu as keyof typeof educationMessages
                                                ],
                                            ),
                                        )
                                        .join(', ')}`}
                                </CText>
                            </View>
                        )}
                        {!!resource?.yearsOfExperience && (
                            <CText>
                                {`${resource.yearsOfExperience} ${format(
                                    profileMessages.yoe,
                                )}`}
                            </CText>
                        )}
                        {!!resource?.reviewCount && (
                            <View
                                style={[
                                    style.horizontal,
                                    style.centeredItems,
                                    style.verticalPadded,
                                ]}
                            >
                                <StarDisplay stars={resource.averageRating} />
                                <CText message={`(${resource.reviewCount})`} />
                            </View>
                        )}
                        {hourlyRate ? (
                            <CText tertiaryHeadline>
                                {`${format(
                                    profProfileMessages.rate,
                                )} ${convertNumberToCurrencyString(
                                    hourlyRate,
                                    region,
                                )}`}
                            </CText>
                        ) : (
                            <CText message={profProfileMessages.noRate} />
                        )}
                    </View>
                    <View>
                        {wasRequested ? (
                            requestWasRejected ? (
                                <View>
                                    <CText
                                        message={
                                            availabilityMessages.wasRejected
                                        }
                                    />
                                    <CButton
                                        title={availabilityMessages.newRequest}
                                        onPress={() =>
                                            navigate(
                                                '/availableUsers/request/' +
                                                    availability.documentId,
                                            )
                                        }
                                        small
                                    />
                                </View>
                            ) : (
                                <CText
                                    message={
                                        availabilityMessages.alreadyRequested
                                    }
                                    style={style.verticalPadded}
                                />
                            )
                        ) : (
                            <CButton
                                onPress={() =>
                                    navigate(
                                        '/availableUsers/request/' +
                                            availability.documentId,
                                    )
                                }
                                title={availabilityMessages.request}
                            />
                        )}
                        <View style={style.flex1} />
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon
                                icon={expanded ? 'chevronUp' : 'chevronRight'}
                                tint={theme.textSecondaryColor}
                            />
                            <CText
                                message={
                                    expanded
                                        ? availabilityMessages.showLessDetail
                                        : availabilityMessages.showMoreDetail
                                }
                            />
                        </View>
                    </View>
                </View>
            }
            body={
                <View>
                    <CText>
                        {format(availabilityMessages.availableFromTo, {
                            fromDate: from.toLocaleDateString('de'),
                            toDate: to.toLocaleDateString('de'),
                        })}
                    </CText>
                    <DayMap days={availability.days} />
                    {/* {!!unactiveDays.length && (
                        <>
                            <View style={style.verticalPadded} />
                            <CText
                                message={availabilityMessages.notAvailableOn}
                            />
                            <CText message={unactiveDays.join(' ')} />
                        </>
                    )} */}
                    {!!unavailabilities.length && (
                        <>
                            <CText
                                message={
                                    availabilityMessages.notAvailableDuring
                                }
                            />
                            {unavailabilities.map((a) => (
                                <View key={a.documentId}>
                                    <CText
                                        message={`${new Date(
                                            a.exactStartTime,
                                        ).toLocaleDateString(
                                            'de',
                                        )} - ${new Date(
                                            a.exactEndTime,
                                        ).toLocaleDateString('de')}`}
                                    />
                                </View>
                            ))}
                        </>
                    )}
                </View>
            }
        />
    );
};
